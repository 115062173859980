import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Subtitle from "../../Component/PageTitle/Subtitle";
import { List, CircularProgress, Backdrop } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import "../dashboard/Dashboard.scss";
import {
  reportTaskOverview,
  reportActivityOverview,
  reportProjectOverview,
} from "../../redux/api/index";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  PieChart,
  Pie,
  Sector,
  Cell,
} from "recharts";
import { useParams } from "react-router-dom";

const data = [
  {
    Week: "Week 0",
    Project: 0,
    amt: 100,
  },
  {
    name: "Week 1",
    Project: 200,
    amt: 200,
  },
  {
    name: "Week 2",
    Project: 500,
    amt: 400,
  },
  {
    name: "Week 3",
    Project: 400,
    amt: 2000,
  },
  {
    name: "Week 4",
    Project: 0,
    amt: 2181,
  },
  {
    name: "Week 5",
    Project: 600,
    amt: 2500,
  },
  {
    name: "Week 6",
    Project: 700,
    amt: 2100,
  },
  {
    name: "Week 6",
    Project: 800,
    amt: 700,
  },
];

const TaskData = [
  { name: "Group A", value: 900 },
  { name: "Group B", value: 200 },
  { name: "Group C", value: 100 },
];
const COLORS = ["#4516FF", "#63E3FF", "#FF1686"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const dataCivil = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 600 },
];
const COLORSCivil = ["#29B6F7", "#EFEFEF"];

const dataMechanical = [
  { name: "Group A", value: 800 },
  { name: "Group B", value: 200 },
];
const COLORSMechanical = ["#EFEFEF", "#E489BD"];

const dataElectrical = [
  { name: "Group A", value: 800 },
  { name: "Group B", value: 300 },
];
const COLORSElectrical = ["#EFEFEF", "#AA8EE6"];

export default function ReportDashboard() {
  const [taskOver, setTaskOver] = useState({
    dataCount: 0,
    formated: [
      { name: "Group A", value: 0 },
      { name: "Group B", value: 0 },
      { name: "Group C", value: 0 },
    ],
  });
  const [activityOver, setActivityOver] = useState({
    formated: [
      [
        {
          name: "",
          value: 0,
          percent: 0,
          activityName: "",
        },
        {
          name: "",
          percent: 0,
          activityName: "",
          value: 1,
        },
      ],
      [
        {
          name: "",
          value: 0,
          percent: 0,
          activityName: "",
        },
        {
          name: "",
          percent: 0,
          activityName: "",
          value: 1,
        },
      ],
      [
        {
          name: "",
          value: 0,
          percent: 0,
          activityName: "",
        },
        {
          name: "",
          percent: 0,
          activityName: "",
          value: 1,
        },
      ],
    ],
  });
  const [projectOver, setProjectOver] = useState([]);
  const [showBackdrop, setShowBackdrop] = useState(false);

  const params = useParams();

  const fetchTaskOverview = async () => {
    try {
      setShowBackdrop(true);
      const { data } = await reportTaskOverview(params.projectId);
      setTaskOver({
        ...taskOver,
        dataCount: data.aggregatedCount,
        formated: data.formatedData,
      });
      setShowBackdrop(false);
    } catch (error) {
      setShowBackdrop(false);
      console.log("error fetching task overview");
    }
  };
  const fetchProjectOverview = async () => {
    try {
      setShowBackdrop(true);
      const { data } = await reportProjectOverview(params.projectId);
      setProjectOver(data.weekWiseData);
      setShowBackdrop(false);
    } catch (error) {
      setShowBackdrop(false);
      console.log("error fetching task overview");
    }
  };
  const fetchActOverview = async () => {
    try {
      setShowBackdrop(true);
      const { data } = await reportActivityOverview(params.projectId);
      setActivityOver({
        ...activityOver,
        formated: data.formatedData,
      });
      setShowBackdrop(false);
    } catch (error) {
      setShowBackdrop(false);
      console.log("error fetching task overview");
    }
  };

  useEffect(() => {
    fetchTaskOverview();
    fetchActOverview();
    fetchProjectOverview();
  }, []);

  return (
    <>
      <Box component="div" className="tabs-reports">
        <Grid container className="reportDashboard" spacing={2}>
          <Grid item md={4} style={{ marginBottom: "20px" }}>
            <Paper elevation={2}>
              <Subtitle leftTitle="Tasks Overview" />

              <Grid container>
                <Grid item md={7}>
                  <PieChart width={200} height={260}>
                    <Pie
                      data={taskOver.formated}
                      cx="-100px"
                      cy="280px"
                      labelLine={false}
                      label={renderCustomizedLabel}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {data.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </Grid>
                <Grid item md={5}>
                  <List className="resport-listing-task">
                    <Typography variant="h6">
                      Total Tasks{" "}
                      <span style={{ fontSize: "20px" }}>
                        {" "}
                        {taskOver.dataCount}{" "}
                      </span>
                    </Typography>
                    <ListItem disablePadding>
                      <span style={{ background: COLORS[1] }}></span>
                      <Typography>In Progress</Typography>
                    </ListItem>
                    <ListItem disablePadding>
                      <span style={{ background: COLORS[0] }}></span>
                      <Typography>Completed</Typography>
                    </ListItem>
                    <ListItem disablePadding>
                      <span style={{ background: COLORS[2] }}></span>
                      <Typography>Delayed</Typography>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item md={8}>
            <Subtitle leftTitle="Activity Overview" />
            <Grid container spacing={1} style={{ marginTop: "10px" }}>
              {activityOver.formated.map((formAct, ind) => (
                <>
                  <Grid item md={4}>
                    <Paper elevation={2}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        component="h6"
                        className="threeHeader"
                      >
                        {formAct[0]?.activityName}{" "}
                        <span style={{ fontSize: "100%" }}>
                          {" "}
                          {formAct[0]?.percent}%{" "}
                        </span>
                      </Typography>

                      <PieChart width={280} height={190}>
                        <Pie
                          data={formAct}
                          cx={120}
                          cy={85}
                          innerRadius={60}
                          outerRadius={80}
                          fill="#8884d8"
                          paddingAngle={5}
                          dataKey="value"
                        >
                          {data.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORSCivil[index % COLORSCivil.length]}
                            />
                          ))}
                        </Pie>
                      </PieChart>
                    </Paper>
                  </Grid>
                </>
              ))}
              {/* <Grid item md={4}>
                <Paper elevation={2}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    component="h6"
                    className="threeHeader"
                  >
                    Mechanical <span>60%</span>
                  </Typography>

                  <PieChart width={280} height={190}>
                    <Pie
                      data={dataMechanical}
                      cx={120}
                      cy={85}
                      innerRadius={60}
                      outerRadius={80}
                      fill="#8884d8"
                      paddingAngle={5}
                      dataKey="value"
                    >
                      {data.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={
                            COLORSMechanical[index % COLORSMechanical.length]
                          }
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </Paper>
              </Grid> */}
              {/* <Grid item md={4}>
                <Paper elevation={2}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    component="h6"
                    className="threeHeader"
                  >
                    Electrical <span>60%</span>
                  </Typography>

                  <PieChart width={280} height={190}>
                    <Pie
                      data={dataElectrical}
                      cx={120}
                      cy={85}
                      innerRadius={60}
                      outerRadius={80}
                      fill="#8884d8"
                      paddingAngle={5}
                      dataKey="value"
                    >
                      {data.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={
                            COLORSElectrical[index % COLORSElectrical.length]
                          }
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </Paper>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          className="reportDashboard"
          style={{ marginTop: "20px" }}
        >
          <Grid item md={12}>
            <Paper
              elevation={4}
              style={{
                height: "50vh",
                overflow: "hidden",
                paddingBottom: "5rem",
              }}
            >
              <Subtitle leftTitle="Project Overview" />
              <ResponsiveContainer width="100%" height="100%">
                <LineChart width={500} height={300} data={projectOver}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
                  <YAxis tickCount={8} />
                  <Tooltip />
                  {/* <Legend /> */}

                  <Line type="monotone" dataKey="work" stroke="#82ca9d" />
                </LineChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Backdrop style={{ zIndex: 9, opacity: "35%" }} open={showBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
