import { makeStyles, useTheme } from "@material-ui/core/styles";
import { spacing, textTransform } from "@mui/system";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  smallSize: {
    "min-width": "35px",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  inputRoot: {
    color: "inherit",
    width: "30rem",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(8)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  bglight: {
    "background-image": "linear-gradient(216deg, #f5f7fa 0%, #c3cfe2 100%)",
    padding: "20px",
  },
  tableCellClasses: {
    borderRadius: "10px !important",
    boxShadow: "0px 12px 30px -18px rgba(21 ,101, 192,0.3) !important",
    border: "1px solid rgba(122,123,151,.3)",
  },
  tableHeaderCell: {
    fontWeight: "bold !important",
    backgroundColor: "rgba(239,239,247,.7)",
    color: "#3b3e66",
    borderBottom: "2px solid #dcdef1",
    borderTop: "2px solid #dcdef1",
    textTransform: "uppercase",
    padding: theme.spacing(2, 1),
  },
  tableCell: {
    padding: "9px !important",
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  top: {
    color: "#1a90ff",
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
  circle: {
    strokeLinecap: "round",
  },
  sticky: {
    position: "sticky",
    left: 0,
    boxShadow: "10px 0 5px -2px #ddd",
    borderRight: "1px solid #ddd",
    background: "#fff",
  },
  tableReport: {
    "&:tr:nth-child(event)": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
}));

export const btnStyles = makeStyles({
  root: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    color: "white",
    minWidth: 35,
  },
  label: {
    textTransform: "capitalize",
  },
});
