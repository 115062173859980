import React, { useState, useEffect } from "react";
import "./Addtask.css";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  Box,
  Button,
  FormGroup,
  Toolbar,
  Tooltip,
  Typography,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { TextField } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import Title from "./Title";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Activities from "./Activities/Activities";
import Header from "../../Component/Header/Header";
import CssBaseline from "@material-ui/core/CssBaseline";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import PageTitle from "../../Component/PageTitle/PageTitle";
import Link from "@material-ui/core/Link";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Preview from "./PreviewProject/Preview";
import Projectdetails from "./ProjectDetails/Projectdetails";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_ACTIVITY,
  GET_TASK,
  FILTER_TASK,
  GET_ALL_TASK,
} from "../../redux/constants";
import activityList from "../../data/activityData.json";
import taskList from "../../data/taskData.json";
import Footer from "../Footer/Footer";
import {
  activity as activityApi,
  taskList as actTaskApi,
  allTask,
} from "../../redux/api/index";

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#1F487C",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
  },
});
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    overflow: "auto",
  },
  tabsButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
}));

export default function Addtask() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activityId, setActivityId] = useState(0);
  const [snack, setSnack] = useState({ open: false, message: "" });
  const [toggleOpen, setToggleOpen] = useState(1);
  const toggleTabs = (index) => {
    setToggleOpen(index);
  };

  const task = useSelector((state) => state.master.task);

  const [projectDetail, setProjectDetail] = useState({
    project_id: "",
    project_name: "",
    start_date: null,
    etc_date: null,
    latitide: "",
    longitude: "",
    client_name: "",
    client_email: "",
    client_phone: "",
    address: "",
    state: "",
    state_id: "",
    district: "",
    district_id: "",
    pincode: "",
    supervisor_name: "",
    supervisor_email: "",
    supervisor_phone: "",
    project_creator_name: "",
    creator_password: "",
    project_access_email1: "",
    project_access_email2: "",
  });

  let [selectedTask, setSelectedTask] = useState([]);
  let [checkedTask, setCheckedTask] = useState([]);

  const handleLoad = async () => {
    try {
      loadActivity();
      const { data } = await allTask();

      setSelectedTask(
        data.data.map((t) => {
          return {
            id: t.id,
            name: t.name,
            activity_id: t.activityId,
            uom: t.uom,
            goal: "",
            etc_date: null,
            scheduled_date: null,
            error_goal: "",
            error_etc_date: "",
            error_scheduled_date: "",
          };
        })
      );
    } catch (error) {
      console.log("task feting error");
    }
  };

  const loadActivity = async () => {
    try {
      const { data } = await activityApi();
      dispatch({ type: GET_ACTIVITY, payload: data.data });
      loadTask();
      dispatch({ type: FILTER_TASK, payload: data.data[0].id });
    } catch (error) {
      console.log("get activity list error");
    }
  };
  const loadTask = async (actId) => {
    try {
      // const {data} = await actTaskApi(actId);
      const { data } = await allTask();
      dispatch({ type: GET_ALL_TASK, payload: data.data });
    } catch (error) {
      console.log("act task feting err");
    }
  };

  useEffect(() => {
    // dispatch({ type: GET_ACTIVITY, payload: activityList });
    // dispatch({ type: GET_TASK, payload: taskList });
    handleLoad();
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <CssBaseline />
          <Header addProjSnack={snack} setAddProjSnack={setSnack} />
          <main className={classes.content} style={{ overflow: "auto" }}>
            <Toolbar />
            <PageTitle title="Add Project" />
            <Paper
              elevation={4}
              style={{ backgroundColor: "#eeefff6b", minHeight: "70%" }}
            >
              <Listing>
                <List className="reportListing" disablePadding>
                  <ListItem disablePadding>
                    <ListItemButton
                      variant="contained"
                      // onClick={() => toggleTabs(1)}
                      className={toggleOpen === 1 ? "tabs active-tabs" : "tabs"}
                    >
                      Project Details
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      variant="contained"
                      // onClick={() => toggleTabs(2)}
                      className={toggleOpen === 2 ? "tabs active-tabs" : "tabs"}
                    >
                      Activities & Task Details
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      variant="contained"
                      // onClick={() => toggleTabs(3)}
                      className={toggleOpen === 3 ? "tabs active-tabs" : "tabs"}
                    >
                      Preview
                    </ListItemButton>
                  </ListItem>
                </List>
              </Listing>

              <Box
                className={
                  toggleOpen === 1 ? "content  active-content" : "content"
                }
              >
                <Projectdetails
                  toggleOpen={toggleOpen}
                  setToggleOpen={setToggleOpen}
                  selectedTask={selectedTask}
                  setSelectedTask={setSelectedTask}
                  projectDetail={projectDetail}
                  setProjectDetail={setProjectDetail}
                />
              </Box>
              <Box
                className={
                  toggleOpen === 2 ? "content  active-content" : "content"
                }
              >
                <Activities
                  toggleOpen={toggleOpen}
                  setToggleOpen={setToggleOpen}
                  selectedTask={selectedTask}
                  setSelectedTask={setSelectedTask}
                  projectDetail={projectDetail}
                  setProjectDetail={setProjectDetail}
                  checkedTask={checkedTask}
                  setCheckedTask={setCheckedTask}
                  setActivityId={setActivityId}
                  activityId={activityId}
                />
              </Box>
              <Box
                className={
                  toggleOpen === 3 ? "content  active-content" : "content"
                }
              >
                <Preview
                  toggleOpen={toggleOpen}
                  setToggleOpen={setToggleOpen}
                  selectedTask={selectedTask}
                  setSelectedTask={setSelectedTask}
                  projectDetail={projectDetail}
                  setProjectDetail={setProjectDetail}
                  checkedTask={checkedTask}
                  setCheckedTask={setCheckedTask}
                  setActivityId={setActivityId}
                  activityId={activityId}
                />
              </Box>
            </Paper>
            <Footer />
          </main>
        </div>
      </ThemeProvider>
    </>
  );
}

const Listing = styled.div`
  border-bottom: 1px solid #ddd;
  background: #fff;
  .tabs {
    min-width: 250px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 15px 20px;
    font-weight: bold;
    background: #fff;
    border-bottom: 4px solid transparent;
    font-size: 0.95rem;
    &:hover {
      color: #1976d2;
      border-color: #1976d2;
      // box-shadow: 0 0.313rem 0.8rem rgb(60 68 177 / 50%), 0 0.126rem 0.225rem rgb(60 68 177 / 30%);
    }

    & > span.txt {
      background: #e8fbfd;
      color: #049aac;
      text-align: center;
      height: 26px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      border-radius: 30px;
      font-weight: bold;
    }
  }
  .tabs.active-tabs {
    // box-shadow: 0 0.313rem 0.8rem rgb(60 68 177 / 50%), 0 0.126rem 0.225rem rgb(60 68 177 / 30%);
    color: #1976d2;
    border-color: #1976d2;
    background: rgba(0, 0, 0, 0.02);
  }
  .btn:not(:disabled):hover {
    transform: translateY(-2px);
  }
`;
