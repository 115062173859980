import React, { useState, useEffect } from "react";
import { Divider, Toolbar, Tooltip } from "@material-ui/core";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { activityMaster, getTask } from "../../../redux/action/masterAction";
import { useDispatch, useSelector } from "react-redux";
import { GET_ACTIVITY, GET_TASK, FILTER_TASK } from "../../../redux/constants";
import activityList from "../../../data/activityData.json";
import taskList from "../../../data/taskData.json";
import { Typography } from "@mui/material";
import "../../dashboard/Dashboard.scss";
import { ListItemButton } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  leftlist: {
    height: "100vh",
  },
}));

export default function ActivitiesNavbar({ setActivityId, activityId }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const activity = useSelector((state) => state.master.activity);

  const handleActivityChange = (id) => {
    setActivityId(id);
    dispatch({ type: FILTER_TASK, payload: id });
    // dispatch(getTask(id));
  };

  useEffect(() => {
    // dispatch({ type: GET_ACTIVITY, payload: activityList });
    // dispatch({ type: GET_TASK, payload: taskList });
    // if (activity.length > 0) {
    //   setActivityId(activity[0].id);
    // }
  }, []);

  return (
    <>
      <Box className="leftlist-activites">
        <List disablePadding>
          <ListItem
            disablePadding
            style={{ background: "#fff", color: "#1F487C" }}
          >
            <Typography variant="h6"> Activity Name </Typography>
          </ListItem>
          <Divider />
          <ListItem
            button
            onClick={() => handleActivityChange(0)}
            selected={activityId == 0 ? true : false}
          >
            <Typography variant="h6">All</Typography>
          </ListItem>
          <Divider />
          {activity.map((act, index) => (
            <>
              <ListItem
                button
                onClick={() => handleActivityChange(act.id)}
                selected={activityId == act.id ? true : false}
              >
                <Typography variant="h6">{act.name}</Typography>
              </ListItem>
              <Divider />
            </>
          ))}
        </List>
      </Box>
    </>
  );
}
