import { makeStyles } from "@material-ui/styles";
import tinycolor from "tinycolor2";

export default makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  listbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: " 10px",
    marginBottom: "10px",
    border: "2px solid #3c44b1",
    borderRadius: "5px",
    "&>div > div:first-child": {
      color: theme.palette.primary.main,
      fontWeight: 700,
    },
  },
  listing: {
    display: "flex",
    alignItems: "center",
    fontSize: "0.9rem",
    borderRight: "1px solid #bcc8d3",
    "&:last-child": {
      borderRight: "0",
    },
    "& > div": {
      paddingRight: "8px",
      fontSize: "0.95rem",
      color: "#000000",
      fontWeight: "500",
    },
  },

  neutral: {
    backgroundColor: theme.palette.neutral.main,
    color: "#fff",
  },

  primary: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: "#fff",
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: "#fff",
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  table: {
    width: "100%",
    "& .MuiTableCell-body": {},
    "& .MuiTableCell-head ": {
      padding: "35px 15px 16px",
      fontWeight: "700",
      fontSize: "1rem",
    },
  },
  tabsButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0",
  },
}));
