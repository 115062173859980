import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import UserHeader from "../../Component/Header/UserHeader";
import PageTitle from "../../Component/PageTitle/PageTitle";
import { Button, Grid, Paper, Box, Divider } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { DangerBtn, SaccessBtn, PrimaryBtn, NeutralBtn } from "../styles";
import { useStyles, btnStyles } from "../TableStyle";
import { NavLink } from "react-router-dom";
import Link from "@mui/material/Link";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TablePagination from "@mui/material/TablePagination";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import Footer from "../Footer/Footer";
import {
  assignedtasklist,
  submitTask,
  futureAssignedList,
} from "../../redux/api/index";
import { useParams } from "react-router-dom";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

const longText = `
Aliquam eget finibus ante, non facilisis lectus. Sed vitae dignissim est, vel aliquam tellus.
Praesent non nunc mollis, fermentum neque at, semper arcu.
Nullam eget est sed sem iaculis gravida eget vitae justo.
`;

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#ffffff",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
  },
});

const number = [...new Array(20)];

export default function Assignmenthistory() {
  const classes = useStyles();
  const param = useParams();

  let [taskList, setTaskList] = useState({
    assigned: [],
  });
  const [selectedAct, setSelectedAct] = useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [work, setWork] = React.useState("");
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = (event) => {
    setWork(event.target.value);
  };
  const [value, setValue] = React.useState(null);

  const fetchAssignedTask = async (actId, date) => {
    try {
      const { data } = await futureAssignedList(
        param.projectid,
        actId ? actId : null,
        date ? date : null
      );
      setTaskList(data.data);
    } catch (error) {
      console.log("assigned task fetch error");
    }
  };

  useEffect(() => {
    fetchAssignedTask();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <UserHeader
          selectedAct={selectedAct}
          fetchAssignedTask={fetchAssignedTask}
          setSelectedAct={setSelectedAct}
        />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {/* <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/">
              Upcoming
            </Link>
            <Typography color="textPrimary">Listing</Typography>
          </Breadcrumbs> */}
          <PageTitle title="Upcoming Task" />
          <Grid container>
            <Grid item xs={6}>
              <Box elevation={4}>
                <Typography
                  variant="h6"
                  sx={{
                    display: "flex",
                    alignItems: "left",
                    textTransform: "capitalize",
                    flexDirection: "column",
                    p: 0,
                    mb: 2,
                  }}
                  component={Box}
                >
                  {taskList?.project_name}{" "}
                  <span style={{ marginTop: "-2px" }}>
                    {taskList?.project_id}{" "}
                  </span>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={taskList.assigned.length}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage="Task per Page"
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{ minHeight: 0 }}
              />
            </Grid>
          </Grid>
          <Grid container style={{ marginBottom: "20px" }}>
            <Grid item xs={12}>
              <TableContainer component={Paper} elevation={10}>
                <Table fullWidth className="future-dashboard">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ pl: 1, pr: 1 }}>Activity Name</TableCell>
                      <TableCell sx={{ pl: 1, pr: 1 }}>Task Name</TableCell>
                      <TableCell sx={{ pl: 1, pr: 1 }}>UOM</TableCell>
                      <TableCell sx={{ pl: 1, pr: 1 }}>Start Date</TableCell>
                      <TableCell sx={{ pl: 1, pr: 1 }}>Schedule Date</TableCell>
                      <TableCell sx={{ pl: 1, pr: 1 }}>ETC Date</TableCell>
                      <TableCell sx={{ pl: 1, pr: 1 }}>
                        Completed Date
                      </TableCell>
                      <TableCell sx={{ pl: 1, pr: 1 }}>Total Work</TableCell>
                      <TableCell sx={{ pl: 1, pr: 1 }}>Work Done</TableCell>
                      {/* <TableCell sx={{ pl: 1, pr: 1 }} align="center">
                        Remark
                      </TableCell> */}
                      <TableCell sx={{ pl: 1, pr: 1 }}>Work Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* {
                      taskList.assigned.length > 0 ? (
                        (rowsPerPage > 0
                          ? taskList.assigned.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                          :taskList.assigned */}

                    {(taskList.assigned.length > 0
                      ? rowsPerPage > 0
                        ? taskList.assigned.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                        : taskList.assigned
                      : taskList.assigned
                    ).map((data) => {
                      return (
                        <TableRow key={data.id}>
                          <TableCell sx={{ pl: 1, pr: 1 }} align="center">
                            {" "}
                            {data?.activity?.name}{" "}
                          </TableCell>
                          <TableCell sx={{ pl: 1, pr: 1 }} align="center">
                            {" "}
                            {data?.taskDetail?.name}{" "}
                          </TableCell>
                          <TableCell sx={{ pl: 1, pr: 1 }} align="center">
                            {" "}
                            {data?.taskDetail?.uom}{" "}
                          </TableCell>
                          <TableCell sx={{ pl: 1, pr: 1 }} align="center">
                            {" "}
                            {data?.from_date
                              ? moment(data.from_date).format("DD/MM/YYYY")
                              : "-"}{" "}
                          </TableCell>
                          <TableCell sx={{ pl: 1, pr: 1 }} align="center">
                            {data?.projectTask?.scheduled_date
                              ? moment(data.scheduled_date).format("DD/MM/YYYY")
                              : "-"}
                          </TableCell>
                          <TableCell sx={{ pl: 1, pr: 1 }} align="center">
                            {data?.projectTask?.etc_date
                              ? moment(data.etc_date).format("DD/MM/YYY")
                              : "-"}
                          </TableCell>
                          <TableCell sx={{ pl: 1, pr: 1 }} align="center">
                            {" "}
                            {data?.completed_date
                              ? moment(data.completed_date).format("DD/MM/YYYY")
                              : "-"}{" "}
                          </TableCell>
                          <TableCell sx={{ pl: 1, pr: 1 }} align="center">
                            {" "}
                            {data?.assigned_goal}{" "}
                          </TableCell>
                          <TableCell sx={{ pl: 1, pr: 1 }} align="center">
                            {" "}
                            {data?.work_done}{" "}
                          </TableCell>
                          {/* <CustomWidthTooltip title={longText}>
                            <TableCell sx={{ pl: 1, pr: 1 }} align="center">
                              View
                            </TableCell>
                          </CustomWidthTooltip> */}
                          <TableCell sx={{ pl: 1, pr: 1 }} align="center">
                            {data?.work_status}{" "}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Footer />
        </main>
      </div>
    </ThemeProvider>
  );
}
