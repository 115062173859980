import { combineReducers } from "redux";
import authReducer from "./authReducer";
import { master } from "./masterReducer";
import { project } from "./projectReducer";
import phoneReducer from "./phoneReducer";

export default combineReducers({
  auth: authReducer,
  master: master,
  project: project,
  phone: phoneReducer,
});
