import { GET_PROJECT_LIST } from "../constants";

const initialState = {
  allProject: [
    {
      id: "",
      project_name: "",
      project_site_link: "",
      project_id: "",
      client_name: "",
      client_email: "",
      client_phone: "",
      location: "",
      start_date: "",
      etc_date: "",
      status: "",
      state: "",
      state_id: "",
      district: "",
      district_id: "",
      supervisor_name: "",
      supervisor_email: "",
      supervisor_phone: "",
      project_creator_name: "",
      createdAt: "",
      updatedAt: "",
    },
  ],
  completed: [
    {
      id: "",
      project_name: "",
      project_site_link: "",
      project_id: "",
      client_name: "",
      client_email: "",
      client_phone: "",
      location: "",
      start_date: "",
      etc_date: "",
      status: "",
      state: "",
      state_id: "",
      district: "",
      district_id: "",
      supervisor_name: "",
      supervisor_email: "",
      supervisor_phone: "",
      project_creator_name: "",
      createdAt: "",
      updatedAt: "",
    },
  ],
  inprogress: [
    {
      id: "",
      project_name: "",
      project_site_link: "",
      project_id: "",
      client_name: "",
      client_email: "",
      client_phone: "",
      location: "",
      start_date: "",
      etc_date: "",
      status: "",
      state: "",
      state_id: "",
      district: "",
      district_id: "",
      supervisor_name: "",
      supervisor_email: "",
      supervisor_phone: "",
      project_creator_name: "",
      createdAt: "",
      updatedAt: "",
    },
  ],
  hold: [
    {
      id: "",
      project_name: "",
      project_site_link: "",
      project_id: "",
      client_name: "",
      client_email: "",
      client_phone: "",
      location: "",
      start_date: "",
      etc_date: "",
      status: "",
      state: "",
      state_id: "",
      district: "",
      district_id: "",
      supervisor_name: "",
      supervisor_email: "",
      supervisor_phone: "",
      project_creator_name: "",
      createdAt: "",
      updatedAt: "",
    },
  ],
  draft: {
    id: "",
    project_name: "",
    project_site_link: "",
    project_id: "",
    client_name: "",
    client_email: "",
    client_phone: "",
    location: "",
    start_date: "",
    etc_date: "",
    status: "",
    state: "",
    state_id: "",
    district: "",
    district_id: "",
    supervisor_name: "",
    supervisor_email: "",
    supervisor_phone: "",
    project_creator_name: "",
    createdAt: "",
    updatedAt: "",
  },
  currentProject: {
    id: 0,
    project_name: "",
    project_site_link: null,
    project_id: "",
    client_name: "",
    client_email: "",
    client_phone: "",
    location: null,
    start_date: null,
    etc_date: null,
    status: "",
    address: "",
    state_id: 0,
    district_id: 0,
    pincode: 0,
    supervisor_name: "",
    supervisor_email: "",
    supervisor_phone: "",
    project_creator_name: "",
    project_password: null,
    longitude: "00.0000",
    latitude: "00.0000",
    isDraft: 1,
    createdAt: "",
    updatedAt: "",
    add: [
      {
        activityId: 0,
        activity_id: 0,
        detail: {
          id: 0,
          name: "",
          status: 1,
          createdAt: "",
          updatedAt: "",
        },
        task: [
          //   {
          //     id: 1,
          //     name: "",
          //     uom: "",
          //     activityId: "",
          //     status: 1,
          //     createdAt: "",
          //     updatedAt: "",
          //     goal: "",
          //     checked: false,
          //     etc_date: null,
          //     scheduled_date: null,
          //   },
        ],
      },
    ],
    activity: [
      {
        activity_id: 0,
        detail: {
          id: 0,
          name: "",
          status: 1,
          createdAt: "",
          updatedAt: "",
        },
        submittedTask: [
          //   {
          //     id: 0,
          //     activity_id: 0,
          //     task_id: 0,
          //     from_date: "",
          //     to_date: "",
          //     assigned_goal: 0,
          //     admin_remark: null,
          //     supervisor_remark: null,
          //     work_status: null,
          //     project_task_id: 0,
          //     work_done: 0,
          //     supervisor_name: null,
          //     project_id: 0,
          //     status: 1,
          //     isApproved: 0,
          //     isSubmitted: 0,
          //     createdAt: "",
          //     updatedAt: "",
          //     projectTask: {
          //       id: 0,
          //       project_id: 0,
          //       activity_id: 0,
          //       task_id: 0,
          //       task_assign_from_date: null,
          //       task_assign_to_date: null,
          //       start_date: null,
          //       etc_date: null,
          //       scheduled_date: null,
          //       completed_date: null,
          //       assigned_total: 0,
          //       total_work_allocated: 0,
          //       total_completed: 0,
          //       remark: null,
          //       status: null,
          //       isAssigned: 1,
          //       createdAt: "",
          //       updatedAt: "",
          //     },
          //     taskDetail: {
          //       id: 0,
          //       name: "",
          //       uom: "",
          //       activityId: 0,
          //       status: 1,
          //       createdAt: "",
          //       updatedAt: "",
          //     },
          //   },
        ],
        unassigned: [
          //   {
          //     id: 0,
          //     project_id: 0,
          //     activity_id: 0,
          //     task_id: 0,
          //     task_assign_from_date: null,
          //     task_assign_to_date: null,
          //     start_date: null,
          //     etc_date: null,
          //     scheduled_date: null,
          //     completed_date: null,
          //     assigned_total: 0,
          //     total_work_allocated: 0,
          //     total_completed: 0,
          //     remark: null,
          //     status: null,
          //     isAssigned: 1,
          //     createdAt: "",
          //     updatedAt: "",
          //     today_goal: "",
          //     taskDetail: {
          //       id: 0,
          //       name: "",
          //       uom: "",
          //       activityId: 0,
          //       status: 1,
          //       createdAt: "",
          //       updatedAt: "",
          //     },
          //   },
        ],
      },
    ],
  },
};

export const project = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROJECT_LIST:
      return {
        ...state,
        allProject: action.payload,
        completed: action.payload.filter((proj) => proj.status === "COMPLETED"),
        inprogress: action.payload.filter(
          (proj) => proj.status === "IN_PROGRESS"
        ),
        hold: action.payload.filter((proj) => proj.status === "HOLD"),
      };

    default:
      return state;
  }
};
