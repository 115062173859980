import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import Avatar from "@material-ui/core/Avatar";
import logo from "../../assets/images/protrac+blue.svg";
import Slinko from "../../assets/images/slnko+white.svg";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Navigate, NavLink, useParams } from "react-router-dom";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import GroupIcon from "@material-ui/icons/Group";
import LayersIcon from "@material-ui/icons/Layers";
import "../../Pages/dashboard/Dashboard.scss";
import styled from "styled-components";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { projAct, logOut } from "../../redux/api/index";
import { useLocation, useMatch, useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import Tooltip from "@mui/material/Tooltip";

const drawerWidth = 200;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    // marginTop:'15px',
    // borderRadius:'8px',
    width: `calc(100% - ${theme.spacing(14) + 1}px)`,
    backgroundImage: "white",
    color: "#151c30",
    [theme.breakpoints.down("sm")]: {
      width: `calc(100% - ${theme.spacing(12) + 1}px)`,
    },
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    background: "#193051",
    padding: "0",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
      overflowL: "#ed00ff",
    }),
  },
  drawerClose: {
    background: "#193051",
    width: theme.spacing(14) + 1,
    paddingLeft: theme.spacing(2),
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    width: theme.spacing(12) + 1,
    overflow: "hidden",
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(14),
      padding: "0 12px",
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    [theme.breakpoints.up("sm")]: {
      justifyContent: "center",
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  profilesTxt: {
    display: "flex",
    textAlign: "right",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  imgLogo: {
    width: "90px",
    overflow: "inherit",
    margin: "0 auto",
    fill: "#1F487C",
  },
  imgAccount: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    width: "auto",
  },
  imgAccountLogo: {
    width: `calc(100% - 200px)`,
    display: "flex",
    alignItems: "center",
  },
  slogo: {
    width: "90px",
    margin: "13px auto",
  },
  arrow: {
    color: "white",
  },
  maxWidth: {},
  minWidth: {
    minWidth: "63px",
  },
}));

const sidebarTheme = createTheme({
  overrides: {
    MuiListItem: {
      root: {
        color: "#ffffff80",
        fontSize: "0.7rem",
        fontWeight: "normal",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.1) !important",
          borderRadius: "60px",
          color: "#65cfea",
        },
        "&.Mui-selected": {
          backgroundColor: "rgba(0, 0, 0, 0.1) !important",
          borderRadius: "60px",
          boxShadow:
            "0 .18rem .1rem rgba(25 46 79,0.8),0 .126rem 1rem rgba(122,123,151,.1)",
          color: "#65cfea",
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.1) !important",
            borderRadius: "60px",
            "& div > svg": {
              color: "#65cfea",
            },
          },
          "& div > svg": {
            color: "#65cfea",
          },
        },
      },
      text: {
        color: "#ffffff80",
      },
    },
    MuiListItemIcon: {
      root: {
        color: "#ffffff80",
        justifyContent: "center",
        "&:hover": {
          color: "#65cfea",
        },
      },
    },
    "MuiSelect-select": {
      select: {
        "&:focus": {
          background: "#3A3660",
        },
        color: "white",
      },
    },
  },
});

export default function Header({
  setSelectedAct,
  selectedAct,
  fetchAssignedTask,
  date,
  setDate
}) {
  const classes = useStyles();
  const theme = useTheme();
  const params = useParams();
  const isMatch = useMatch("/supervisor/view-assignments/:projectid/future");
  const isMatch2 = useMatch("/supervisor/view-assignments/:projectid");
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const [selectedIndex, setSelectedIndex] = React.useState();
  const [selectDrop, setSelectDrop] = React.useState("");
  const [projectActivity, setProjectActivity] = useState([]);
  const [value, setValue] = React.useState(null);
  const preventDefault = (event) => event.preventDefault();
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  const handleChange = (event) => {
    setSelectedAct(event.target.value);
    if (isMatch || isMatch2) {
      fetchAssignedTask(event.target.value, null);
    }
  };

  const projectAct = async () => {
    try {
      const { data } = await projAct(params.projectid);
      setProjectActivity(data.data);
    } catch (error) {
      console.log("assigned task fetch error");
    }
  };

  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/supervisor/login");
    } catch (error) {
      console.log("supervisor logout error");
    }
  };

  useEffect(() => {
    projectAct();
    // if(selectedAct){
    //   setSelectedAct(selectedAct)
    // }
  }, []);

  return (
    <div>
      <AppBar
        position="fixed"
        color="info"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: !open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: !open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}></Typography>

          <div className={classes.profilesTxt}>
            <Box component="div" className={classes.imgAccountLogo}>
              {" "}
              <Avatar
                alt="logo"
                src={logo}
                width="97"
                className={classes.imgLogo}
              />
            </Box>
            <Box component="div" className={classes.imgAccount}>
              {/* <Typography className="account_name">
                Supervisor | Logout
                <div>
                  <NavLink
                    to="/"
                    onClick={preventDefault}
                    color="inherit"
                    className="login_after"
                  >
                    Logout
                  </NavLink>
                </div>
              </Typography> */}
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                <IconButton aria-label="show 4 new mails" color="inherit">
                  <Tooltip title="Supervisor">
                    <AccountCircleIcon />
                  </Tooltip>
                </IconButton>
                <Divider orientation="vertical" flexItem />
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={handleLogout}
                >
                  <Tooltip title="Logout">
                    <LogoutIcon />
                  </Tooltip>
                </IconButton>
              </Box>
            </Box>
          </div>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: !open,
          [classes.drawerClose]: open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: !open,
            [classes.drawerClose]: open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <a href={`/supervisor/view-assignments/${params.projectid}`}>
            <img src={Slinko} alt width="90" className={classes.slogo} />
          </a>
          <IconButton
            onClick={handleDrawerClose}
            style={{ color: "#fff" }}
            className={{ [classes.hide]: !open }}
          >
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <ThemeProvider theme={sidebarTheme}>
          <List className={classes.activelink}>
            <ListItem>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                style={{ color: "#ffffff80" }}
              >
                <DatePicker
                  value={value}
                  style={{ color: "#ffffff80" }}
                  onChange={(newValue) => {
                    setValue(newValue);

                    if (isMatch || isMatch2) {
                      fetchAssignedTask(selectedAct, newValue);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" className="userDate" />
                  )}
                />
              </LocalizationProvider>
            </ListItem>

            <ListItem>
              <FormControl fullWidth className="usecontrol" size="small">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedAct}
                  label="Activity"
                  onChange={handleChange}
                  style={{ color: "#ffffff80" }}
                >
                  <MenuItem value={""}> Activity </MenuItem>
                  {projectActivity.map((act, ind) => (
                    <MenuItem value={act.activity_id}>
                      {act?.activityDetail?.name}{" "}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </ListItem>
            <NavLink to={`/supervisor/view-assignments/${params.projectid}`}>
              <ListItem
                disableGutters
                button
                className={classes.link}
                selected={selectedIndex === 0}
                // onClick={(event) => handleListItemClick(event, 0)}
              >
                <ListItemIcon
                  disableGutters
                  className={{
                    [classes.minWidth]: open,
                    [classes.maxWidth]: !open,
                  }}
                >
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItem>
            </NavLink>
            <NavLink
              to={`/supervisor/view-assignments/${params.projectid}/future`}
            >
              <ListItem
                disableGutters
                button
                className={classes.link}
                selected={selectedIndex === 0}
                // onClick={(event) => handleListItemClick(event, 0)}
              >
                <ListItemIcon
                  disableGutters
                  className={{
                    [classes.minWidth]: open,
                    [classes.maxWidth]: !open,
                  }}
                >
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Future Assignments" />
              </ListItem>
            </NavLink>
            <NavLink
              to={`/supervisor/view-assignments/${params.projectid}/history`}
            >
              <ListItem
                disableGutters
                button
                selected={selectedIndex === 1}
                // onClick={(event) => handleListItemClick(event, 1)}
              >
                <ListItemIcon
                  className={{
                    [classes.minWidth]: open,
                    [classes.maxWidth]: !open,
                  }}
                >
                  <AcUnitIcon />
                </ListItemIcon>
                <ListItemText primary="Assignment History" />
              </ListItem>
            </NavLink>
          </List>
        </ThemeProvider>
      </Drawer>
    </div>
  );
}
