import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Header from "../../Component/Header/Header";
import PageTitle from "../../Component/PageTitle/PageTitle";
import { Button, Grid, Paper } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  DangerBtn,
  SaccessBtn,
  PrimaryBtn,
  NeutralBtn,
} from "../../Pages/styles";
import { useStyles, btnStyles } from "../TableStyle";
import { NavLink } from "react-router-dom";
import Link from "@mui/material/Link";
import Footer from "../Footer/Footer";
import Subtitle from "../../Component/PageTitle/Subtitle";
import { Input } from "@material-ui/core";
import "../dashboard/Dashboard.scss";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import { CircularProgress, Backdrop } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { TableFooter } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import IosShareSharpIcon from "@mui/icons-material/IosShareSharp";
import "../dashboard/Dashboard.scss";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { BorderBottom } from "@mui/icons-material";
import {
  reportProjectDetailed,
  reportProjectSummary,
} from "../../redux/api/index";
import { useParams } from "react-router-dom";
import moment from "moment";
import generateExcel from "zipcelx";

const longText = `
Aliquam eget finibus ante, non facilisis lectus. Sed vitae dignissim est, vel aliquam tellus.Praesent non nunc mollis, fermentum neque at, semper arcu..`;

const number = [...new Array(10)];
function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
  },
});

export default function ReportsDetails() {
  const classes = useStyles();
  const [progress, setProgress] = React.useState(10);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [value, setValue] = React.useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [report, setReport] = useState({
    data: [],
    distTask: [],
    distAct: [],
  });
  const [activity, setActivity] = React.useState("");
  const [detailTaskList, setDetailTaskList] = useState([]);
  const params = useParams();

  const handleActChange = (event) => {
    setActivity(event.target.value);
    fetchDetailedReport(event.target.value);
  };
  const handleDateChange = (elem, value) => {
    if (elem == "fromDate") {
      setFromDate(moment(value).utc().format());
      if (moment(value).utc().format() != "Invalid date") {
        fetchDetailedReport(null, moment(value).utc().format());
      }
    } else {
      setToDate(moment(value).utc().format());
      fetchDetailedReport(null, null, moment(value).utc().format());
    }
  };

  const fetchDetailedReport = async (actId, fDate, tDate) => {
    try {
      setShowBackdrop(true);
      const { data } = await reportProjectDetailed(
        params.projectId,
        actId ? actId : activity,
        fDate ? fDate : fromDate == "Invalid date" ? "" : fromDate,
        tDate ? tDate : toDate == "Invalid date" ? "" : toDate
      );
      setReport({
        ...report,
        data: data.data,
        distTask: data.distTask,
        distAct: data.distAct,
      });
      setShowBackdrop(false);
    } catch (error) {
      setShowBackdrop(false);
      console.log("detailed report fetch error");
    }
  };

  const fetchSummary = async () => {
    try {
      const { data } = await reportProjectSummary("", "", params.projectId);
      setDetailTaskList(data.data);
    } catch (error) {
      console.log("fetch summary error");
    }
  };

  const exportExcel = () => {
    const config = {
      filename: `Detailed Report ${moment().format("DD MMM HHmm")}`,
      sheet: {
        data: [],
      },
    };

    let dataSet = config.sheet.data;

    const headerRow = report.distTask.map((el) => {
      let val = {};
      val.value = el.taskDetail.name;
      val.type = "string";
      return val;
    });
    headerRow.unshift({
      value: "Date/ Task",
      type: "string",
    });

    dataSet.push(headerRow);

    const uomRow = report.distTask.map((tsk) => {
      let val = {};
      val.value = tsk.taskDetail.uom;
      val.type = "string";

      return val;
    });
    uomRow.unshift({
      value: "Unit",
      type: "string",
    });

    dataSet.push(uomRow);

    let dataRow = [];
    report.data.forEach((dateRep) => {
      let valRow = [];
      valRow.push({
        value: dateRep.date ? moment(dateRep.date).format("DD/MM/YYYY") : "-",
        type: "string",
      });
      dateRep.task.forEach((tsk) => {
        valRow.push({
          value: tsk?.value ? tsk.value : "-",
          type: tsk?.value ? "number" : "string",
        });
      });
      dataSet.push(valRow);
    });

    let footerRow = [];
    footerRow.push({
      value: "Total",
      type: "string",
    });
    report.distTask.forEach((tsk) => {
      footerRow.push({
        value: tsk?.sum_work_done ? tsk?.sum_work_done : "-",
        type: "string",
      });
    });
    dataSet.push(footerRow);

    return generateExcel(config);
  };

  const exportDetailedExcel = () => {
    const config = {
      filename: `Summary Report ${moment().format("DD MMM HHmm")}`,
      sheet: {
        data: [],
      },
    };

    let dataSet = config.sheet.data;

    const headerRow = [
      {
        value: "Date",
        type: "string",
      },
      {
        value: "Activity",
        type: "string",
      },
      {
        value: "Task",
        type: "string",
      },
      {
        value: "Unit",
        type: "string",
      },
      {
        value: "Total Work",
        type: "string",
      },
      {
        value: "Completed Work",
        type: "string",
      },
      {
        value: "Daily Goal",
        type: "string",
      },
      {
        value: "Daily Work",
        type: "string",
      },
      {
        value: "Status",
        type: "string",
      },
      {
        value: "User Remark",
        type: "string",
      },
      {
        value: "Admin Remark",
        type: "string",
      },
    ];

    dataSet.push(headerRow);

    detailTaskList.forEach((tsk) => {
      let valRow = [];
      valRow.push(
        {
          value: tsk.from_date ? moment(tsk.from_date).format("DD MMM") : "-",
          type: "string",
        },
        {
          value: tsk?.activityDetail?.name ? tsk?.activityDetail?.name : "-",
          type: "string",
        },
        {
          value: tsk?.taskDetail?.name ? tsk?.taskDetail?.name : "-",
          type: "string",
        },
        {
          value: tsk?.taskDetail?.uom ? tsk?.taskDetail?.uom : "-",
          type: "string",
        },
        {
          value: tsk?.projectTask?.total_work_allocated
            ? tsk?.projectTask?.total_work_allocated
            : "-",
          // type:tsk?.projectTask?.total_work_allocated?"number":"string"
          type: "string",
        },
        {
          value: tsk?.projectTask?.total_completed
            ? tsk?.projectTask?.total_completed
            : "-",
          // type:tsk?.projectTask?.total_completed?"number":"string"
          type: "string",
        },
        {
          value: tsk?.assigned_goal ? tsk?.assigned_goal : "-",
          // type:tsk?.assigned_goal?"number":"string"?
          type: "string",
        },
        {
          value: tsk?.work_done ? tsk?.work_done : "-",
          // type:tsk?.work_done?"number":"string"
          type: "string",
        },
        {
          value: tsk?.work_status ? tsk?.work_status : "-",
          // type:tsk?.work_status?"number":"string"
          type: "string",
        },
        {
          value: tsk?.supervisor_remark ? tsk?.supervisor_remark : "-",
          type: "string",
        },
        {
          value: tsk?.admin_remark ? tsk?.admin_remark : "-",
          type: "string",
        }
      );

      dataSet.push(valRow);
    });

    return generateExcel(config);
  };

  useEffect(() => {
    setProgress((prevProgress) =>
      prevProgress >= 100 ? 10 : prevProgress + 10
    );
    fetchDetailedReport();
    fetchSummary();
  }, []);
  return (
    <Box component="div" className="tabs-reports">
      <Grid container>
        <Grid item xs={4}>
          <Box style={{ width: "200px" }} className="tabs-reports_lft">
            <FormControl
              fullWidth
              size="small"
              style={{ transform: "scale(0.9)", transformOrigin: "left" }}
            >
              <InputLabel id="demo-simple-select-label">
                All Activity
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={activity}
                label="activity"
                onChange={handleActChange}
              >
                <MenuItem value=""> All </MenuItem>
                {report.distAct.map((el) => (
                  <MenuItem value={el.activity_id}>
                    {" "}
                    {el.activityDetail.name}{" "}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            className="tabs-reports_rft"
          >
            <span
              style={{
                minWidth: "150px",
                display: "flex",
                alignItems: "baseline",
              }}
            >
              <Button onClick={exportDetailedExcel}>
                <IosShareSharpIcon fontSize="small" sx={{ mr: 1 }} /> Export
                List
              </Button>
            </span>
            <span style={{ marginRight: "10px" }}>From</span>
            <Box alignItems="center" style={{ width: "150px" }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  inputFormat="DD/MM/YYYY"
                  value={fromDate}
                  name="fromDate"
                  onChange={(val) => {
                    handleDateChange("fromDate", val);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      style={{
                        transform: "scale(0.9)",
                        transformOrigin: "left",
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
            <span style={{ marginLeft: "10px", marginRight: "10px" }}>To</span>
            <Box alignItems="center" style={{ width: "180px" }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  inputFormat="DD/MM/YYYY"
                  value={toDate}
                  name="toDate"
                  onChange={(val) => {
                    handleDateChange("toDate", val);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      style={{
                        transform: "scale(0.9)",
                        transformOrigin: "right",
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} className={classes.boxes}>
          <TableContainer>
            <Table
              style={{
                tableLayout: "fixed",
                minWidth: "1600px",
                width: "auto",
              }}
              className="report_details_table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    className={classes.sticky}
                    align="left"
                    style={{
                      background: "rgb(244 244 249)",
                      borderTop: "2px solid #dcdef1",
                      width: "200px",
                    }}
                  >
                    Date/Task
                  </TableCell>
                  {report.distTask.map((el) => (
                    <TableCell
                      className={classes.tableHeaderCell}
                      align="center"
                    >
                      {el?.taskDetail?.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow className="table_row unit_row">
                  <TableCell className={classes.sticky}>Unit</TableCell>
                  {report.distTask.map((el) => (
                    <TableCell
                      className={classes.tableHeaderCell}
                      align="center"
                    >
                      {el?.taskDetail ? el?.taskDetail?.uom : "-"}
                    </TableCell>
                  ))}
                </TableRow>
                {report.data.map((dateRep) => (
                  <NoMaxWidthTooltip title={longText}>
                    <TableRow className="table_row">
                      <>
                        <TableCell className={classes.sticky}>
                          {" "}
                          {dateRep?.date
                            ? moment(dateRep.date).format("DD/MM/YYYY")
                            : "-"}{" "}
                        </TableCell>
                        {dateRep.task.map((el) => (
                          <TableCell align="center">
                            {el?.value ? el.value : "-"}{" "}
                          </TableCell>
                        ))}
                      </>
                    </TableRow>
                  </NoMaxWidthTooltip>
                ))}
              </TableBody>

              <TableFooter>
                <TableRow className="table_row unit_row">
                  <TableCell align="left" className={classes.sticky}>
                    <Box>Total:</Box>{" "}
                  </TableCell>
                  {report.distTask.map((el) => (
                    <TableCell
                      className={classes.tableHeaderCell}
                      align="center"
                    >
                      {el?.sum_work_done ? el?.sum_work_done : "-"}
                    </TableCell>
                  ))}
                </TableRow>
              </TableFooter>
            </Table>
            <Backdrop style={{ zIndex: 9, opacity: "35%" }} open={showBackdrop}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
}
