import React, { useRef, useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Header from "../../Component/Header/Header";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import PageTitle from "../../Component/PageTitle/PageTitle";
import { borders } from "@material-ui/system";
import Divider from "@material-ui/core/Divider";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import EditIcon from "@material-ui/icons/Edit";
import TablePagination from "@mui/material/TablePagination";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import AddBoxIcon from "@material-ui/icons/AddBox";
import Edit from "../../assets/images/icon-edit.svg";
import Delete from "../../assets/images/icon-delete.svg";
import Plus from "../../assets/images/icon-plus.svg";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import AddCommentIcon from "@material-ui/icons/AddComment";
import {
  ButtonGroup,
  Snackbar,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { activityMaster, getTask } from "../../redux/action/masterAction";
import { changeTaskStatus, changeActivityStatus } from "../../redux/api/index";
import {
  GET_ACTIVITY,
  GET_TASK,
  FILTER_TASK,
  GET_ALL_TASK,
} from "../../redux/constants";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import Modal from "../../Component/Modal/Modal";
import activityList from "../../data/activityData.json";
import taskList from "../../data/taskData.json";
import {
  activity as activityApi,
  taskList as actTaskApi,
  allTask,
} from "../../redux/api";
import Subtitle from "../../Component/PageTitle/Subtitle";
import { ListItemButton } from "@mui/material";
import styled from "styled-components";
import { useStyles } from "../TableStyle";
import { DangerBtn, SaccessBtn } from "../../Pages/styles";
import Footer from "../Footer/Footer";
import { el } from "date-fns/locale";
import Tooltip from "@mui/material/Tooltip";

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#1976d2",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
  },
});

const GStyles = makeStyles(() => ({
  root: {
    display: "flex",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",

    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  boxes: {},
  table: {
    width: "100%",
    "& .MuiTableCell-body": {
      padding: "6px 0px 6px 16px",
    },
    "& .MuiTableCell-head ": {
      padding: "12px 16px",
      fontWeight: "700",
      fontSize: "1rem",
    },
  },
  listicon: {
    display: "flex",
    justifyContent: "space-between",
  },
  addicon: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    fontWeight: "700",
  },
  smallSize: {
    minWidth: "40px !important",
    height: "40px",
  },
}));

export default function Generalmaster() {
  const classes = useStyles();
  const classesbtn = GStyles();
  const dispatch = useDispatch();
  const [selectedAct, setSelectedAct] = useState(0);
  const [allTaskList, setAllTaskList] = useState([]);
  const [selectedTask, setSelectedTask] = useState(0);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [snack, setSnack] = useState({ open: false, message: "" });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [toggle, setToggle] = useState(true);
  const activity = useSelector((state) => state.master.activity);
  const task = useSelector((state) =>
    state.master.task.filter((ac) => ac.activityId == selectedAct)
  );
  const handleToggle = (actId) => {
    setToggle(!toggle);
  };
  const handleActivityChange = (id) => {
    // dispatch(getTask(id));
    setSelectedAct(id);
    // dispatch({ type: FILTER_TASK, payload: id });
  };

  const loadActivity = async () => {
    try {
      setShowBackdrop(true);
      const { data } = await activityApi();
      if (!selectedAct) {
        setSelectedAct(data.data[0].id);
      }
      dispatch({ type: GET_ACTIVITY, payload: data.data });
      loadTask();
      setShowBackdrop(false);
      // dispatch({ type: FILTER_TASK, payload: data.data[0].id });
    } catch (error) {
      setShowBackdrop(false);
      console.log("get activity list error");
    }
  };
  const loadTask = async (actId) => {
    try {
      setShowBackdrop(true);
      // const {data} = await actTaskApi(actId);
      const { data } = await allTask();
      setAllTaskList(data.data);
      dispatch({ type: GET_ALL_TASK, payload: data.data });
      setShowBackdrop(false);
    } catch (error) {
      setShowBackdrop(false);
      console.log("act task feting err");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleLoad = () => {
    loadActivity();
  };

  const handleUpdateActivityStatus = async (actId, status) => {
    try {
      setShowBackdrop(true);
      await changeActivityStatus(actId, status);
      loadActivity();
      setSnack({
        ...snack,
        open: true,
        message: "Activity status successfully changes",
      });
      setShowBackdrop(false);
    } catch (error) {
      setShowBackdrop(false);
      console.log("update activity status error");
    }
    // handleToggle(actId, status);
  };

  const handleUpdatetaskStatus = async (actId, taskId, status) => {
    try {
      setShowBackdrop(true);
      await changeTaskStatus(actId, taskId, status);
      setSnack({
        ...snack,
        open: true,
        message: "Task status successfully changes",
      });
      loadActivity();
      setShowBackdrop(false);
    } catch (error) {
      setShowBackdrop(false);
      console.log("update activity status error");
    }
  };

  useEffect(() => {
    // dispatch(activityMaster());
    // dispatch({type:GET_TASK,payload:taskList});
    // dispatch({type:GET_ACTIVITY,payload:activityList});
    handleLoad();
    loadTask();
  }, [dispatch]);

  const childCompRef = useRef();

  const [open, setOpen] = React.useState(false);

  const [modalData, setModalData] = useState({
    for: "",
    title: "",
    type: "",
    activityId: "",
    taskId: "",
    task: "",
    activity: "",
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <CssBaseline />
          <Header />
          <main className={classes.content}>
            <div className={classes.toolbar} />
            {/* <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: "10px" }}>
            <Link color="inherit" to="/dashboard">
              Dashboard
            </Link>
            <Typography color="textPrimary">General Master</Typography>
          </Breadcrumbs> */}
            <PageTitle title="General Master" />
            <Paper className={classes.boxes} elevation={4}>
              <Subtitle leftTitle="General Master" />
              <Grid container>
                <Grid
                  item
                  xs={12}
                  lg={4}
                  md={4}
                  className={classes.boxes}
                  style={{ borderRight: "1px solid #ddd" }}
                >
                  <LeftSideBar elevation={0} style={{ minHeight: "63vh" }}>
                    <List disablePadding className="listing">
                      <ListItem
                        disablePadding
                        style={{ borderBottom: "1px solid #ddd" }}
                      >
                        <ListItemButton
                          disableRipple
                          disableTouchRipple
                          disableElevation
                          style={{ paddingTop: 0, paddingBottom: 0 }}
                        >
                          <span> Activity Name</span>
                          <Tooltip title="Add Activity">
                            <IconButton
                              variant="contained"
                              color="primary"
                              style={{ padding: "4px" }}
                            >
                              <AddCommentIcon
                                color="primary"
                                onClick={() => {
                                  setOpen(true);
                                  setModalData({
                                    for: "activity",
                                    title: "Add Activity",
                                    type: "add",
                                    // activityId:act.id,
                                    // activity:act
                                  });
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </ListItemButton>
                      </ListItem>
                    </List>
                    <Divider />
                    <List disablePadding className="listing">
                      {activity.map((act, index) => (
                        <>
                          <ListItem
                            key={index}
                            onClick={() => handleActivityChange(act.id)}
                            className={classes.listicon}
                          >
                            <ListItemButton
                              disablePadding
                              className={classes.listicon}
                              selected={selectedAct == act.id ? true : false}
                            >
                              <Tooltip title={act.name}>
                                <span> {act.name}</span>
                              </Tooltip>
                              <Typography className={classes.listicon}>
                                <Tooltip title="Update Activity">
                                  <Button
                                    src={Edit}
                                    size="small"
                                    color="primary"
                                    alt="edit"
                                    style={{ minWidth: "36px" }}
                                    onClick={() => {
                                      setOpen(true);
                                      setModalData({
                                        for: "activity",
                                        title: "Update Activity",
                                        type: "edit",
                                        activityId: act.id,
                                        activity: act,
                                      });
                                    }}
                                  >
                                    <EditIcon size="small" />
                                  </Button>
                                </Tooltip>
                                <ButtonGroup
                                  variant="contained"
                                  size="small"
                                  disableElevation
                                  className="general-toggle"
                                >
                                  <Button
                                    onClick={() =>
                                      handleUpdateActivityStatus(act.id, true)
                                    }
                                    className={
                                      act.status == false ? "inactive" : ""
                                    }
                                  >
                                    Active
                                  </Button>
                                  <Button
                                    className={
                                      act.status == true ? "inactive" : ""
                                    }
                                    onClick={() =>
                                      handleUpdateActivityStatus(act.id, false)
                                    }
                                  >
                                    Inactive
                                  </Button>
                                </ButtonGroup>

                                {/* <img src={Delete} alt="delete" /> */}
                                {/* </Typography>
           <Typography > */}
                              </Typography>
                            </ListItemButton>
                          </ListItem>
                          <Divider />
                        </>
                      ))}

                      {/* <ListItem button className={classes.listicon}>
          Solar Execution
          <Typography >
            <IconButton size="small"><EditIcon color="primary"/></IconButton>
            <IconButton size="small"><DeleteForeverIcon color="secondary"/></IconButton>
         </Typography>
           
         
          </ListItem>
          <Divider/>
          <ListItem button className={classes.listicon}>
          Off-Grid SHS
         <Typography >
            <IconButton size="small"><EditIcon color="primary"/></IconButton>
            <IconButton size="small"><DeleteForeverIcon color="secondary"/></IconButton>
         </Typography>
           
         
          </ListItem>
          <Divider/> */}
                    </List>
                  </LeftSideBar>
                </Grid>

                <Grid item xs={12} md={8} lg={8} className={classes.boxes}>
                  <TableContainer
                    component={Paper}
                    style={{ boxShadow: "none", minHeight: "56vh" }}
                  >
                    <Table
                      size="small"
                      className="table-borderless table_small_padding"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">S No.</TableCell>
                          <TableCell align="left" className="task_name">
                            Task Name
                          </TableCell>
                          <TableCell align="left">UOM</TableCell>
                          <TableCell align="right">
                            <Tooltip title="Add task">
                              <IconButton
                                variant="contained"
                                color="primary"
                                style={{ padding: "4px" }}
                              >
                                <AddCommentIcon
                                  onClick={() => {
                                    setOpen(true);
                                    setModalData({
                                      for: "task",
                                      title: "Add task",
                                      type: "add",
                                      activityId: selectedAct,
                                    });
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(rowsPerPage > 0
                          ? task.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                          : task
                        ).map((tsk, ind) => (
                          <TableRow>
                            <TableCell align="left" style={{ width: "80px" }}>
                              {ind + 1}
                            </TableCell>
                            <TableCell align="left"> {tsk.name}</TableCell>
                            <TableCell align="left">{tsk.uom}</TableCell>
                            <TableCell align="right">
                              <Box>
                                <Tooltip title="Update task">
                                  <Button
                                    variant="text"
                                    size="small"
                                    onClick={() => {
                                      setModalData({
                                        for: "task",
                                        title: "Update task",
                                        type: "edit",
                                        activityId: tsk.activityId,
                                        taskId: tsk.id,
                                        task: tsk,
                                      });
                                      setOpen(true);
                                    }}
                                  >
                                    <EditIcon />
                                  </Button>
                                </Tooltip>
                                <ButtonGroup
                                  variant="contained"
                                  size="small"
                                  disableElevation
                                  className="general-toggle"
                                >
                                  <Button
                                    size="small"
                                    className={
                                      tsk.status == false ? "inactive" : ""
                                    }
                                    onClick={() =>
                                      handleUpdatetaskStatus(
                                        tsk.activityId,
                                        tsk.id,
                                        true
                                      )
                                    }
                                  >
                                    Active
                                  </Button>
                                  <Button
                                    className={
                                      tsk.status == true ? "inactive" : ""
                                    }
                                    onClick={() =>
                                      handleUpdatetaskStatus(
                                        tsk.activityId,
                                        tsk.id,
                                        false
                                      )
                                    }
                                  >
                                    Inactive
                                  </Button>
                                </ButtonGroup>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={task.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage="Task per Page"
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                  <Snackbar
                    open={snack.open}
                    autoHideDuration={3000}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    onClose={() => {
                      setSnack({
                        ...snack,
                        open: false,
                        message: "",
                      });
                    }}
                    message={snack.message}
                  />
                </Grid>
              </Grid>
            </Paper>
            <Footer />
            <Backdrop
              style={{ zIndex: 9, opacity: "35%" }}
              open={showBackdrop}
              // onClick={()}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </main>
          <Modal
            open={open}
            setOpen={setOpen}
            data={modalData}
            selectedAct={selectedAct}
            setSelectedAct={setSelectedAct}
            setSnack={setSnack}
            snack={snack}
          />
        </div>
      </ThemeProvider>
    </>
  );
}

const LeftSideBar = styled(Paper)`
  // box-shadow: 0 .46875rem 2.1875rem rgba(60,68,177,.03),0 .9375rem 1.40625rem rgba(60,68,177,.03),0 .25rem .53125rem rgba(60,68,177,.05),0 .125rem .1875rem rgba(60,68,177,.03)!important;
  .listing {
    background: #fff;
    border-radius: 0;
    li {
      &.active {
        background-color: #a2a6dadb;
        box-shadow: 0 0.18rem 0.3rem rgba(166, 166, 185, 0.5),
          0 0.326rem 3rem rgba(122, 123, 151, 0.3) !important;
      }
      padding: 0;
      div {
        span {
          padding: 6px 0px;
          font-weight: 500;
          color: #111;
          font-size: 1.04rem;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          max-width: 150px;
        }
        justify-content: space-between;
      }
    }
  }
  .general-toggle {
    button {
      font-size: 0.7rem;
      &.inactive {
        background: #ddd;
        color: #666;
      }
    }
  }
`;
