import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
const useStyles = makeStyles((theme) => ({
  pageTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(2),
    "font-weight": "400",
    "font-size": "calc(2.4rem)",
    color: "#193051",
  },
}));
export default function PageTitle(props) {
  var classes = useStyles();

  return (
    <div className={classes.pageTitleContainer}>
      <Typography className={classes.typo} variant="h5" size="sm">
        {props.title}
      </Typography>
      {props.button && props.button}
    </div>
  );
}
