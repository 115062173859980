import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@mui/material/Grid";
import { Box, Button, FormGroup, Typography } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { TextField, Backdrop, CircularProgress } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import Title from "../Title";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import styled from "styled-components";
import { useFormik } from "formik";
import { addProject, updateProject } from "../../../redux/api/index";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import "../../dashboard/Dashboard.scss";
import { getphoneState, getphoneCity } from "../../../redux/action/phoneAction";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import Tooltip from "@mui/material/Tooltip";
import { OutlinedInput } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#1565c0",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-fullWidth": {
      marginLeft: "10px",
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  paper: {
    flexDirection: "row",
    alignItems: "center",
    padding: theme.spacing(4),
  },
  formControl: {
    background: "#fff",
    width: "97%",
    position: "relative",
  },
  mb: {
    marginBottom: "20px",
  },
  textField: {
    width: "97%",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "15px",
    background: "#fff",
    "& +.errorText": {
      marginTop: "-12px",
      paddingBottom: "15px",
    },
  },
}));
export default function Projectdetails({
  setToggleOpen,
  toggleOpen,
  setProjectDetail,
  projectDetail,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const phoneState = useSelector((state) => state.phone.state);
  const phoneCity = useSelector((state) => state.phone.city);
  const navigate = useNavigate();
  const [state, setState] = React.useState("");
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [showPass, setShowPass] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();

  // const Input = styled("input")({
  //   display: "none",
  // });

  const formik = useFormik({
    initialValues: {
      project_id: "",
      project_name: "",
      start_date: null,
      etc_date: null,
      latitude: "",
      longitude: "",
      client_name: "",
      client_logo: "",
      client_email: "",
      client_phone: "",
      address: "",
      state: "",
      state_id: "",
      district: "",
      district_id: "",
      pincode: "",
      supervisor_name: "",
      supervisor_email: "",
      supervisor_phone: "",
      project_creator_name: "",
      project_password: "",
      project_access_email1: "",
      project_access_email2: "",
    },
    validate: (value) => {
      let errors = {};

      if (!value.project_name) {
        errors.project_name = "Project name is required field";
      }
      if (!value.start_date || value.start_date == "Invalid date") {
        errors.start_date = "Start Date is required field";
      }
      if (!value.etc_date || value.etc_date == "Invalid date") {
        errors.etc_date = "ETC Date is required field";
      }
      // if (value.etc_date && value.start_date) {
      //   if (
      //     new Date(moment(value.etc_date).format("YYYY-MM-DD")).getTime() <
      //     new Date(moment(value.start_date).format("YYYY-MM-DD")).getTime()
      //   ) {
      //     errors.etc_date = "Start date need to be more than start Date";
      //     errors.start_date = "ETC date should be less than etc date";
      //   }
      //   if (
      //     new Date(moment(value.start_date).format("YYYY-MM-DD")).getTime() <
      //     new Date(moment().format("YYYY-MM-DD")).getTime()
      //   ) {
      //     errors.start_date = "Start date should be More thann current date";
      //   }
      // }
      // if (!value.longitude) {
      //   errors.longitude = "Longitude is required field";
      // }
      if (value.longitude) {
        const longSpl = value.longitude.split(".");
        if (longSpl.length < 2) {
          errors.longitude = "Please enter in correct format";
        } else if (longSpl.length == 2) {
          if (longSpl[1].length < 2) {
            errors.longitude = "Please enter in correct format";
          }
        }
      }
      // if (!value.latitude) {
      //   errors.latitude = "Latitude is required field";
      // }
      if (value.latitude) {
        const longSpl = value.latitude.split(".");
        if (longSpl.length < 2) {
          errors.latitude = "Please enter correct format latitude";
        } else if (longSpl.length == 2) {
          if (longSpl[1].length < 2) {
            errors.latitude = "Please enter in correct format";
          }
        }
      }
      if (!value.client_name) {
        errors.client_name = "Client Name is required field";
      }
      if (!value.client_email) {
        errors.client_email = "Client Email is required field";
      }

      if (value.client_email) {
        if (
          !/[a-z0-9]{1,10}@[a-z0-9]{1,80}[.]{1,1}[a-z0-9]{2,80}/gm.test(
            value.client_email
          )
        ) {
          errors.client_email = "Please enter a valid email address";
        }
      }
      if (!value.client_phone) {
        errors.client_phone = "Please enter client number";
      }
      if (value.client_phone) {
        if (!/^[6-9][0-9]{9}$/i.test(value.client_phone)) {
          errors.client_phone = "Please enter a valid number";
        }
      }

      if (!value.address) {
        errors.address = "Address is required field";
      }
      if (!value.state_id) {
        errors.state_id = "State is required field";
      }
      if (!value.district_id) {
        errors.district_id = "District is required field";
      }
      if (!value.pincode) {
        errors.pincode = "Pincode is required field";
      }

      if (value.pincode) {
        if (Number(value.pincode) < 1) {
          errors.pincode = "Please enter valid Pin Code";
        }
        if (value.pincode.length < 6) {
          errors.pincode = "Please enter complete Pin Code";
        }
      }

      // if (!value.supervisor_name) {
      //   errors.supervisor_name = "Supervisor Name is required field";
      // }
      // if (!value.supervisor_email) {
      //   errors.supervisor_email = "Supervisor Email is required field";
      // }
      if (value.supervisor_email) {
        if (
          !/[a-z0-9]{1,10}@[a-z0-9]{1,80}[.]{1,1}[a-z0-9]{2,80}/gm.test(
            value.supervisor_email
          )
        ) {
          errors.supervisor_email = "Please enter a valid email address";
        }
      }
      // if (!value.supervisor_phone) {
      //   errors.supervisor_phone = "Supervisor Number is required field";
      // }
      if (value.supervisor_phone) {
        if (!/^[6-9][0-9]{9}$/i.test(value.supervisor_phone)) {
          errors.supervisor_phone = "Please enter a valid number";
        }
      }
      // if (!value.project_creator_name) {
      //   errors.project_creator_name = "Creator Name is required field";
      // }
      if (!value.project_password) {
        errors.project_password = "Project Password is required field";
      }

      return errors;
    },
  });

  const handleNextPage = async () => {
    setShowBackdrop(true);
    if (!formik.values.project_name || formik.errors.project_name) {
      formik.setFieldTouched("project_name", true);
      formik.setFieldError("project_name", "Project Name is required field");
      // return
    }
    if (!formik.values.start_date || formik.errors.start_date) {
      formik.setFieldTouched("start_date", true);
    }
    if (!formik.values.etc_date || formik.errors.etc_date) {
      formik.setFieldTouched("etc_date", true);
      // return
    }
    if (!formik.values.longitude || formik.errors.longitude) {
      formik.setFieldTouched("longitude", true);
      // return
    }
    if (!formik.values.latitude || formik.errors.latitude) {
      formik.setFieldTouched("latitude", true);
      // return
    }
    if (!formik.values.client_name || formik.errors.client_name) {
      formik.setFieldTouched("client_name", true);
      // return
    }
    if (!formik.values.client_email || formik.errors.client_email) {
      formik.setFieldTouched("client_email", true);
      // return
    }
    if (!formik.values.client_phone || formik.errors.client_phone) {
      formik.setFieldTouched("client_phone", true);
      // return
    }
    if (!formik.values.address || formik.errors.address) {
      formik.setFieldTouched("address", true);
      // return
    }
    if (!formik.values.state_id || formik.errors.state_id) {
      formik.setFieldTouched("state_id", true);
      // return
    }
    if (!formik.values.district_id || formik.errors.district_id) {
      formik.setFieldTouched("district_id", true);
      // return
    }
    if (!formik.values.pincode || formik.errors.pincode) {
      formik.setFieldTouched("pincode", true);
      // return
    }
    if (!formik.values.supervisor_name || formik.errors.supervisor_name) {
      formik.setFieldTouched("supervisor_name", true);
      // return
    }
    if (!formik.values.supervisor_email || formik.errors.supervisor_email) {
      formik.setFieldTouched("supervisor_email", true);
      // return
    }
    if (!formik.values.supervisor_phone || formik.errors.supervisor_phone) {
      formik.setFieldTouched("supervisor_phone", true);
      // return
    }
    if (
      !formik.values.project_creator_name ||
      formik.errors.project_creator_name
    ) {
      formik.setFieldTouched("project_creator_name", true);
      // return
    }
    if (!formik.values.project_password || formik.errors.project_password) {
      formik.setFieldTouched("project_password", true);
      // return
    }
    let errorNo = Object.keys(formik.errors);

    if (errorNo.length > 0) {
      setShowBackdrop(false);
      return;
    }

    if (!formik.values.project_name) {
      setShowBackdrop(false);
      return;
    }

    const formData = new FormData();
    formData.append("project_name", formik.values.project_name);
    formData.append("start_date", formik.values.start_date);
    formData.append("etc_date", formik.values.etc_date);
    formData.append("longitude", formik.values.longitude);
    formData.append("latitude", formik.values.latitude);
    formData.append("client_name", formik.values.client_name);
    formData.append("client_email", formik.values.client_email);
    formData.append("client_phone", formik.values.client_phone);
    formData.append("address", formik.values.address);
    formData.append("state_id", formik.values.state_id);
    formData.append("district_id", formik.values.district_id);
    formData.append("pincode", formik.values.pincode);
    formData.append("supervisor_name", formik.values.supervisor_name);
    formData.append("supervisor_email", formik.values.supervisor_email);
    formData.append("supervisor_phone", formik.values.supervisor_phone);
    formData.append("project_creator_name", formik.values.project_creator_name);
    formData.append("project_password", formik.values.project_password);
    formData.append("logo", formik.values.client_logo);
    setShowBackdrop(true);
    try {
      setShowBackdrop(true);
      const projData = {
        ...formik.values,
      };
      if (searchParams.get("projectid")) {
        const { data } = await updateProject(
          searchParams.get("projectid"),
          formData
        );
        setProjectDetail(data.data);
        setToggleOpen(2);
        setShowBackdrop(false);
      } else {
        const { data } = await addProject(formData);
        setProjectDetail(data.data);
        setToggleOpen(2);
        setSearchParams({
          projectid: data.data.id,
        });
      }
      setShowBackdrop(false);
    } catch (error) {
      setShowBackdrop(false);
      console.log("project add error", error.message);
    }

    // setToggleOpen(2)
  };

  // const [accessEmail, setAccessEmail] = useState([
  //   {
  //     id: 1,
  //     email: "",
  //     errMsg: "",
  //   },
  // ]);

  const handleEmail = (e) => {
    const isEmail =
      /[a-z0-9]{1,10}@[a-z0-9]{1,80}[.]{1,1}[a-z0-9]{2,80}/gm.test(
        e.target.value
      );
    formik.setFieldValue([e.target.name], e.target.value);
    // formik.setFieldError([e.target.name], "Plese use valid email address");
  };

  const handleName = (e) => {
    if (e.target.name == "project_name") {
      formik.setFieldValue(
        [e.target.name],
        e.target.value.replace(/[$&/+,:;=?@#|[\]\\'<>.^*()%!_-]/gi, "")
      );
    } else {
      formik.setFieldValue(
        [e.target.name],
        e.target.value.replace(/[0-9$&/+,:;=?@#|[\]\\'<>.^*()%!_-]/gi, "")
      );
    }
  };
  const handlePhone = (e) => {
    formik.setFieldValue(
      [e.target.name],
      e.target.value.replace(/[a-zA-Z$&/+,:;=?@#|'<>.^*()%!_-]/gi, "")
    );
  };
  const handleLatLong = (e) => {
    formik.setFieldValue(
      [e.target.name],
      e.target.value.replace(/[a-zA-Z$&/+,:;=?@#|'<>^*()%!_-]/gi, "")
    );
  };

  const handleLogoChange = (e) => {
    formik.setFieldValue("client_logo", e.target.files[0]);
  };

  useEffect(() => {
    dispatch(getphoneState());
  }, []);

  return (
    <>
      <Backdrop
        style={{ zIndex: 9 }}
        open={showBackdrop}
        // onClick={()}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ThemeProvider theme={theme}>
        <form className="formInput" noValidate autoComplete="off">
          <Grid container className={classes.mb}>
            <Grid item xs={12} sm={3}>
              <TextField
                autoComplete="project_name"
                size="small"
                label="Project Name"
                name="project_name"
                variant="outlined"
                required
                fullWidth
                id="project_name"
                placeholder="Project Name"
                className={classes.textField}
                value={formik.values.project_name}
                onChange={handleName}
                // {...formik.getFieldProps('project_name')}
                // autoFocus
              />
              {formik.touched.project_name && formik.errors.project_name && (
                <p className="errorText">{formik.errors.project_name}</p>
              )}
            </Grid>
            <Grid item xs={12} sm={3}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  // placeholder="Start Date"
                  // minDate={moment(new Date())}
                  maxDate={
                    formik.values.etc_date
                      ? moment(new Date(formik.values.etc_date))
                      : moment(new Date("2100-01-01"))
                  }
                  inputFormat="DD/MM/YYYY"
                  name="start_date"
                  value={formik.values.start_date}
                  label="Start Date"
                  onChange={(e) => {
                    // props.row.original.completed_date = moment(e).utc().format();
                    formik.setFieldValue(
                      "start_date",
                      moment(e).utc().format()
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      name="start_date"
                      size="small"
                      required
                      className={classes.textField}
                      variant="outlined"
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
              {formik.touched.start_date && formik.errors.start_date && (
                <p className="errorText">{formik.errors.start_date}</p>
              )}
            </Grid>
            <Grid xs={12} sm={3}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  minDate={
                    formik.values.start_date
                      ? moment(new Date(formik.values.start_date))
                      : moment(new Date())
                  }
                  inputFormat="DD/MM/YYYY"
                  name="etc_date"
                  label="Expected Completion Date"
                  value={formik.values.etc_date}
                  onChange={(e) => {
                    formik.setFieldValue("etc_date", moment(e).utc().format());
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      required
                      className={classes.textField}
                      variant="outlined"
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
              {formik.touched.etc_date && formik.errors.etc_date && (
                <p className="errorText">{formik.errors.etc_date}</p>
              )}
            </Grid>
            <Grid xs={12} sm={3}>
              <TextField
                size="small"
                label="Longitude"
                name="longitude"
                variant="outlined"
                fullWidth
                placeholder="Longitude"
                inputProps={{ maxLength: 8 }}
                className={classes.textField}
                value={formik.values.longitude}
                onChange={handleLatLong}
                // focused={formik?.errors?.longitude?true:false}
                //  {...formik.getFieldProps('latitide')}
              />
              {formik.touched.longitude && formik.errors.longitude && (
                <p className="errorText">{formik.errors.longitude}</p>
              )}
            </Grid>
            <Grid xs={12} sm={3}>
              <TextField
                size="small"
                name="latitude"
                variant="outlined"
                fullWidth
                label="Latitude"
                placeholder="Latitude"
                inputProps={{ maxLength: 8 }}
                className={classes.textField}
                value={formik.values.latitude}
                onChange={handleLatLong}
                // focused={formik?.errors?.latitude?true:false}
              />
              {formik.touched.latitude && formik.errors.latitude && (
                <p className="errorText">{formik.errors.latitude}</p>
              )}
            </Grid>

            <Grid item xs={12} sm={3}>
              <Tooltip title="Would be 600x600px, 320x320px">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    name="client_logo"
                    // value={formik.values.client_logo}
                    multiple
                    type="file"
                    onChange={(e) => {
                      handleLogoChange(e);
                    }}
                  />

                  <Button
                    variant="contained"
                    component="span"
                    color="primary"
                    style={{ width: "97%" }}
                  >
                    {formik?.values?.client_logo?.name
                      ? formik.values.client_logo.name
                      : "Client Logo"}{" "}
                    <PhotoCamera sx={{ ml: 1 }} />
                    {}
                  </Button>
                </label>
              </Tooltip>
            </Grid>
          </Grid>

          <Grid container className={classes.mb}>
            <Grid item xs={12} sm={12}>
              <Title title="Client Details" />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                autoComplete="Client Name"
                label="Client Name"
                name="client_name"
                size="small"
                variant="outlined"
                required
                fullWidth
                id="client_name"
                placeholder="Client Name"
                className={classes.textField}
                value={formik.values.client_name}
                onChange={handleName}
                // {...formik.getFieldProps('client_name')}
                // autoFocus
              />
              {formik.touched.client_name && formik.errors.client_name && (
                <p className="errorText">{formik.errors.client_name}</p>
              )}
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                autoComplete="Client Email"
                name="client_email"
                label="Client Email"
                variant="outlined"
                size="small"
                required
                fullWidth
                id="client_email"
                placeholder="Client Email"
                type="email"
                className={classes.textField}
                value={formik.values.client_email}
                onChange={handleEmail}
                // {...formik.getFieldProps('client_email')}
                // autoFocus
              />
              {formik.touched.client_email && formik.errors.client_email && (
                <p className="errorText">{formik.errors.client_email}</p>
              )}
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                autoComplete="Client Number"
                name="client_phone"
                label="Client Number"
                variant="outlined"
                size="small"
                required
                fullWidth
                id="client_number"
                placeholder="Client Number"
                type="text"
                value={formik.values.client_phone}
                inputProps={{ maxLength: 10 }}
                onChange={handlePhone}
                // {...formik.getFieldProps('client_phone')}
                className={classes.textField}
                // autoFocus
              />
              {formik.touched.client_phone && formik.errors.client_phone && (
                <p className="errorText">{formik.errors.client_phone}</p>
              )}
            </Grid>
          </Grid>

          <Grid container className={classes.mb}>
            <Grid item xs={12} sm={12}>
              <Title title="Address" />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                autoComplete="Address"
                label="Address"
                name="address"
                variant="outlined"
                size="small"
                required
                fullWidth
                id="address"
                placeholder="Address"
                className={classes.textField}
                {...formik.getFieldProps("address")}
                // autoFocus
              />
              {formik.touched.address && formik.errors.address && (
                <p className="errorText">{formik.errors.address}</p>
              )}
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl
                variant="outlined"
                fullWidth
                required
                className={classes.formControl}
                size="small"
              >
                <InputLabel>State</InputLabel>
                <Select
                  label="State"
                  name="state"
                  fullWidth
                  {...formik.getFieldProps("state")}
                  // placeholder="State"
                  value={formik.values.state_id}
                  onChange={(e) => {
                    formik.setFieldValue("state_id", e.target.value);
                    dispatch(getphoneCity(e.target.value));
                  }}
                >
                  <MenuItem value="" disabled>
                    <em>Select State</em>
                  </MenuItem>
                  {phoneState.map((st, ind) => (
                    <MenuItem key={st.id} value={st.id}>
                      {st.name}{" "}
                    </MenuItem>
                  ))}
                  {/* <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
              </FormControl>
              {formik.touched.state_id && formik.errors.state_id && (
                <p className="errorText">{formik.errors.state_id}</p>
              )}
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl
                variant="outlined"
                fullWidth
                required
                className={classes.formControl}
                size="small"
              >
                <InputLabel>District</InputLabel>
                <Select
                  label="District"
                  name="district"
                  // {...formik.getFieldProps("district_id")}
                  value={formik.values.district_id}
                  // placeholder="district"
                  onChange={(e) => {
                    formik.setFieldValue("district_id", e.target.value);
                  }}
                >
                  <MenuItem value="" disabled>
                    <em>Select District</em>
                  </MenuItem>
                  {phoneCity.map((cit, ind) => (
                    <MenuItem key={`district${cit.id}`} value={cit.id}>
                      {cit.name}{" "}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {formik.touched.district_id && formik.errors.district_id && (
                <p className="errorText">{formik.errors.district_id}</p>
              )}
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                autoComplete="Pin code"
                size="small"
                name="pincode"
                label="Pin code"
                variant="outlined"
                required
                fullWidth
                id="pincode"
                placeholder="Pin Code"
                type="text"
                inputProps={{ maxLength: 6 }}
                className={classes.textField}
                value={formik.values.pincode}
                onChange={handlePhone}
                // {...formik.getFieldProps('pincode')}
                // autoFocus
              />
              {formik.touched.pincode && formik.errors.pincode && (
                <p className="errorText">{formik.errors.pincode}</p>
              )}
            </Grid>
          </Grid>

          <Grid container className={classes.mb}>
            <Grid item xs={12} sm={12}>
              <Title title="Supervisor Details" />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                autoComplete="supervisor_name"
                size="small"
                label="Supervisor Name"
                name="supervisor_name"
                variant="outlined"
                fullWidth
                id="supervisor_name"
                placeholder="Supervisor Name"
                className={classes.textField}
                value={formik.values.supervisor_name}
                onChange={handleName}
                // {...formik.getFieldProps('supervisor_name')}
                // autoFocus
              />
              {formik.touched.supervisor_name &&
                formik.errors.supervisor_name && (
                  <p className="errorText">{formik.errors.supervisor_name}</p>
                )}
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                autoComplete="Supervisor Email"
                size="small"
                label="Supervisor Email"
                name="supervisor_email"
                variant="outlined"
                fullWidth
                placeholder="Supervisor Email"
                type="email"
                className={classes.textField}
                value={formik.values.supervisor_email}
                // {...formik.getFieldProps('supervisor_email')}
                onChange={handleEmail}
                // autoFocus
              />
              {formik.touched.supervisor_email &&
                formik.errors.supervisor_email && (
                  <p className="errorText">{formik.errors.supervisor_email}</p>
                )}
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                autoComplete="Supervisor Contact Number"
                size="small"
                name="supervisor_phone"
                variant="outlined"
                label="Supervisor Contact Number"
                fullWidth
                placeholder="Supervisor Contact Number"
                type="text"
                inputProps={{ maxLength: 10 }}
                value={formik.values.supervisor_phone}
                onChange={handlePhone}
                // {...formik.getFieldProps('supervisor_phone')}
                className={classes.textField}
                // autoFocus
              />
              {formik.touched.supervisor_phone &&
                formik.errors.supervisor_phone && (
                  <p className="errorText">{formik.errors.supervisor_phone}</p>
                )}
            </Grid>
          </Grid>

          <Grid container className={classes.mb}>
            <Grid item xs={12} sm={12}>
              <Title title="Project Creator Details" />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                autoComplete="Creator Name"
                name="project_creator_name"
                size="small"
                label="Creator Name"
                variant="outlined"
                fullWidth
                id="creator_name"
                placeholder="Creator Name"
                className={classes.textField}
                // {...formik.getFieldProps("project_creator_name")}
                value={formik.values.project_creator_name}
                onChange={handleName}
                // autoFocus
              />
              {formik.touched.project_creator_name &&
                formik.errors.project_creator_name && (
                  <p className="errorText">
                    {formik.errors.project_creator_name}
                  </p>
                )}
            </Grid>
            <Grid item xs={12} sm={3}>
              <OutlinedInput
                autoComplete="Project Password"
                name="project_password"
                size="small"
                // label="Project Password"
                required
                fullWidth
                // variant="outlined"
                placeholder="Project Password"
                type={showPass ? "text" : "password"}
                style={{ background: "#fff", cursor: "pointer" }}
                className={classes.textField}
                {...formik.getFieldProps("project_password")}
                endAdornment={
                  <Box
                    style={{ lineHeight: "normal" }}
                    onClick={() => {
                      setShowPass(!showPass);
                    }}
                  >
                    {showPass ? <VisibilityOff /> : <Visibility />}
                  </Box>
                }
                // autoFocus
              />
              {formik.touched.project_password &&
                formik.errors.project_password && (
                  <p className="errorText">{formik.errors.project_password}</p>
                )}
            </Grid>
          </Grid>

          {/* <Grid container className={classes.mb}>
        <Grid item xs={12} sm={12}>
            <Title title="Project Access Emails">
                <InputButton variant="contained" color="default" size="small" style={{'marginLeft':'2%'}}
                onClick={()=>{
                  setAccessEmail([...accessEmail,{id:accessEmail.length+1,email:"",errMsg:""}])
                }
                }
                >Add More <AddCircleIcon m={1}/></InputButton>
            </Title>
        </Grid>

{
  accessEmail.map((accesE,index)=>(
    <Grid item xs={12} sm={3}>
    <TextField autoComplete="Access Emails"
            name={`project_access_email${accesE.id}`}
            variant="outlined"
            size='small'
            required
            fullWidth
            id="project_access_email1"
            label={`Email ${accesE.id}`}
            type="email"
            className={classes.textField}
            // autoFocus
            value={
              accesE.email
            }
            onChange={(e)=>{
              // console.log(accessEmail[index])
              accessEmail[index].email=e.target.value;
              setAccessEmail([...accessEmail])
            }}
            // {...formik.getFieldProps('project_access_email1')}
             />
        </Grid>

  ))
}
        
        </Grid> */}

          <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                // console.log(accessEmail)
                handleNextPage();
              }}
            >
              {" "}
              Next <NavigateNextIcon />
            </Button>
          </Grid>
        </form>
      </ThemeProvider>
    </>
  );
}

const Input = styled("input")({
  display: "none",
});

const InputButton = styled(Button)`
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  box-shadow: 0 0.313rem 0.8rem rgb(60 68 177 / 50%),
    0 0.126rem 0.225rem rgb(60 68 177 / 30%);
  background: #3c44b1;
  color: #fff;
  text-transform: capitalize;
  svg {
    margin-left: 8px;
  }
  &:hover {
    background: #3c44b1;
    color: #fff;
    box-shadow: 0 0.313rem 0.8rem rgb(60 68 177 / 50%),
      0 0.126rem 0.225rem rgb(60 68 177 / 30%);
  }
`;
