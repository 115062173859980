import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Header from "../../Component/Header/Header";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import PageTitle from "../../Component/PageTitle/PageTitle";
import { Paper, Grid, Button, Box } from "@mui/material";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import AddCommentIcon from "@material-ui/icons/AddComment";
import Addtask from "../AddTask/Addtask";
import { NavLink } from "react-router-dom";
import projectList from "../../data/projectData.json";
import { useDispatch, useSelector } from "react-redux";
import { GET_PROJECT_LIST } from "../../redux/constants";
import AllProject from "./AllProject";
import CompletedProject from "./CompletedProject";
import ProgressProj from "./ProgressProject";
import HoldProject from "./HoldProject";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import styled from "styled-components";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import generateExcel from "zipcelx";
import Tooltip from "@mui/material/Tooltip";

import {
  DangerBtn,
  SaccessBtn,
  PrimaryBtn,
  NeutralBtn,
} from "../../Pages/styles";
import Footer from "../Footer/Footer";
import { projectListAction } from "../../redux/action/projectAction";

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#1976d2",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
  },
});
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    overflow: "auto",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  table: {
    width: "100%",
    "& .MuiTableCell-body": {},
    "& .MuiTableCell-head ": {
      padding: "35px 15px 16px",
      fontWeight: "700",
      fontSize: "1rem",
    },
  },
  tabsButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0",
    marginBottom: "10px",
    "& li:last-child": {
      flexShrink: 3,
    },
  },
}));

export default function Projectmanagement() {
  const classes = useStyles();
  const [toggleOpen, setToggleOpen] = useState(1);
  const [projSearch, setprojSearch] = useState("");
  const [searchNow, setSearchNow] = useState("");
  const toggleTabs = (index) => {
    setToggleOpen(index);
  };

  const dispatch = useDispatch();
  const allProject = useSelector((state) => state.project.allProject);
  const completedProj = useSelector((state) => state.project.completed);
  const holdProj = useSelector((state) => state.project.hold);
  const progressProj = useSelector((state) => state.project.inprogress);

  const handleSearch = () => {
    console.log(searchNow);
  };

  function getExcel() {
    const config = {
      filename: "all project",
      sheet: {
        data: [],
      },
    };

    let dataSet = config.sheet.data;

    // review with one level nested config
    // HEADERS
    const headerRow = [
      {
        value: "Sr. No",
        type: "string",
      },
      {
        value: "Project Id",
        type: "string",
      },
      {
        value: "Project Name",
        type: "string",
      },
      {
        value: "Client Name",
        type: "string",
      },
      {
        value: "Location",
        type: "string",
      },
      {
        value: "Start Date",
        type: "string",
      },
      {
        value: "Status",
        type: "string",
      },
      {
        value: "Progress",
        type: "string",
      },
    ];

    dataSet.push(headerRow);

    allProject.map((el, ind) => {
      let val = [
        {
          value: ind + 1,
          type: "string",
        },
        {
          value: el.project_id,
          type: "string",
        },
        {
          value: el.project_name,
          type: "string",
        },
        {
          value: el.client_name,
          type: "string",
        },
        {
          value: el.address,
          type: "string",
        },
        {
          value: el.start_date,
          type: "string",
        },
        {
          value: el.status,
          type: "string",
        },
        {
          value: el.total_work,
          type: "string",
        },
      ];

      dataSet.push(val);
    });

    return generateExcel(config);
  }

  useEffect(() => {
    dispatch(projectListAction());
    // dispatch({ type: GET_PROJECT_LIST, payload: projectList });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <Header />
        <main className={classes.content} style={{ overflow: "auto" }}>
          <div className={classes.toolbar} />
          {/* <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" to="/dashboard">
              Dashboard
            </Link>
            <Typography color="textPrimary">Project Management</Typography>
          </Breadcrumbs> */}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            style={{ width: "100%" }}
          >
            <PageTitle title="Project Management" />
            <Box className={classes.root}>
              <Button
                className="exportlist"
                display="flex"
                alignItems="center"
                size="small"
                color="primary"
                onClick={() => {
                  getExcel();
                }}
              >
                {" "}
                Export List{" "}
                <UploadFileIcon
                  onClick={() => {
                    getExcel();
                  }}
                />
              </Button>
              {/* <Paper
                component="form"
                style={{
                  p: "4px",
                  display: "flex",
                  alignItems: "center",
                  width: 400,
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1, pl: 2 }}
                  placeholder="Search Projects"
                  inputProps={{ "aria-label": "search Projects" }}
                  value={projSearch}
                  onChange={(e) => {
                    setprojSearch(e.target.value);
                  }}
                />
                <IconButton
                  sx={{ p: "10px" }}
                  aria-label="search"
                  onClick={handleSearch}
                >
                  <SearchIcon />
                </IconButton>
              </Paper> */}
            </Box>
          </Box>
          <Listing>
            <List className={classes.tabsButton} disablePadding>
              <ListItem disablePadding>
                <ListItemButton
                  style={{ background: "#F5F3F3", color: "#5E5873" }}
                  variant="contained"
                  onClick={() => toggleTabs(1)}
                  className={toggleOpen === 1 ? "tabs active-tabs" : "tabs"}
                >
                  Project Total
                  <span
                    className="txt"
                    style={{ background: "#5E5873", color: "#fff" }}
                  >
                    {allProject.length}
                  </span>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  style={{ background: "#EBEEFF", color: "#6E6B7B" }}
                  variant="contained"
                  className={toggleOpen === 2 ? "tabs active-tabs" : "tabs"}
                  onClick={() => toggleTabs(2)}
                >
                  Completed Projects{" "}
                  <span
                    className="txt"
                    style={{ background: "#536DFE", color: "#fff" }}
                  >
                    {" "}
                    {completedProj.length}{" "}
                  </span>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  style={{ background: "#E1FFF5", color: "#5E5873" }}
                  variant="contained"
                  className={toggleOpen === 3 ? "tabs active-tabs" : "tabs"}
                  onClick={() => toggleTabs(3)}
                >
                  Inprogress Projects{" "}
                  <span
                    className="txt"
                    style={{ background: "#3CD4A0", color: "#6E6B7B" }}
                  >
                    {" "}
                    {progressProj.length}{" "}
                  </span>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  style={{ background: "#FFF5E4", color: "#6E6B7B" }}
                  variant="contained"
                  className={toggleOpen === 4 ? "tabs active-tabs" : "tabs"}
                  onClick={() => toggleTabs(4)}
                >
                  Hold Projects{" "}
                  <span
                    className="txt"
                    style={{ background: "#FFC260", color: "#6E6B7B" }}
                  >
                    {" "}
                    {holdProj.length}{" "}
                  </span>
                </ListItemButton>
              </ListItem>
              <Tooltip title="Add Project">
                <ListItem disablePadding>
                  <NavLink to="add-project" style={{ lineHeight: "0" }}>
                    <ListItemButton variant="contained" className="tabs">
                      <AddCommentIcon color="primary" />{" "}
                    </ListItemButton>
                  </NavLink>
                </ListItem>
              </Tooltip>
            </List>
          </Listing>

          <Grid container>
            <Grid item xs={12} className={classes.boxes}>
              {toggleOpen === 1 && (
                <AllProject
                  projSearch={projSearch}
                  setprojSearch={setprojSearch}
                  handleSearch={handleSearch}
                  searchNow={searchNow}
                  setSearchNow={setSearchNow}
                />
              )}
              {toggleOpen === 2 && (
                <CompletedProject
                  projSearch={projSearch}
                  setprojSearch={setprojSearch}
                />
              )}
              {toggleOpen === 3 && (
                <ProgressProj
                  projSearch={projSearch}
                  setprojSearch={setprojSearch}
                />
              )}
              {toggleOpen === 4 && (
                <HoldProject
                  projSearch={projSearch}
                  setprojSearch={setprojSearch}
                />
              )}
            </Grid>
          </Grid>
          <Footer />
        </main>
      </div>
    </ThemeProvider>
  );
}

const Listing = styled.div`
  background: white;
  .tabs {
    display: block;
    width: 100%;
    align-items: center;
    padding: 15px;
    font-weight: bold;
    font-size: 0.95rem;
    // border-bottom: 4px solid transparent;
    position: relative;
    margin-bottom: -4px;
    &:hover {
      color: #1976d2;
      border-color: #1976d2;
      // box-shadow: 0 0.313rem 0.8rem rgb(60 68 177 / 50%), 0 0.126rem 0.225rem rgb(60 68 177 / 30%);
    }

    & > span.txt {
      background: #bdc2e5;
      color: #3545b0;
      text-align: center;
      height: 26px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      border-radius: 30px;
      font-weight: bold;
      top: 14px;
      right: 20px;
      position: absolute;
    }
  }
  .tabs.active-tabs {
    &:nth-child(1) {
      color: #5e5873;
      // border-color: #5E5873;
      background: rgba(0, 0, 0, 0.02);
    }

    // box-shadow: 0 0.313rem 0.8rem rgb(60 68 177 / 50%), 0 0.126rem 0.225rem rgb(60 68 177 / 30%);
  }
  .btn:not(:disabled):hover {
    transform: translateY(-2px);
  }
  ul {
    li {
      border-right: 1px solid #ddd;
    }
  }
`;
