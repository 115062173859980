import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Header from "../../Component/Header/Header";
import PageTitle from "../../Component/PageTitle/PageTitle";
import { Grid, Paper, Backdrop, CircularProgress } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import {
  DangerBtn,
  SaccessBtn,
  PrimaryBtn,
  NeutralBtn,
} from "../../Pages/styles";
import { useStyles, btnStyles } from "../TableStyle";
import { NavLink } from "react-router-dom";
import Link from "@mui/material/Link";
import Footer from "../Footer/Footer";
import Subtitle from "../../Component/PageTitle/Subtitle";
import { Input } from "@material-ui/core";
import LinkIcon from "@mui/icons-material/Link";
import Button from "@mui/material/Button";
import { getProjectList } from "../../redux/api/index";

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#ffffff",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
  },
});

export default function ReportsView() {
  const classes = useStyles();
  const [projectList, setProjectList] = useState([]);
  const [page, setPage] = React.useState(0);
  const [showBackdrop, setShowBackdrop] = useState(false);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const fetchProjects = async () => {
    try {
      setShowBackdrop(true);
      const { data } = await getProjectList();
      setProjectList(data.data);
      setShowBackdrop(false);
    } catch (error) {
      setShowBackdrop(false);
      console.log("all projetc fetch error");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <Header />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {/* <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/" onClick={handleClick}>
              Dashboard
            </Link>
            <Typography color="textPrimary">Reports</Typography>
          </Breadcrumbs> */}
          <PageTitle title="Reports" />
          <Paper elevation={4}>
            <Grid container>
              <Grid item xs={12} className={classes.boxes}>
                <Subtitle
                  leftTitle="Report Listing"
                  rftValue={
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={projectList.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      labelRowsPerPage="Task per Page"
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  }
                  className="dash_box_daily_task"
                />

                <Table size="small" className={classes.tableCellClasses}>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHeaderCell}>
                        Project ID
                      </TableCell>
                      <TableCell className={classes.tableHeaderCell}>
                        Project Name
                      </TableCell>
                      <TableCell className={classes.tableHeaderCell}>
                        Client Name
                      </TableCell>
                      <TableCell className={classes.tableHeaderCell}>
                        Status
                      </TableCell>
                      <TableCell
                        className={classes.tableHeaderCell}
                        align="center"
                      >
                        View Report
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0
                      ? projectList.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : projectList
                    ).map((data) => {
                      return (
                        <TableRow>
                          <TableCell> {data.project_id} </TableCell>
                          <TableCell> {data.project_name}</TableCell>
                          <TableCell> {data.client_name} </TableCell>
                          <TableCell> {data.status} </TableCell>
                          <TableCell align="center">
                            <NavLink to={`${data.id}`}>
                              <Button
                                variant="contained"
                                size="small"
                                style={{ minWidth: "40px" }}
                              >
                                <LinkIcon size="small" />
                              </Button>
                            </NavLink>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </Paper>
          <Backdrop
            style={{ zIndex: 9, opacity: "35%" }}
            open={showBackdrop}
            // onClick={()}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Footer />
        </main>
      </div>
    </ThemeProvider>
  );
}
