import React, { useEffect, useState, useMemo } from "react";
import { Button, Paper } from "@mui/material";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@mui/material/Checkbox";
import { TextField, Snackbar, Backdrop, CircularProgress } from "@mui/material";
import "../../Pages/dashboard/Dashboard.scss";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import Pagination from "./Pagination";
import { useParams } from "react-router-dom";
import { addProjectTask } from "../../redux/api/index";
import "../dashboard/Dashboard.scss";
export default function Addtask({
  projrectDetail,
  setProjrectDetail,
  selectedAct,
  setSelectedAct,
  curAct,
  addAct,
  getprojectDetail,
}) {
  const param = useParams();
  const [checkedTask, setcheckedTask] = useState([]);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [snack, setSnack] = useState({ open: false, message: "" });
  const [selectedAllDate, setSelectedAllDate] = useState({
    scheduled_date: null,
    etc_date: null,
  });

  const handleCheck = (val) => {
    const isAdded = checkedTask.find((el) => Number(el.id) === Number(val.id));
    if (isAdded) {
      const filtCk = checkedTask.filter(
        (el) => Number(el.id) !== Number(val.id)
      );
      setcheckedTask(filtCk);
      isAdded.error_goal = "";
      isAdded.error_scheduled_date = "";
      isAdded.error_etc_date = "";
      setProjrectDetail({ ...projrectDetail });
    } else {
      setcheckedTask([...checkedTask, val]);
    }
  };

  const handleCheckAll = (val) => {
    if (val) {
      if (Number(addAct) === 0) {
        const alllTask = projrectDetail?.notAddedTask?.filter((el) => true);
        setcheckedTask(alllTask);
      } else {
        const alllTask = projrectDetail?.notAddedTask?.filter(
          (el) => Number(el.activityId) === Number(addAct)
        );
        const withoutCurAct = checkedTask.filter(
          (el) => Number(el.activityId) !== Number(addAct)
        );
        setcheckedTask([...withoutCurAct, ...alllTask]);
      }
    } else {
      if (Number(addAct) === 0) {
        setcheckedTask([]);
      } else {
        const filteredCheckedtask = checkedTask.filter(
          (el) => Number(el.activityId) !== Number(addAct)
        );
        setcheckedTask([...filteredCheckedtask]);
      }
    }
    setProjrectDetail({ ...projrectDetail });
  };

  let errArr = [];
  const handleSubmit = async () => {
    let outData = checkedTask.map((el) => {
      let val = {};
      val.project_id = param.projectid;
      val.total_work_allocated = el.goal;
      val.task_id = el.id;
      val.activity_id = el.activityId;
      val.scheduled_date = el.scheduled_date;
      val.etc_date = el.etc_date;

      if (!el.goal) {
        el.error_goal = "Please enter goal";
        errArr.push(el);
      } else {
        el.error_goal = "";
      }
      if (!el.scheduled_date || el.scheduled_date === "Invalid date") {
        el.error_scheduled_date = "Please select scheduled date";
        errArr.push(el);
      }
      // else if (
      //   new Date(moment(el.scheduled_date).format("YYYY-MM-DD")).getTime() >
      //   new Date(moment(el.etc_date).format("YYYY-MM-DD")).getTime()
      // ) {
      //   el.error_scheduled_date = "Scheduled date should less than ETC date";
      //   errArr.push(el);
      // }
      // else if (
      //   new Date(moment(el.scheduled_date).format("YYYY-MM-DD")).getTime() <
      //   new Date(moment().format("YYYY-MM-DD")).getTime()
      // ) {
      //   el.error_scheduled_date =
      //     "Scheduled date should greater than current date";
      //   errArr.push(el);
      // }
      else {
        el.error_scheduled_date = "";
      }
      if (!el.etc_date || el.etc_date === "Invalid date") {
        el.error_etc_date = "Please select ETC date";
        errArr.push(el);
      } else if (
        new Date(moment(el.etc_date).format("YYYY-MM-DD")).getTime() <
        new Date(moment(el.scheduled_date).format("YYYY-MM-DD")).getTime()
      ) {
        el.error_etc_date = "ETC Date need to be greater than scheduled Date";
        errArr.push(el);
      } else {
        el.error_etc_date = "";
      }

      return val;
    });
    setProjrectDetail({ ...projrectDetail });

    if (errArr.length > 0) {
      setSnack({
        ...snack,
        open: true,
        message: "Please fix the error before adding task",
      });
      return;
    }
    if (outData.length === 0) {
      setSnack({
        ...snack,
        open: true,
        message: "Please check task you wish to add",
      });
      return;
    }

    try {
      setShowBackdrop(true);
      await addProjectTask(param.projectid, outData);
      setSnack({
        ...snack,
        open: true,
        message: "Task added successfully",
      });
      const checkedTaskId = checkedTask.map((el) => el.id);
      const filterProj = projrectDetail.notAddedTask.map((tsk) => {
        return checkedTaskId.indexOf(tsk.id) === -1;
      });
      projrectDetail.notAddedTask = filterProj;
      setProjrectDetail({ ...projrectDetail });

      getprojectDetail({ isUpdating: true });
      setShowBackdrop(false);
    } catch (error) {
      setSnack({
        ...snack,
        open: true,
        message:
          error?.response?.data?.message ||
          error?.message ||
          "Something went wrong, please try again later",
      });
      setShowBackdrop(false);
    }
  };

  const COLUMNS = [
    {
      Header: (props) => (
        <Checkbox
          size="small"
          checked={
            Number(addAct) !== 0
              ? projrectDetail?.notAddedTask.length > 0 &&
                projrectDetail?.notAddedTask?.filter(
                  (ts) => Number(ts.activityId) === Number(addAct)
                ).length ===
                  checkedTask.filter(
                    (ts) => Number(ts.activityId) === Number(addAct)
                  ).length
              : projrectDetail?.notAddedTask.length > 0 &&
                projrectDetail?.notAddedTask?.length === checkedTask.length
          }
          onClick={(e) => {
            handleCheckAll(e.target.checked);
          }}
        />
      ),
      accessor: "id",
      Cell: (props) => (
        <Checkbox
          size="small"
          color="primary"
          inputProps={{ "aria-label": "secondary checkbox" }}
          checked={
            checkedTask.find(
              (el) =>
                Number(el.id) === Number(props?.row?.original?.id) &&
                Number(el.activityId) ===
                  Number(props?.row.original?.activityId)
            )
              ? true
              : false
          }
          onChange={() => {
            handleCheck(props?.row?.original);
          }}
        />
      ),
    },
    {
      Header: "S No.",
      Cell: (props) => <>{props.row.index + 1}</>,
    },
    {
      Header: "Task Name",
      accessor: "name",
    },
    {
      Header: "UOM",
      accessor: "uom",
    },
    {
      Header: "Total Work",
      accessor: "goal",
      Cell: (props) => (
        <>
          <TextField
            label="Goal"
            size="small"
            placeholder="400"
            inputProps={{ maxLength: 9 }}
            value={props.row.original.goal}
            name="goal"
            style={{ transform: "scale(0.9)", transformOrigin: "left" }}
            onChange={(e) => {
              props.row.original.goal = e.target.value.replace(
                /[a-zA-[\]\\Z$&/+,:;=?@#|'<>.^*()%!_-]/gi,
                ""
              );
              setProjrectDetail({ ...projrectDetail });
            }}
          />

          {props?.row?.original?.error_goal && (
            <p className="errorText">{props.row.original.error_goal} </p>
          )}
        </>
      ),
    },
    {
      Header: () => (
        <>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Scheduled Date"
              minDate={moment(new Date(projrectDetail.start_date))}
              inputFormat="DD/MM/YYYY"
              value={selectedAllDate.scheduled_date}
              onChange={(e) => {
                setSelectedAllDate({
                  ...selectedAllDate,
                  scheduled_date: moment(e).utc().format(),
                });
                // props.row.original.scheduled_date = moment(e).utc().format();
                setProjrectDetail({ ...projrectDetail });
              }}
              renderInput={(params) => (
                <TextField
                  size="small"
                  variant="outlined"
                  {...params}
                  style={{ transform: "scale(0.9)", transformOrigin: "left" }}
                />
              )}
            />
          </LocalizationProvider>
        </>
      ),
      accessor: "scheduled_date",
      Cell: (props) => (
        <>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Scheduled Date"
              minDate={moment(new Date(projrectDetail.start_date))}
              maxDate={
                props?.row?.original?.etc_date
                  ? moment(new Date(props?.row?.original?.etc_date))
                  : moment(new Date("2100-01-01"))
              }
              inputFormat="DD/MM/YYYY"
              value={props.row?.original.scheduled_date}
              onChange={(e) => {
                props.row.original.scheduled_date = moment(e).utc().format();
                setProjrectDetail({ ...projrectDetail });
              }}
              renderInput={(params) => (
                <TextField
                  size="small"
                  variant="outlined"
                  {...params}
                  fullWidth
                  style={{ transform: "scale(0.9)", transformOrigin: "left" }}
                />
              )}
            />
          </LocalizationProvider>
          {props?.row?.original?.error_scheduled_date && (
            <p className="errorText">
              {props.row.original.error_scheduled_date}{" "}
            </p>
          )}
        </>
      ),
    },
    {
      Header: (props) => (
        <>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="ETC Date"
              minDate={moment(new Date())}
              inputFormat="DD/MM/YYYY"
              value={props.row.original.etc_date}
              onChange={(e) => {
                props.row.original.etc_date = moment(e).utc().format();
                setProjrectDetail({ ...projrectDetail });
              }}
              renderInput={(params) => (
                <TextField
                  size="small"
                  variant="outlined"
                  {...params}
                  fullWidth
                  style={{ transform: "scale(0.9)", transformOrigin: "left" }}
                />
              )}
            />
          </LocalizationProvider>
        </>
      ),
      accessor: "etc_date",
      Cell: (props) => (
        <>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="ETC Date"
              minDate={
                props?.row?.original?.scheduled_date
                  ? moment(props?.row?.original?.scheduled_date)
                  : moment(new Date())
              }
              inputFormat="DD/MM/YYYY"
              value={props.row.original.etc_date}
              onChange={(e) => {
                props.row.original.etc_date = moment(e).utc().format();
                setProjrectDetail({ ...projrectDetail });
              }}
              renderInput={(params) => (
                <TextField
                  size="small"
                  variant="outlined"
                  {...params}
                  fullWidth
                  style={{ transform: "scale(0.9)", transformOrigin: "left" }}
                />
              )}
            />
          </LocalizationProvider>
          {props?.row?.original?.error_etc_date && (
            <p className="errorText">{props.row.original.error_etc_date} </p>
          )}
        </>
      ),
    },
  ];

  const columns = useMemo(() => COLUMNS, [addAct, checkedTask]);
  const data = useMemo(
    () =>
      projrectDetail.notAddedTask.filter((el) => {
        if (Number(addAct) !== 0) {
          return Number(el.activityId) === Number(addAct);
        } else {
          const isAvail = projrectDetail.add.find(
            (act) => Number(act.activityId) === Number(el.activityId)
          )?.detail;
          return isAvail ? true : false;
        }
      }),
    [addAct, checkedTask, projrectDetail]
  );
  const tableInstance = useTable(
    {
      columns: columns,
      // data: projrectDetail.add.find((el) => el.activity_id == addAct)?.task
      //   ? projrectDetail.add.find((el) => el.activity_id == addAct)?.task: approveT.add[0].task,
      data: data,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    pageCount,
    pageOptions,
    setPageSize,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    prepareRow,
    state,
  } = tableInstance;

  const { pageIndex, pageSize } = state;

  useEffect(() => {
    getprojectDetail({ isUpdating: true });
  }, []);

  return (
    <>
      {/* <Backdrop
        style={{ zIndex: 9 }}
        open={showBackdrop}
       
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <TableContainer component={Paper}>
        <Table
          {...getTableProps()}
          size="small"
          className="report-table add-task-project"
          style={{ width: "100%" }}
        >
          <TableHead>
            {/* {headerGroups.map((headerGroup,index) => (
              <TableRow key={`header-addTask-${index+1}`} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell>{column.render("Header")}</TableCell>
                ))}
              </TableRow>
            ))} */}

            <TableRow className="aprrove-task">
              <TableCell>
                <Checkbox
                  size="small"
                  checked={
                    Number(addAct) !== 0
                      ? projrectDetail?.notAddedTask.length > 0 &&
                        projrectDetail?.notAddedTask?.filter(
                          (ts) => Number(ts.activityId) === Number(addAct)
                        ).length ===
                          checkedTask.filter(
                            (ts) => Number(ts.activityId) === Number(addAct)
                          ).length
                      : projrectDetail?.notAddedTask.length > 0 &&
                        projrectDetail?.notAddedTask?.length ===
                          checkedTask.length
                  }
                  onClick={(e) => {
                    handleCheckAll(e.target.checked);
                  }}
                />
              </TableCell>
              <TableCell>S No.</TableCell>
              <TableCell>Task Name</TableCell>
              <TableCell>UOM</TableCell>
              <TableCell>Total Work</TableCell>
              <TableCell style={{ paddingLeft: "10px" }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label="Scheduled Date"
                    minDate={moment(new Date(projrectDetail.start_date))}
                    inputFormat="DD/MM/YYYY"
                    value={selectedAllDate.scheduled_date}
                    onChange={(e) => {
                      setSelectedAllDate({
                        ...selectedAllDate,
                        scheduled_date: moment(e).utc().format(),
                      });

                      if (Number(addAct) === 0) {
                        data.map((el) => {
                          el.scheduled_date = moment(e).utc().format();
                          return el;
                        });
                      } else {
                        data
                          .filter(
                            (el) => Number(el.activityId) === Number(addAct)
                          )
                          .map((el) => {
                            el.scheduled_date = moment(e).utc().format();
                            return el;
                          });
                      }

                      setProjrectDetail({ ...projrectDetail });
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        variant="outlined"
                        {...params}
                        fullWidth
                        style={{
                          transform: "scale(0.9)",
                          transformOrigin: "left",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </TableCell>
              <TableCell style={{ paddingLeft: "10px" }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label="ETC Date"
                    minDate={moment(new Date())}
                    inputFormat="DD/MM/YYYY"
                    value={selectedAllDate.etc_date}
                    onChange={(e) => {
                      setSelectedAllDate({
                        ...selectedAllDate,
                        etc_date: moment(e).utc().format(),
                      });

                      if (Number(addAct) === 0) {
                        data.map((el) => {
                          el.etc_date = moment(e).utc().format();
                          return el;
                        });
                      } else {
                        data
                          .filter(
                            (el) => Number(el.activityId) === Number(addAct)
                          )
                          .map((el) => {
                            el.etc_date = moment(e).utc().format();
                            return el;
                          });
                      }

                      setProjrectDetail({ ...projrectDetail });
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        variant="outlined"
                        {...params}
                        fullWidth
                        style={{
                          transform: "scale(0.9)",
                          transformOrigin: "left",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <TableRow
                  key={`add-task-row-${index}`}
                  {...row.getRowProps()}
                  className="aprrove-task"
                >
                  {row.cells.map((cell, index) => {
                    return (
                      <TableCell
                        key={`add-task-row-cell-${index}`}
                        style={{ textAlign: "left", maxWidth: "200px" }}
                      >
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}

            <TableRow>
              {rows.length > 0 ? (
                <>
                  <TableCell
                    colSpan={14}
                    style={{ padding: "20px", textAlign: "center" }}
                    align="center"
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      style={{ minWidth: "200px" }}
                      onClick={() => handleSubmit()}
                    >
                      Add
                    </Button>
                  </TableCell>
                </>
              ) : (
                <TableCell
                  colSpan={14}
                  style={{ padding: "20px" }}
                  align="center"
                >
                  No Data Available
                </TableCell>
              )}
            </TableRow>
          </TableBody>
        </Table>
        {rows.length > 0 && (
          <Pagination
            page={page}
            nextPage={nextPage}
            previousPage={previousPage}
            pageCount={pageCount}
            setPageSize={setPageSize}
            pageSize={pageSize}
            pageOptions={pageOptions}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            rows={rows}
            pageIndex={pageIndex}
          />
        )}
      </TableContainer>
      <Snackbar
        open={snack.open}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        autoHideDuration={3000}
        onClose={() => {
          setSnack({
            ...snack,
            open: false,
            message: "",
          });
        }}
        message={snack.message}
      />
    </>
  );
}
