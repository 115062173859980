import React, { useState, useEffect, useMemo } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Header from "../../Component/Header/Header";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import PageTitle from "../../Component/PageTitle/PageTitle";
import {
  Paper,
  Grid,
  Button,
  IconButton,
  Box,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import AddCommentIcon from "@material-ui/icons/AddComment";
import Addtask from "../AddTask/Addtask";
import { Link, NavLink, useNavigate } from "react-router-dom";
import projectList from "../../data/projectData.json";
import { useDispatch, useSelector } from "react-redux";
import { GET_PROJECT_LIST } from "../../redux/constants";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { projectListAction } from "../../redux/action/projectAction";
import moment from "moment";

import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import Pagination from "./Pagination";
import { useStyles, btnStyles } from "../TableStyle";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

const useStylesFacebook = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  top: {
    color: "#1a90ff",
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
  circle: {
    strokeLinecap: "round",
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#1F487C",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
  },
});

const useButton = makeStyles({
  root: {
    background: "#FE6B8B",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 60,
    padding: "0 30px",
    width: "100%",
    fontWeight: "700",
    marginBottom: "-20px",
    display: "flex",
    justifyContent: "space-between",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  },
  label: {
    textTransform: "capitalize",
  },
});

export default function Projectmanagement() {
  const classes = useStyles();
  const navigate = useNavigate();
  const cbtn = useButton();
  const [searchInp, setSearchInp] = useState("");
  const [toggleOpen, setToggleOpen] = useState(1);
  const toggleTabs = (index) => {
    setToggleOpen(index);
  };

  const dispatch = useDispatch();
  const completedProj = useSelector((state) => state.project.completed);

  const COLUMNS = [
    {
      Header: "S No",
      accessor: "serial_no",
      Cell: (props) => (
        <>
          <p> {Number(props.row.id) + Number(1)} </p>
        </>
      ),
    },
    {
      Header: "Project Id",
      accessor: "project_id",
    },
    {
      Header: "Project Name",
      accessor: "project_name",
    },
    {
      Header: "Client Name",
      accessor: "client_name",
    },
    {
      Header: "Location",
      accessor: "address",
    },
    {
      Header: "Start Date",
      accessor: "start_date",
      Cell: (props) => (
        <>{props.value ? moment(props.value).format("DD/MM/YYYY") : "-"}</>
      ),
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Progress",
      Cell: () => (
        <BorderLinearProgress
          variant="determinate"
          color="primary"
          value={70}
        />
      ),
    },
  ];

  const columns = useMemo(() => COLUMNS, []);
  const tableData = useMemo(() => completedProj, [completedProj]);

  const tableInstance = useTable(
    {
      columns: columns,
      data: tableData,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    pageCount,
    pageOptions,
    setPageSize,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    prepareRow,
    state,
    setGlobalFilter,
    setFilter,
    setAllFilters,
    preFilteredRows,
    toggleSortBy,
  } = tableInstance;

  const { globalFilter, filters, pageIndex, pageSize } = state;

  const handleNavigate = (id) => {
    navigate(`${id}`);
  };

  useEffect(() => {
    dispatch(projectListAction());
    // dispatch({ type: GET_PROJECT_LIST, payload: projectList });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Paper elevation={4}>
        <Grid container style={{ overflow: "auto" }}>
          <Grid
            item
            xs={12}
            className={classes.boxes}
            style={{ overflow: "auto" }}
          >
            <Table {...getTableProps()} style={{ minWidth: "1200px" }}>
              <TableHead>
                {headerGroups.map((headerGroup) => (
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <TableCell
                        className={classes.tableHeaderCell}
                        style={{
                          background: "#ebeeff",
                          display: "table-cell",
                          minWidth: "120px",
                          fontSize: "13px",
                        }}
                      >
                        {column.render("Header")}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableRow>
                <TableCell
                  colSpan={8}
                  style={{ padding: "20px" }}
                  align="center"
                >
                  <OutlinedInput
                    fullWidth
                    variant="outlined"
                    placeholder="Search Project"
                    size="small"
                    value={searchInp}
                    style={{
                      background: "#426efd12",
                    }}
                    onChange={(e) => {
                      setSearchInp(e.target.value);
                      // setGlobalFilter(e.target.value)
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setGlobalFilter(searchInp);
                          }}
                        >
                          <SearchIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setGlobalFilter("");
                            setSearchInp("");
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </TableCell>
              </TableRow>

              <TableBody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <TableRow
                      {...row.getRowProps()}
                      onClick={() => handleNavigate(row.original.id)}
                      className="tbcell"
                    >
                      {row.cells.map((cell) => {
                        return <TableCell>{cell.render("Cell")}</TableCell>;
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Pagination
              page={page}
              nextPage={nextPage}
              previousPage={previousPage}
              pageCount={pageCount}
              setPageSize={setPageSize}
              pageSize={pageSize}
              pageOptions={pageOptions}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              rows={rows}
              pageIndex={pageIndex}
            />
          </Grid>
        </Grid>
      </Paper>
    </ThemeProvider>
  );
}
