import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Button from "@mui/material/Button";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import PageTitle from "../../../Component/PageTitle/PageTitle";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Divider,
  Toolbar,
  Tooltip,
  Backdrop,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeOutlinedIcon from "@material-ui/icons/NavigateBeforeOutlined";
import ActivitiesTasks from "../Activities/ActivitiesTasks";
import ActivitiesNavbar from "../Activities/ActivitiesNavbar";
import styled from "styled-components";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useSelector, useParams } from "react-redux";
import moment from "moment";
import { addProjectTask } from "../../../redux/api/index";

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#1565c0",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
  },
});
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  item: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0.5, 1),

    "&> p": {
      fontSize: "14px",
      "&:first-child": {
        fontWeight: "bold",
      },
    },
  },
  leftlist: {
    minHeight: "40vh",
  },
}));

export default function Preview({
  selectedTask,
  setSelectedTask,
  setProjectDetail,
  projectDetail,
  checkedTask,
  setCheckedTask,
  setToggleOpen,
  setActivityId,
  activityId,
}) {
  const classes = useStyles();

  let [searchParams, setSearchParams] = useSearchParams();
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [snack, setSnack] = useState({ open: false, message: "" });
  const navigate = useNavigate();

  const projectid = searchParams.get("projectid");
  const project = useSelector((state) =>
    state.project.allProject.find((proj) => proj.id == projectid)
  );

  let errArr = [];
  const handleCreate = async () => {
    setShowBackdrop(true);
    const finalD = checkedTask.map((el) => {
      let val = {};
      val.task_id = el.id;
      val.activity_id = el.activity_id;
      val.goal = el.goal;
      val.total_work_allocated = el.goal;
      val.etc_date = el.etc_date;
      val.scheduled_date = el.scheduled_date;
      val.project_id = projectid;
      if (!el.goal) {
        el.error_goal = "Please enter goal";
        errArr.push(el);
      } else {
        el.error_goal = "";
      }
      if (!el.scheduled_date || el.scheduled_date == "Invalid date") {
        el.error_scheduled_date = "Please select scheduled date";
        errArr.push(el);
      }
      // else if (
      //   new Date(moment(el.scheduled_date).format("YYYY-MM-DD")).getTime() >
      //   new Date(moment(el.etc_date).format("YYYY-MM-DD")).getTime()
      // ) {
      //   el.error_scheduled_date = "Scheduled date should less than ETC date";
      //   errArr.push(el);
      // }
      // else if (
      //   new Date(moment(el.scheduled_date).format("YYYY-MM-DD")).getTime() <
      //   new Date(moment().format("YYYY-MM-DD")).getTime()
      // ) {
      //   el.error_scheduled_date =
      //     "Scheduled date should greater than current date";
      //   errArr.push(el);
      // }
      else {
        el.error_scheduled_date = "";
      }
      if (!el.etc_date || el.etc_date == "Invalid date") {
        el.error_etc_date = "Please select ETC date";
        errArr.push(el);
      } else if (
        new Date(moment(el.etc_date).format("YYYY-MM-DD")).getTime() <
        new Date(moment(el.scheduled_date).format("YYYY-MM-DD")).getTime()
      ) {
        el.error_etc_date = "ETC Date need to be greater than scheduled Date";
        errArr.push(el);
      } else {
        el.error_etc_date = "";
      }
      setSelectedTask([...selectedTask]);
      return val;
    });

    if (errArr.length > 0) {
      setShowBackdrop(false);
      return;
    }

    if (finalD.length == 0) {
      setShowBackdrop(false);
      return;
    }

    try {
      await addProjectTask(projectid, finalD);
      setShowBackdrop(false);
      navigate("/project-management");
    } catch (error) {
      setShowBackdrop(false);
      console.log("project task assign error");
    }
  };

  useEffect(() => {}, []);
  return (
    <>
      <ThemeProvider theme={theme}>
        <Grid container>
          <Grid item xs={3}>
            <Box p={2}>
              <Box className={classes.item}>
                <Typography color="primary">PROJECT ID</Typography>
                <Typography>{projectDetail?.project_id}</Typography>
              </Box>
              <Box className={classes.item}>
                <Typography color="primary">LATITUDE</Typography>
                <Typography>{projectDetail.latitude}</Typography>
              </Box>
              <Box className={classes.item}>
                <Typography color="primary">CLIENT NAME</Typography>
                <Typography>{projectDetail?.client_name} </Typography>
              </Box>
              <Box className={classes.item}>
                <Typography color="primary">ADDRESS</Typography>
                <Typography>{projectDetail?.address} </Typography>
              </Box>
              <Box className={classes.item}>
                <Typography color="primary">SUPERVISOR NAME</Typography>
                <Typography>{projectDetail?.supervisor_name}</Typography>
              </Box>
              <Box className={classes.item}>
                <Typography color="primary">CREATOR NAME</Typography>
                <Typography> {projectDetail?.project_creator_name} </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box p={2}>
              <Box className={classes.item}>
                <Typography color="primary">PROJECT NAME</Typography>
                <Typography> {projectDetail?.project_name} </Typography>
              </Box>
              <Box className={classes.item}>
                <Typography color="primary">LATITUDE</Typography>
                <Typography> {projectDetail?.latitude} </Typography>
              </Box>
              <Box className={classes.item}>
                <Typography color="primary">CLIENT EMAIL</Typography>
                <Typography> {projectDetail?.client_email} </Typography>
              </Box>
              <Box className={classes.item}>
                <Typography color="primary">STATE</Typography>
                <Typography> {projectDetail?.state?.name} </Typography>
              </Box>
              <Box className={classes.item}>
                <Typography color="primary">SUPERVISOR EMAIL</Typography>
                <Typography> {projectDetail?.supervisor_email} </Typography>
              </Box>
              <Box className={classes.item}>
                <Typography color="primary">PROJECT PASSWORD</Typography>
                <Typography>{projectDetail?.project_password} </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box p={2}>
              <Box className={classes.item}>
                <Typography color="primary">STARTING DATE</Typography>
                <Typography>
                  {" "}
                  {projectDetail?.start_date
                    ? moment(projectDetail.start_date).format("DD/MM/YYYY")
                    : "-"}{" "}
                </Typography>
              </Box>
              <Box className={classes.item}>
                <Typography color="primary">CLIENT NUMBER</Typography>
                <Typography> {projectDetail?.client_phone} </Typography>
              </Box>
              <Box className={classes.item}>
                <Typography color="primary">DISTRICT</Typography>
                <Typography> {projectDetail?.district?.name} </Typography>
              </Box>
              <Box className={classes.item}>
                <Typography color="primary">SUPERVISOR CONTACT NO.</Typography>
                <Typography>{projectDetail.supervisor_phone} </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box p={2}>
              <Box className={classes.item}>
                <Typography color="primary">ETC DATE</Typography>
                <Typography>
                  {projectDetail?.etc_date
                    ? moment(projectDetail.etc_date).format("DD/MM/YYYY")
                    : "-"}{" "}
                </Typography>
              </Box>
              <Box className={classes.item}>
                <Typography color="primary">PIN CODE</Typography>
                <Typography>{projectDetail.pincode} </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Divider />

        <Grid
          container
          spacing={0}
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={2}>
                <ActivitiesNavbar
                  activityId={activityId}
                  setActivityId={setActivityId}
                  selectedTask={selectedTask}
                  setSelectedTask={setSelectedTask}
                />
              </Grid>
              <Grid item xs={10} style={{ position: "relative" }}>
                <ActivitiesTasks
                  activityId={activityId}
                  setActivityId={setActivityId}
                  selectedTask={selectedTask}
                  setSelectedTask={setSelectedTask}
                  checkedTask={checkedTask}
                  setCheckedTask={setCheckedTask}
                />
                <Grid item xs={12}>
                  <Box
                    p={3}
                    textAlign="center"
                    style={{ position: "relative", bottom: "0", width: "100%" }}
                  >
                    <Button
                      color="primary"
                      size="small"
                      onClick={() => {
                        setToggleOpen(2);
                      }}
                    >
                      {" "}
                      <NavigateBeforeOutlinedIcon /> Back{" "}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{ marginLeft: "20px" }}
                      onClick={() => handleCreate()}
                    >
                      {" "}
                      Create <NavigateNextIcon />
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ThemeProvider>
      <Backdrop
        style={{ zIndex: 9 }}
        open={showBackdrop}
        // onClick={()}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={snack.open}
        autoHideDuration={3000}
        onClose={() => {
          setSnack({
            ...snack,
            open: false,
            message: "",
          });
        }}
        message={snack.message}
      />
    </>
  );
}

const InputButton = styled(Button)`
  align-items: center;
  justify-content: center;
  font-weight: bold;
  box-shadow: 0 0.313rem 0.8rem rgb(60 68 177 / 50%),
    0 0.126rem 0.225rem rgb(60 68 177 / 30%);
  background: #3c44b1;
  color: #fff;
  text-transform: capitalize;
  padding: 6px 20px;
  svg {
    margin-left: 8px;
  }
  &:hover {
    background: #3c44b1;
    color: #fff;
    box-shadow: 0 0.313rem 0.8rem rgb(60 68 177 / 50%),
      0 0.126rem 0.225rem rgb(60 68 177 / 30%);
  }
`;
