import logo from "./logo.svg";
import "./App.css";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Login from "./Component/Login";
import SupervisorLogin from "./Component/SupervisorLogin";
import Dashboard from "./Pages/dashboard/Dashboard";
import Generalmaster from "./Pages/generalmaster/Generalmaster";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Projectmanagement from "./Pages/Projectmanagement/Projectmanagement";
import Addtask from "./Pages/AddTask/Addtask";
import Projectdetails from "./Pages/ProjectDetails/Projectdetails";
import AllProject from "./Pages/Projectmanagement/AllProject";
import Reportsmain from "./Pages/Reports/Reportsmain";
import ProjectSummary from "./Pages/Reports/ProjectSummary";
import Supervisor from "./Pages/Supervisor/Supervisor";
import Assignmenthistory from "./Pages/Supervisor/Assignmenthistory";
import FutureAssignments from "./Pages/Supervisor/FutureAssignments";
import ProjectDetail from "./Pages/ProjectDetails/Projectdetails";
// import Header from "./Component/Header/Header";
import ReportsView from "./Pages/Reports/ReportsView";
import Notfound from "./Error/Notfound";

function App() {
  return (
    <Router>
      <CssBaseline />
      <Routes>
        <Route path="/" element={<Login />} />

        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/general-master" element={<Generalmaster />} />
        <Route path="project-summary" element={<ProjectSummary />} />

        <Route path="view-report/:projectId">
          <Route path="" element={<Reportsmain />} />
        </Route>

        <Route path="project-management">
          <Route path="" element={<Projectmanagement />} />
          <Route path="add-project" element={<Addtask />} />
          <Route path=":projectid" element={<ProjectDetail />} />
          <Route path=":projectid/supervisor" element={<Supervisor />} />
          <Route
            path=":projectid/supervisor/assignment-history"
            element={<Assignmenthistory />}
          />
          <Route
            path=":projectid/supervisor/assignment-future"
            element={<FutureAssignments />}
          />
        </Route>
        <Route path="/reportview" element={<ReportsView />} />
        <Route path="/reportview/:projectId" element={<Reportsmain />} />
        <Route path="supervisor">
          <Route path="login" element={<SupervisorLogin />} />
          <Route path="view-assignments/:projectid" element={<Supervisor />} />
          <Route
            path="view-assignments/:projectid/history"
            element={<Assignmenthistory />}
          />
          <Route
            path="view-assignments/:projectid/future"
            element={<FutureAssignments />}
          />
        </Route>
        <Route path="/projectdetails" element={<Projectdetails />} />
        <Route path="/supervisor" element={<Supervisor />} />
        <Route path="/assignmenthistory" element={<Assignmenthistory />} />
        <Route path="/reportview" element={<ReportsView />} />
        <Route path="*" element={<Notfound />} />
      </Routes>
    </Router>
  );
}

export default App;
