import React from "react";
import Typography from "@material-ui/core/Typography";
import { Box, Button, Grid, Paper } from "@material-ui/core";
import Divider from "@mui/material/Divider";
import "../../Pages/dashboard/Dashboard.scss";
export default function Subtitle({
  leftTitle,
  dateInput,
  rftValue,
  className = "",
}) {
  return (
    <>
      <Typography
        variant="h6"
        sx={{
          p: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        component={Box}
        className={className}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "30rem",
          }}
        >
          <Typography variant="h6" className="subtitle">
            {leftTitle}
          </Typography>
          {dateInput}
        </Box>
        <span style={{ fontWeight: "bold", fontSize: "14px" }}>{rftValue}</span>
      </Typography>
      <Divider></Divider>
    </>
  );
}
