import React, { useState, useEffect, useMemo } from "react";
import { Box, Button, Grid, Paper } from "@material-ui/core";
import { Divider, Toolbar, Tooltip } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@mui/material/Checkbox";
import { TextField, Snackbar } from "@mui/material";
import {
  DangerBtn,
  SaccessBtn,
  PrimaryBtn,
  NeutralBtn,
} from "../../Pages/styles";
import ButtonGroup from "@mui/material/ButtonGroup";
import "../../Pages/dashboard/Dashboard.scss";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { useParams } from "react-router-dom";
import { updateProjectTask } from "../../redux/api/index";
import { useSelector } from "react-redux";
import Pagination from "./Pagination";

export default function UpdateTask({
  projrectDetail,
  setProjrectDetail,
  selectedAct,
  setSelectedAct,
  curAct,
  getprojectDetail,
}) {
  const [checkedTask, setcheckedTask] = useState([]);
  const [snack, setSnack] = useState({ open: false, message: "" });
  const [selectedAllDate, setSelectedAllDate] = useState({
    scheduled_date: null,
    etc_date: null,
  });

  const approveT = useSelector((state) => state.project.currentProject);
  const param = useParams();
  const handleCheck = (val) => {
    const isAdded = checkedTask.find((el) => el.id == val.id);
    if (isAdded) {
      const filtCk = checkedTask.filter((el) => el.id != val.id);
      setcheckedTask(filtCk);
      isAdded.error_update_total_work_allocated = "";
      isAdded.error_update_scheduled_date = "";
      isAdded.error_update_etc_date = "";
      setProjrectDetail({ ...projrectDetail });
    } else {
      setcheckedTask([...checkedTask, val]);
    }
  };

  const handleCheckAll = (val) => {
    if (val) {
      if (selectedAct == 0) {
        const alllTask = projrectDetail?.addedTask?.filter((el) => true);
        setcheckedTask(alllTask);
      } else {
        const alllTask = projrectDetail?.addedTask?.filter(
          (el) => el.activity_id == selectedAct
        );
        const withoutCurAct = checkedTask.filter(
          (el) => el.activity_id != selectedAct
        );
        setcheckedTask([...withoutCurAct, ...alllTask]);
      }
    } else {
      if (selectedAct == 0) {
        setcheckedTask([]);
      } else {
        const filteredCheckedtask = checkedTask.filter(
          (el) => el.activity_id != selectedAct
        );
        setcheckedTask([...filteredCheckedtask]);
      }
    }
  };
  let errArr = [];
  const handleSubmit = async () => {
    let outData = checkedTask.map((el) => {
      let val = {};
      val.id = el.id;
      val.total_work_allocated = el.total_work_allocated;
      val.scheduled_date = el.scheduled_date;
      val.etc_date = el.etc_date;

      if (!el.total_work_allocated) {
        el.error_update_total_work_allocated = "Please enter goal";
        errArr.push(el);
      } else {
        el.error_update_total_work_allocated = "";
      }
      if (!el.scheduled_date || el.scheduled_date == "Invalid date") {
        el.error_update_scheduled_date = "Please select scheduled date";
        errArr.push(el);
      } else if (
        new Date(moment(el.scheduled_date).format("YYYY-MM-DD")).getTime() >
        new Date(moment(el.etc_date).format("YYYY-MM-DD")).getTime()
      ) {
        el.error_update_scheduled_date =
          "Scheduled date should less than ETC date";
        errArr.push(el);
      }
      //  else if (
      //   new Date(moment(el.scheduled_date).format("YYYY-MM-DD")).getTime() <
      //   new Date(moment().format("YYYY-MM-DD")).getTime()
      // ) {
      //   el.error_update_scheduled_date =
      //     "Scheduled date should greater than current date";
      //   errArr.push(el);
      // }
      else {
        el.error_update_scheduled_date = "";
      }
      if (!el.etc_date || el.etc_date == "Invalid date") {
        el.error_update_etc_date = "Please select ETC date";
        errArr.push(el);
      } else {
        el.error_update_etc_date = "";
      }

      return val;
    });
    setProjrectDetail({ ...projrectDetail });

    if (outData.length == 0) {
      setSnack({
        ...snack,
        open: true,
        message: "Please check task you wish to update",
      });
      return;
    }
    if (errArr.length > 0) {
      setSnack({
        ...snack,
        open: true,
        message: "Please correct the errors to update them",
      });
      return;
    }

    try {
      await updateProjectTask(param.projectid, outData);
      getprojectDetail({ isUpdating: true });
      setcheckedTask([]);
      setSnack({
        ...snack,
        open: true,
        message: "Task successfully updated",
      });
    } catch (error) {
      setSnack({
        ...snack,
        open: true,
        message:
          error?.response?.data?.message ||
          error?.message ||
          "Something went wrong, please try again later",
      });
      console.log("task update error");
    }
  };

  const COLUMNS = [
    {
      Header: (props) => (
        <Checkbox
          size="small"
          checked={
            selectedAct != 0
              ? projrectDetail?.addedTask.length > 0 &&
                projrectDetail?.addedTask?.filter(
                  (ts) => ts.activity_id == selectedAct
                ).length ==
                  checkedTask.filter((ts) => ts.activity_id == selectedAct)
                    .length
              : projrectDetail?.addedTask.length > 0 &&
                projrectDetail?.addedTask?.length == checkedTask.length
          }
          color="primary"
          inputProps={{ "aria-label": "secondary checkbox" }}
          onClick={(e) => {
            handleCheckAll(e.target.checked);
          }}
        />
      ),
      accessor: "id",
      Cell: (props) => (
        <Checkbox
          size="small"
          color="primary"
          inputProps={{ "aria-label": "secondary checkbox" }}
          checked={
            checkedTask.find(
              (el) =>
                el.id == props?.row?.original?.id &&
                el.activity_id == props?.row.original?.activity_id
            )
              ? true
              : false
          }
          onChange={() => {
            handleCheck(props?.row?.original);
          }}
        />
      ),
    },
    {
      Header: "S No.",
      Cell: (props) => <> {props.row.index + 1}</>,
      accessor: "taskDetail.id",
    },
    {
      Header: "Task Name",
      Cell: (props) => <>{props.value}</>,
      accessor: "taskDetail.name",
    },
    {
      Header: "UOM",
      accessor: "taskDetail.uom",
    },
    {
      Header: "Total Work",
      accessor: "total_work_allocated",
      Cell: (props) => (
        <>
          <TextField
            size="small"
            placeholder="Goal"
            fullWidth
            value={props?.row?.original?.total_work_allocated}
            inputProps={{ maxLength: 9 }}
            name="goal"
            style={{ transform: "scale(0.9)", transformOrigin: "left" }}
            onChange={(e) => {
              props.row.original.total_work_allocated = e.target.value.replace(
                /[a-zA-[\]\\Z$&/+,:;=?@#|'<>.^*()%!_-]/gi,
                ""
              );
              setProjrectDetail({ ...projrectDetail });
            }}
          />
          {props.row.original.error_update_total_work_allocated && (
            <p className="errorText">
              {props.row.original.error_update_total_work_allocated}
            </p>
          )}
        </>
      ),
    },
    {
      Header: "Scheduled Date",
      accessor: "scheduled_date",
      Cell: (props) => (
        <>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              placeholder="Scheduled Date"
              minDate={moment(new Date(projrectDetail.start_date))}
              maxDate={
                props?.row?.original?.etc_date
                  ? moment(new Date(props?.row?.original?.etc_date))
                  : moment(new Date("2100-01-01"))
              }
              inputFormat="DD/MM/YYYY"
              value={props?.row?.original?.scheduled_date}
              onChange={(e) => {
                props.row.original.scheduled_date = moment(e).utc().format();
                // props.row.original.etc_date = e;
                setProjrectDetail({ ...projrectDetail });
              }}
              renderInput={(params) => (
                <TextField
                  size="small"
                  variant="outlined"
                  {...params}
                  fullWidth
                  style={{ transform: "scale(0.9)", transformOrigin: "left" }}
                />
              )}
            />
          </LocalizationProvider>
          {props.row.original.error_update_scheduled_date && (
            <p className="errorText">
              {props.row.original.error_update_scheduled_date}
            </p>
          )}
        </>
      ),
    },
    {
      Header: "ETC Date",
      accessor: "etc_date",
      Cell: (props) => (
        <>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              placeholder="ETC Date"
              minDate={
                props?.row?.original?.scheduled_date
                  ? moment(props?.row?.original?.scheduled_date)
                  : moment(new Date())
              }
              inputFormat="DD/MM/YYYY"
              value={props?.row?.original?.etc_date}
              onChange={(e) => {
                props.row.original.etc_date = moment(e).utc().format();
                // props.row.original.etc_date = e;
                setProjrectDetail({ ...projrectDetail });
              }}
              renderInput={(params) => (
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  {...params}
                  style={{ transform: "scale(0.9)", transformOrigin: "left" }}
                />
              )}
            />
          </LocalizationProvider>
          {props.row.original.error_update_etc_date && (
            <p className="errorText">
              {props.row.original.error_update_etc_date}
            </p>
          )}
        </>
      ),
    },
  ];

  const columns = useMemo(() => COLUMNS, [curAct, checkedTask]);
  const data = useMemo(
    () =>
      projrectDetail.addedTask.filter((el) => {
        if (selectedAct != 0) {
          return el.activity_id == selectedAct;
        } else {
          return true;
        }
      }),
    [curAct, selectedAct, checkedTask, projrectDetail]
  );

  const tableInstance = useTable(
    {
      columns: columns,
      data: data,
      // data: projrectDetail.activity.find((el) => el.activity_id == selectedAct)
      //   ?.unassigned
      //   ? projrectDetail.activity.find((el) => el.activity_id == selectedAct)
      //       ?.unassigned
      //   : approveT.activity[0].unassigned,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    pageCount,
    pageOptions,
    setPageSize,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    prepareRow,
    state,
    setGlobalFilter,
    setFilter,
    setAllFilters,
    preFilteredRows,
    toggleSortBy,
  } = tableInstance;

  const { globalFilter, filters, pageIndex, pageSize } = state;

  useEffect(() => {
    getprojectDetail({ isUpdating: true });
  }, []);

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          {...getTableProps()}
          size="small"
          className="update-task-project"
          style={{ width: "100%" }}
        >
          <TableHead>
            {/* {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell style={{ textAlign: "center" }}>
                    {column.render("Header")}
                  </TableCell>
                ))}
              </TableRow>
            ))} */}

            <TableRow className="aprrove-task">
              <TableCell style={{ textAlign: "center" }}>
                <Checkbox
                  size="small"
                  checked={
                    selectedAct != 0
                      ? projrectDetail?.addedTask.length > 0 &&
                        projrectDetail?.addedTask?.filter(
                          (ts) => ts.activity_id == selectedAct
                        ).length ==
                          checkedTask.filter(
                            (ts) => ts.activity_id == selectedAct
                          ).length
                      : projrectDetail?.addedTask.length > 0 &&
                        projrectDetail?.addedTask?.length == checkedTask.length
                  }
                  color="primary"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  onClick={(e) => {
                    handleCheckAll(e.target.checked);
                  }}
                />
              </TableCell>
              <TableCell>S No.</TableCell>
              <TableCell>Task Name</TableCell>
              <TableCell>UOM</TableCell>
              <TableCell>Total Work</TableCell>
              <TableCell>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label="Scheduled Date"
                    minDate={moment(new Date(projrectDetail.start_date))}
                    inputFormat="DD/MM/YYYY"
                    value={selectedAllDate.scheduled_date}
                    onChange={(e) => {
                      setSelectedAllDate({
                        ...selectedAllDate,
                        scheduled_date: moment(e).utc().format(),
                      });

                      if (Number(selectedAct) === 0) {
                        data.map((el) => {
                          el.scheduled_date = moment(e).utc().format();
                          return el;
                        });
                      } else {
                        data
                          .filter(
                            (el) =>
                              Number(el.activity_id) === Number(selectedAct)
                          )
                          .map((el) => {
                            el.scheduled_date = moment(e).utc().format();
                            return el;
                          });
                      }

                      setProjrectDetail({ ...projrectDetail });
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        variant="outlined"
                        fullWidth
                        {...params}
                        style={{
                          transform: "scale(0.9)",
                          transformOrigin: "left",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </TableCell>
              <TableCell>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label="ETC Date"
                    minDate={moment(new Date())}
                    inputFormat="DD/MM/YYYY"
                    value={selectedAllDate.etc_date}
                    onChange={(e) => {
                      setSelectedAllDate({
                        ...selectedAllDate,
                        etc_date: moment(e).utc().format(),
                      });

                      if (Number(selectedAct) === 0) {
                        data.map((el) => {
                          el.etc_date = moment(e).utc().format();
                          return el;
                        });
                      } else {
                        data
                          .filter(
                            (el) =>
                              Number(el.activity_id) === Number(selectedAct)
                          )
                          .map((el) => {
                            el.etc_date = moment(e).utc().format();
                            return el;
                          });
                      }

                      setProjrectDetail({ ...projrectDetail });
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        variant="outlined"
                        {...params}
                        fullWidth
                        style={{
                          transform: "scale(0.9)",
                          transformOrigin: "left",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()} className="aprrove-task">
                  {row.cells.map((cell) => {
                    return (
                      <TableCell
                        style={{ textAlign: "left", maxWidth: "150px" }}
                      >
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}

            <TableRow>
              {rows.length > 0 ? (
                <>
                  <TableCell
                    colSpan={14}
                    style={{ padding: "20px", textAlign: "center" }}
                    align="center"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ minWidth: "200px" }}
                      onClick={() => handleSubmit()}
                    >
                      Update
                    </Button>
                  </TableCell>
                </>
              ) : (
                <TableCell
                  colSpan={14}
                  style={{ padding: "20px", textAlign: "center" }}
                  align="center"
                >
                  No Data Available
                </TableCell>
              )}
            </TableRow>
          </TableBody>
        </Table>
        {rows.length > 0 && (
          <Pagination
            page={page}
            nextPage={nextPage}
            previousPage={previousPage}
            pageCount={pageCount}
            setPageSize={setPageSize}
            pageSize={pageSize}
            pageOptions={pageOptions}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            rows={rows}
            pageIndex={pageIndex}
          />
        )}
      </TableContainer>
      <Snackbar
        open={snack.open}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        autoHideDuration={3000}
        onClose={() => {
          setSnack({
            ...snack,
            open: false,
            message: "",
          });
        }}
        message={snack.message}
      />
    </>
  );
}
