import React, { useState, useEffect, useMemo } from "react";
import { Box, Grid, Paper } from "@material-ui/core";
import Button from "@mui/material/Button";
import { Divider, Toolbar, Tooltip } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@mui/material/Checkbox";
import { TextField, Snackbar } from "@mui/material";

import {
  DangerBtn,
  SaccessBtn,
  PrimaryBtn,
  NeutralBtn,
} from "../../Pages/styles";
import ButtonGroup from "@mui/material/ButtonGroup";
import "../../Pages/dashboard/Dashboard.scss";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import Pagination from "./Pagination";
import {
  assignTask as assignTaskApi,
  singleAssignTask,
  getProjectById,
} from "../../redux/api/index";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Activetask({
  projrectDetail,
  setProjrectDetail,
  selectedAct,
  setSelectedAct,
  curAct,
  getprojectDetail,
}) {
  const param = useParams();
  const [checkedTask, setcheckedTask] = useState([]);
  const [snack, setSnack] = useState({ open: false, message: "" });
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedAllDate, setSelectedAllDate] = useState({
    from_date: null,
    to_date: null,
  });

  const approveT = useSelector((state) => state.project.currentProject);
  const handleCheck = (val) => {
    const isAdded = checkedTask.find((el) => el.id == val.id);
    if (isAdded) {
      const filtCk = checkedTask.filter((el) => el.id != val.id);
      setcheckedTask(filtCk);
      isAdded.error_assign_from_date = "";
      isAdded.error_assign_to_date = "";
      isAdded.error_assign_today_goal = "";
      setProjrectDetail({ ...projrectDetail });
    } else {
      setcheckedTask([...checkedTask, val]);
    }
  };

  const handleCheckAll = (val) => {
    if (val) {
      if (selectedAct == 0) {
        const alllTask = projrectDetail?.addedTask?.filter((el) => true);
        setcheckedTask(alllTask);
      } else {
        const alllTask = projrectDetail?.addedTask?.filter(
          (el) => el.activity_id == selectedAct
        );
        const withoutCurAct = checkedTask.filter(
          (el) => el.activity_id != selectedAct
        );
        setcheckedTask([...withoutCurAct, ...alllTask]);
      }
    } else {
      if (selectedAct == 0) {
        setcheckedTask([]);
      } else {
        const filteredCheckedtask = checkedTask.filter(
          (el) => el.activity_id != selectedAct
        );
        setcheckedTask([...filteredCheckedtask]);
      }
    }
  };
  let errArr = [];
  const handleSubmitAll = async () => {
    const outData = checkedTask.map((el) => {
      let val = {};
      val.id = el.id;
      val.task_id = el.task_id;
      val.activity_id = el.activity_id;
      val.project_id = el.project_id;
      val.task_assign_from_date =
        el.task_assign_from_date == "Invalid date"
          ? null
          : el.task_assign_from_date;
      val.task_assign_to_date =
        el.task_assign_to_date == "Invalid date"
          ? null
          : el.task_assign_to_date;
      val.assigned_goal = el.today_goal;
      val.remark = el.admin_remark;

      if (!el.today_goal) {
        el.error_assign_today_goal = "Please enter Today goal";
        errArr.push(el);
      } else {
        el.error_assign_today_goal = "";
      }
      if (
        !el.task_assign_from_date ||
        el.task_assign_from_date == "Invalid date"
      ) {
        el.error_assign_from_date = "Please select scheduled date";
        errArr.push(el);
      } else {
        el.error_assign_from_date = "";
      }
      if (el.task_assign_to_date && el.task_assign_to_date == "Invalid date") {
        el.error_assign_to_date = "Please select ETC date";
        errArr.push(el);
      } else {
        el.error_assign_to_date = "";
      }

      return val;
    });

    setProjrectDetail({ ...projrectDetail });

    if (errArr.length > 0) {
      setSnack({
        ...snack,
        open: true,
        message: "Please fix errors to submit",
      });
      return;
    }
    if (outData.length == 0) {
      setSnack({
        ...snack,
        open: true,
        message: "Please check task for assignment",
      });
      return;
    }

    try {
      await assignTaskApi(param.projectid, outData);
      setSnack({
        ...snack,
        open: true,
        message: "Task assigned successfully",
      });
      getprojectDetail({ isUpdating: true });
    } catch (error) {
      console.log("assign task error");
    }
  };

  const handleAssignOne = async (data) => {
    let val = {};
    val.id = data.id;
    val.task_id = data.task_id;
    val.activity_id = data.activity_id;
    val.project_id = data.project_id;
    val.task_assign_from_date =
      data.task_assign_from_date == "Invalid date"
        ? null
        : data.task_assign_from_date;
    val.task_assign_to_date =
      data.task_assign_to_date == "Invalid date"
        ? null
        : data.task_assign_to_date;
    val.assigned_goal = data.today_goal;
    val.remark = data.admin_remark;

    if (!data.today_goal) {
      data.error_assign_today_goal = "Please enter Today goal";
      // errArr.push(data);
    } else {
      data.error_assign_today_goal = "";
    }
    if (
      !data.task_assign_from_date ||
      data.task_assign_from_date == "Invalid date"
    ) {
      data.error_assign_from_date = "Please select scheduled date";
      // errArr.push(data);
    } else {
      data.error_assign_from_date = "";
    }
    if (
      data.task_assign_to_date &&
      data.task_assign_to_date == "Invalid date"
    ) {
      data.error_assign_to_date = "Please select To date";
      // errArr.push(data);
    } else {
      data.error_assign_to_date = "";
    }

    setProjrectDetail({ ...projrectDetail });
    if (
      data.error_assign_today_goal ||
      data.error_assign_to_date ||
      data.error_assign_from_date
    ) {
      setSnack({
        ...snack,
        open: true,
        message: "Please fix errors to submit",
      });
      return;
    }

    try {
      await singleAssignTask(param.projectid, val);
      setSnack({
        ...snack,
        open: true,
        message: "Task assigned successfully",
      });
      getprojectDetail({ isUpdating: true });
    } catch (error) {
      setSnack({
        ...snack,
        open: true,
        message: "Something went wrong, please try again later",
      });
      console.log("assign task error");
    }
  };

  const COLUMNS = [
    {
      Header: (props) => (
        <Checkbox
          size="small"
          checked={
            selectedAct != 0
              ? projrectDetail?.addedTask.length > 0 &&
                projrectDetail?.addedTask?.filter(
                  (ts) => ts.activity_id == selectedAct
                ).length ==
                  checkedTask.filter((ts) => ts.activity_id == selectedAct)
                    .length
              : projrectDetail?.addedTask.length > 0 &&
                projrectDetail?.addedTask?.length == checkedTask.length
          }
          color="primary"
          inputProps={{ "aria-label": "secondary checkbox" }}
          onClick={(e) => {
            handleCheckAll(e.target.checked);
          }}
        />
      ),
      accessor: "id",
      Cell: (props) => (
        <Checkbox
          size="small"
          color="primary"
          inputProps={{ "aria-label": "secondary checkbox" }}
          checked={
            checkedTask.find(
              (el) =>
                el.id == props?.row?.original.id &&
                el.activity_id == props?.row.original?.activity_id
            )
              ? true
              : false
          }
          onChange={() => {
            handleCheck(props.row.original);
          }}
        />
      ),
    },
    {
      Header: "S No.",
      Cell: (props) => <>{props.row.index + 1}</>,
    },
    {
      Header: "Task Name",
      accessor: "taskDetail.name",
    },
    {
      Header: "UOM",
      accessor: "taskDetail.uom",
    },
    {
      Header: "From Date",
      accessor: "task_assign_from_date",
      Cell: (props) => (
        <>
          {props.row.original.isAssignmentComplete == true ? (
            "-"
          ) : (
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="From Date"
                minDate={moment(new Date(projrectDetail.start_date))}
                maxDate={
                  props?.row?.original?.task_assign_to_date
                    ? moment(props?.row?.original?.task_assign_to_date)
                    : moment(new Date("2100-01-01"))
                }
                inputFormat="DD/MM/YYYY"
                value={props.row.original.task_assign_from_date}
                onChange={(e) => {
                  props.row.original.task_assign_from_date = moment(e)
                    .utc()
                    .format();
                  // props.row.original.etc_date = e;
                  setProjrectDetail({ ...projrectDetail });
                }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    variant="outlined"
                    {...params}
                    style={{ transform: "scale(0.9)", minWidth: "130px" }}
                  />
                )}
              />
            </LocalizationProvider>
          )}
          {props.row.original.error_assign_from_date && (
            <p className="errorText">
              {props.row.original.error_assign_from_date}
            </p>
          )}
        </>
      ),
    },
    {
      Header: "To Date",
      accessor: "task_assign_to_date",
      Cell: (props) => (
        <>
          {props.row.original.isAssignmentComplete == true ? (
            "-"
          ) : (
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="To Date"
                minDate={
                  props?.row.original?.task_assign_from_date
                    ? moment(props?.row.original?.task_assign_from_date)
                    : moment(new Date(projrectDetail.start_date))
                }
                inputFormat="DD/MM/YYYY"
                value={props.row.original.task_assign_to_date}
                onChange={(e) => {
                  props.row.original.task_assign_to_date = moment(e)
                    .utc()
                    .format();
                  // props.row.original.etc_date = e;
                  setProjrectDetail({ ...projrectDetail });
                }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    variant="outlined"
                    {...params}
                    style={{ transform: "scale(0.9)", minWidth: "100px" }}
                  />
                )}
              />
            </LocalizationProvider>
          )}
          {props.row.original.error_assign_to_date && (
            <p className="errorText">
              {props.row.original.error_assign_to_date}
            </p>
          )}
        </>
      ),
    },
    {
      Header: "ETC Date",
      accessor: "etc_date",
      Cell: (props) => (
        <>{props.value ? moment(props.value).format("DD/MM/YYYY") : "-"}</>
      ),
    },

    {
      Header: "Total/Completed",
      accessor: "total_completed",
      Cell: (props) => (
        <>
          {props.row.original.total_work_allocated} /
          {Number(props?.row?.original?.total_completed)}
          {/* Completed: {props?.row?.original?.total_completed}
          Assigned: {props?.row?.original?.assigned_total}/ */}
        </>
      ),
    },
    {
      Header: "Balance",
      accessor: "completed_date",
      Cell: (props) => (
        <>
          {props.row.original.isAssignmentComplete == true
            ? "-"
            : Number(props.row.original.total_work_allocated) -
              Number(props.row.original.assigned_total)}
        </>
      ),
    },
    {
      Header: "Today Work",
      accessor: "today_goal",
      Cell: (props) => (
        <>
          {props.row.original.isAssignmentComplete == true ? (
            "-"
          ) : (
            <TextField
              fullWidth
              size="small"
              placeholder="Today Work"
              value={props.row.original.today_goal}
              name="Today Work"
              onChange={(e) => {
                // props.row.original.today_goal
                const val = e.target.value.replace(
                  /[a-zA-[\]\\Z$&/+,:;=?@#|'<>.^*()%!_-]/gi,
                  ""
                );

                if (
                  Number(val) <=
                  Number(props.row.original.total_work_allocated) -
                    Number(props.row.original.assigned_total)
                ) {
                  props.row.original.today_goal = val;
                } else {
                  setSnack({
                    ...snack,
                    open: true,
                    message: "Work cannot be more than balance assignment",
                  });
                  props.row.original.today_goal =
                    Number(props.row.original.total_work_allocated) -
                    Number(props.row.original.assigned_total);
                }

                setProjrectDetail({ ...projrectDetail });
              }}
              style={{ transform: "scale(0.9)", minWidth: "100px" }}
            />
          )}
          {props.row.original.error_assign_today_goal && (
            <p className="errorText">
              {props.row.original.error_assign_today_goal}
            </p>
          )}
        </>
      ),
    },
    {
      Header: "Remark",
      accessor: "remark",
      Cell: (props) => (
        <>
          {props.row.original.isAssignmentComplete == true ? (
            "-"
          ) : (
            <TextField
              fullWidth
              size="small"
              placeholder="remark"
              value={props.row.original.admin_remark}
              name="remark"
              onChange={(e) => {
                props.row.original.admin_remark = e.target.value;
                setProjrectDetail({ ...projrectDetail });
              }}
              style={{ transform: "scale(0.9)", minWidth: "100px" }}
            />
          )}
        </>
      ),
    },
    {
      Header: "",
      accessor: "isAssigned",
      Cell: (props) => (
        <>
          {props.row.original.isAssignmentComplete == true ? (
            <Button
              variant="contained"
              color="success"
              size="small"
              style={{ minWidth: "100px" }}
            >
              Assigned
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => handleAssignOne(props.row.original)}
              style={{ minWidth: "100px" }}
            >
              Assign
            </Button>
          )}
        </>
      ),
    },
  ];

  const columns = useMemo(() => COLUMNS, [curAct, checkedTask]);
  const data = useMemo(
    () =>
      projrectDetail.addedTask.filter((el) => {
        if (selectedAct != 0) {
          return el.activity_id == selectedAct;
        } else {
          return true;
        }
      }),
    [curAct, selectedAct, checkedTask, projrectDetail]
  );
  const tableInstance = useTable(
    {
      columns: columns,
      data: data,
      // data: projrectDetail.activity.find((el) => el.activity_id == selectedAct)
      //   ?.unassigned
      //   ? projrectDetail.activity.find((el) => el.activity_id == selectedAct)
      //       ?.unassigned
      //   : approveT.activity[0].unassigned,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    pageCount,
    pageOptions,
    setPageSize,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    prepareRow,
    state,
    setGlobalFilter,
    setFilter,
    setAllFilters,
    preFilteredRows,
    toggleSortBy,
  } = tableInstance;

  const { globalFilter, filters, pageIndex, pageSize } = state;

  useEffect(() => {
    getprojectDetail({ isUpdating: true });
  }, []);

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          {...getTableProps()}
          size="small"
          style={{ width: "100%" }}
          className="assign-task-project"
        >
          <TableHead>
            {/* {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell style={{ textAlign: "center" }}>
                    {column.render("Header")}
                  </TableCell>
                ))}
              </TableRow>
            ))} */}

            <TableRow className="aprrove-task">
              <TableCell style={{ textAlign: "center" }}>
                <Checkbox
                  size="small"
                  checked={
                    selectedAct != 0
                      ? projrectDetail?.addedTask.length > 0 &&
                        projrectDetail?.addedTask?.filter(
                          (ts) => ts.activity_id == selectedAct
                        ).length ==
                          checkedTask.filter(
                            (ts) => ts.activity_id == selectedAct
                          ).length
                      : projrectDetail?.addedTask.length > 0 &&
                        projrectDetail?.addedTask?.length == checkedTask.length
                  }
                  color="primary"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  onClick={(e) => {
                    handleCheckAll(e.target.checked);
                  }}
                />
              </TableCell>
              <TableCell style={{ minWidth: "50px" }}>S No.</TableCell>
              <TableCell style={{ minWidth: "150px" }}>Task Name</TableCell>
              <TableCell>UOM</TableCell>
              <TableCell>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label="From Date"
                    minDate={moment(new Date(projrectDetail.start_date))}
                    inputFormat="DD/MM/YYYY"
                    value={selectedAllDate.from_date}
                    onChange={(e) => {
                      setSelectedAllDate({
                        ...selectedAllDate,
                        from_date: moment(e).utc().format(),
                      });

                      if (Number(selectedAct) === 0) {
                        data.map((el) => {
                          el.task_assign_from_date = moment(e).utc().format();
                          return el;
                        });
                      } else {
                        data
                          .filter(
                            (el) =>
                              Number(el.activity_id) === Number(selectedAct)
                          )
                          .map((el) => {
                            el.task_assign_from_date = moment(e).utc().format();
                            return el;
                          });
                      }

                      setProjrectDetail({ ...projrectDetail });
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        variant="outlined"
                        {...params}
                        style={{
                          transform: "scale(0.9)",
                          fontSize: "1.4rem",
                          minWidth: "130px",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </TableCell>
              <TableCell>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label="To Date"
                    minDate={moment(new Date())}
                    inputFormat="DD/MM/YYYY"
                    value={selectedAllDate.to_date}
                    onChange={(e) => {
                      setSelectedAllDate({
                        ...selectedAllDate,
                        to_date: moment(e).utc().format(),
                      });

                      if (Number(selectedAct) === 0) {
                        data.map((el) => {
                          el.scheduled_date = moment(e).utc().format();
                          return el;
                        });
                      } else {
                        data
                          .filter(
                            (el) =>
                              Number(el.activity_id) === Number(selectedAct)
                          )
                          .map((el) => {
                            el.scheduled_date = moment(e).utc().format();
                            return el;
                          });
                      }

                      setProjrectDetail({ ...projrectDetail });
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        variant="outlined"
                        {...params}
                        style={{ transform: "scale(0.9)", minWidth: "130px" }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </TableCell>
              <TableCell>ETC Date</TableCell>
              <TableCell>Total/Completed</TableCell>
              <TableCell>Balance</TableCell>
              <TableCell>Today Work</TableCell>
              <TableCell>Remark</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()} className="aprrove-task">
                  {row.cells.map((cell) => {
                    return (
                      <TableCell style={{ minWidth: "25%", textAlign: "left" }}>
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}

            <TableRow className="aprrove-task">
              {rows.length > 0 ? (
                <>
                  <TableCell
                    colSpan={14}
                    style={{ padding: "20px", textAlign: "center" }}
                    align="center"
                  >
                    <Button
                      variant="contained"
                      style={{ minWidth: "200px" }}
                      onClick={() => handleSubmitAll()}
                    >
                      Assign
                    </Button>
                  </TableCell>
                </>
              ) : (
                <TableCell
                  colSpan={14}
                  style={{ padding: "20px" }}
                  align="center"
                >
                  No Data Available
                </TableCell>
              )}
            </TableRow>
          </TableBody>
        </Table>
        {rows.length > 0 && (
          <Pagination
            page={page}
            nextPage={nextPage}
            previousPage={previousPage}
            pageCount={pageCount}
            setPageSize={setPageSize}
            pageSize={pageSize}
            pageOptions={pageOptions}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            rows={rows}
            pageIndex={pageIndex}
          />
        )}
      </TableContainer>
      <Snackbar
        open={snack.open}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        autoHideDuration={3000}
        onClose={() => {
          setSnack({
            ...snack,
            open: false,
            message: "",
          });
        }}
        message={snack.message}
      />
    </>
  );
}
