import React, { useState, useEffect, useMemo } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Header from "../../Component/Header/Header";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import PageTitle from "../../Component/PageTitle/PageTitle";
import {
  Paper,
  Grid,
  IconButton,
  Button,
  Box,
  Tooltip,
  Toolbar,
  OutlinedInput,
  InputAdornment,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import AddCommentIcon from "@material-ui/icons/AddComment";
import Addtask from "../AddTask/Addtask";
import { Link, NavLink, useNavigate } from "react-router-dom";
import projectList from "../../data/projectData.json";
import { useDispatch, useSelector } from "react-redux";
import { GET_PROJECT_LIST } from "../../redux/constants";
import { projectListAction } from "../../redux/action/projectAction";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import generateExcel from "zipcelx";

import moment from "moment";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import Pagination from "./Pagination";
import { useStyles, btnStyles } from "../TableStyle";
import Themes from "../../themes";

const tableStyles = makeStyles((theme) => ({
  tbcell: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgb(198 202 229)",
      "box-shadow":
        "0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 8px 1px rgba(0,0,0,0.14),0px 4px 10px 3px rgba(0,0,0,0.12)",
    },
  },
  TableCell: {
    "&:hover": {
      color: "rgb(57, 73, 171)",
    },
  },
}));

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

export default function Projectmanagement({ setSearchNow, projSearch }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const cbtn = btnStyles();
  const btnclasses = btnStyles();
  // const tdclasses = tableStyles();
  const [toggleOpen, setToggleOpen] = useState(1);
  const toggleTabs = (index) => {
    setToggleOpen(index);
  };

  const [searchInp, setSearchInp] = useState("");
  const [showBackdrop, setShowBackdrop] = useState(false);

  const dispatch = useDispatch();
  const projects = useSelector((state) => state.project.allProject);

  const COLUMNS = [
    {
      Header: "S No.",
      accessor: "serial_no",
      Cell: (props) => <>{Number(props.row.id) + Number(1)}</>,
    },
    {
      Header: "Project Id",
      accessor: "project_id",
    },
    {
      Header: "Project Name",
      accessor: "project_name",
    },
    {
      Header: "Client Name",
      accessor: "client_name",
    },
    {
      Header: "Location",
      accessor: "address",
      Cell: (props) => <>{props.value}</>,
    },
    {
      Header: "Start Date",
      accessor: "start_date",
      Cell: (props) => (
        <>{props.value ? moment(props.value).format("DD/MM/YYYY") : "-"}</>
      ),
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Progress",

      Cell: (props) => (
        <BorderLinearProgress
          variant="determinate"
          color="primary"
          value={
            props.row.original.status != "NOT_STARTED"
              ? (Number(props.row.original.completed_total) * 100) /
                Number(props.row.original.total_work)
              : 0
          }
        />
      ),
    },
  ];

  const columns = useMemo(() => COLUMNS, []);
  const tableData = useMemo(() => projects, [projects]);

  const tableInstance = useTable(
    {
      columns: columns,
      data: tableData,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    pageCount,
    pageOptions,
    setPageSize,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    prepareRow,
    state,
    setGlobalFilter,
    setFilter,
    setAllFilters,
    preFilteredRows,
    toggleSortBy,
  } = tableInstance;

  const { globalFilter, filters, pageIndex, pageSize } = state;

  const handleNavigate = (id) => {
    navigate(`${id}`);
  };

  const handleLoad = () => {
    // setGlobalFilter("project one")
  };

  function getHeader(column) {
    if (column.totalHeaderCount === 1) {
      return [
        {
          value: column.Header,
          type: "string",
        },
      ];
    } else {
      const span = [...Array(column.totalVisibleHeaderCount - 1)].map((x) => ({
        value: "",
        type: "string",
      }));
      return [
        {
          value: column.Header,
          type: "string",
        },
        ...span,
      ];
    }
  }

  function getExcel() {
    const config = {
      filename: "all project",
      sheet: {
        data: [],
      },
    };

    let dataSet = config.sheet.data;

    // review with one level nested config
    // HEADERS
    headerGroups.forEach((headerGroup) => {
      const headerRow = [];
      if (headerGroup.headers) {
        headerGroup.headers.forEach((column) => {
          headerRow.push(...getHeader(column));
        });
      }

      dataSet.push(headerRow);
    });

    tableData.map((el, ind) => {
      let val = [
        {
          value: ind + 1,
          type: "string",
        },
        {
          value: el.project_id,
          type: "string",
        },
        {
          value: el.project_name,
          type: "string",
        },
        {
          value: el.client_name,
          type: "string",
        },
        {
          value: el.address,
          type: "string",
        },
        {
          value: el.start_date,
          type: "string",
        },
        {
          value: el.status,
          type: "string",
        },
        {
          value: el.total_work,
          type: "string",
        },
      ];

      dataSet.push(val);
    });

    return generateExcel(config);
  }

  useEffect(() => {
    dispatch(projectListAction());
    handleLoad();
    // dispatch({ type: GET_PROJECT_LIST, payload: projectList });
  }, []);

  return (
    <ThemeProvider theme={Themes.default}>
      <Paper elevation={4}>
        {/* <button onClick={getExcel}>get excel</button> */}
        <Grid container>
          <Grid
            item
            xs={12}
            className={classes.boxes}
            style={{ overflow: "auto" }}
          >
            <Table {...getTableProps()} style={{ minWidth: "1200px" }}>
              <TableHead>
                {headerGroups.map((headerGroup) => (
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <TableCell
                        className={classes.tableHeaderCell}
                        style={{
                          background: "#f5f3f3",
                          display: "table-cell",
                          maxWidth: "150px",
                          fontSize: "13px",
                        }}
                      >
                        {column.render("Header")}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableRow>
                <TableCell
                  colSpan={8}
                  style={{ padding: "20px" }}
                  align="center"
                >
                  <OutlinedInput
                    fullWidth
                    variant="outlined"
                    placeholder="Search Project"
                    size="small"
                    value={searchInp}
                    style={{
                      background: "#426efd12",
                    }}
                    onChange={(e) => {
                      setSearchInp(e.target.value);
                      // setGlobalFilter(e.target.value)
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setGlobalFilter(searchInp);
                          }}
                        >
                          <SearchIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setGlobalFilter("");
                            setSearchInp("");
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </TableCell>
              </TableRow>
              <TableBody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <TableRow
                      {...row.getRowProps()}
                      onClick={() => handleNavigate(row.original.id)}
                      className="tbcell"
                    >
                      {row.cells.map((cell) => {
                        return (
                          <TableCell style={{ display: "table-cell" }}>
                            {cell.render("Cell")}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>

            <Pagination
              page={page}
              nextPage={nextPage}
              previousPage={previousPage}
              pageCount={pageCount}
              setPageSize={setPageSize}
              pageSize={pageSize}
              pageOptions={pageOptions}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              rows={rows}
              pageIndex={pageIndex}
            />
            <Backdrop
              style={{ zIndex: 9, opacity: "35%" }}
              open={showBackdrop}
              // onClick={()}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Grid>
        </Grid>
      </Paper>
    </ThemeProvider>
  );
}
