import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Header from "../../Component/Header/Header";
import PageTitle from "../../Component/PageTitle/PageTitle";
import { Button, Grid, Paper } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import generateExcel from "zipcelx";
import {
  DangerBtn,
  SaccessBtn,
  PrimaryBtn,
  NeutralBtn,
} from "../../Pages/styles";
import { useStyles, btnStyles } from "../TableStyle";
import { NavLink } from "react-router-dom";
import Link from "@mui/material/Link";
import Footer from "../Footer/Footer";
import Subtitle from "../../Component/PageTitle/Subtitle";
import { Input } from "@material-ui/core";
import "../dashboard/Dashboard.scss";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { TextField, Backdrop, CircularProgress } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import IosShareSharpIcon from "@mui/icons-material/IosShareSharp";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "../dashboard/Dashboard.scss";
import Divider from "@mui/material/Divider";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  reportProjectSummary,
  reportProjectSummaryAggregated,
} from "../../redux/api/index";

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#ffffff",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
  },
});

const number = [...new Array(20)];
function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 2,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 2,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

export default function Reports() {
  const classes = useStyles();
  const params = useParams();

  const [progress, setProgress] = React.useState(0);
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [selectedDate, setSelectedDate] = useState(null);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [selectedAct, setSelectedAct] = useState("");
  const [activityList, setActivityList] = useState([]);
  const [taskList, setTaskList] = useState([]);

  const handleChange = async (event) => {
    setSelectedAct(event.target.value);
    try {
      setShowBackdrop(true);
      const { data } = await reportProjectSummaryAggregated(
        event.target.value,
        selectedDate,
        params.projectId
      );
      setTaskList(data.data);
      setShowBackdrop(false);
    } catch (error) {
      setShowBackdrop(false);
      console.log("error filter by activity");
    }
  };
  const handleDateChange = async (val) => {
    setSelectedDate(val);
    try {
      setShowBackdrop(true);
      const { data } = await reportProjectSummaryAggregated(
        selectedAct,
        val,
        params.projectId
      );
      setTaskList(data.data);
      setShowBackdrop(false);
    } catch (error) {
      setShowBackdrop(false);
      console.log("error filter by date");
    }
  };

  const fetchSummary = async () => {
    try {
      setShowBackdrop(true);
      const { data } = await reportProjectSummaryAggregated(
        "",
        "",
        params.projectId
      );
      setActivityList(data.activity);
      setTaskList(data.data);
      setShowBackdrop(false);
    } catch (error) {
      setShowBackdrop(false);
      console.log("fetch summary error");
    }
  };

  const exportExcel = () => {
    const config = {
      filename: `Summary Report ${moment().format("DD MMM HHmm")}`,
      sheet: {
        data: [],
      },
    };

    let dataSet = config.sheet.data;

    const headerRow = [
      {
        value: "Date",
        type: "string",
      },
      {
        value: "Activity",
        type: "string",
      },
      {
        value: "Task",
        type: "string",
      },
      {
        value: "Unit",
        type: "string",
      },
      {
        value: "Total Work",
        type: "string",
      },
      {
        value: "Completed Work",
        type: "string",
      },
      {
        value: "Daily Goal",
        type: "string",
      },
      {
        value: "Daily Work",
        type: "string",
      },
      {
        value: "Status",
        type: "string",
      },
      {
        value: "User Remark",
        type: "string",
      },
      {
        value: "Admin Remark",
        type: "string",
      },
    ];

    dataSet.push(headerRow);

    const dataRow = [];
    taskList.forEach((tsk) => {
      let valRow = [];
      valRow.push(
        {
          value: tsk.from_date ? moment(tsk.from_date).format("DD MMM") : "-",
          type: "string",
        },
        {
          value: tsk?.activityDetail?.name ? tsk?.activityDetail?.name : "-",
          type: "string",
        },
        {
          value: tsk?.taskDetail?.name ? tsk?.taskDetail?.name : "-",
          type: "string",
        },
        {
          value: tsk?.taskDetail?.uom ? tsk?.taskDetail?.uom : "-",
          type: "string",
        },
        {
          value: tsk?.projectTask?.total_work_allocated
            ? tsk?.projectTask?.total_work_allocated
            : "-",
          // type:tsk?.projectTask?.total_work_allocated?"number":"string"
          type: "string",
        },
        {
          value: tsk?.projectTask?.total_completed
            ? tsk?.projectTask?.total_completed
            : "-",
          // type:tsk?.projectTask?.total_completed?"number":"string"
          type: "string",
        },
        {
          value: tsk?.assigned_goal ? tsk?.assigned_goal : "-",
          // type:tsk?.assigned_goal?"number":"string"?
          type: "string",
        },
        {
          value: tsk?.work_done ? tsk?.work_done : "-",
          // type:tsk?.work_done?"number":"string"
          type: "string",
        },
        {
          value: tsk?.work_status ? tsk?.work_status : "-",
          // type:tsk?.work_status?"number":"string"
          type: "string",
        },
        {
          value: tsk?.supervisor_remark ? tsk?.supervisor_remark : "-",
          type: "string",
        },
        {
          value: tsk?.admin_remark ? tsk?.admin_remark : "-",
          type: "string",
        }
      );

      dataSet.push(valRow);
    });

    return generateExcel(config);
  };

  useEffect(() => {
    fetchSummary();
  }, []);

  return (
    <Box component="div" className="tabs-reports">
      <Grid container>
        <Grid item xs={6}>
          <Box style={{ width: "200px" }} className="tabs-reports_lft">
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">
                Select Activity
              </InputLabel>
              <Select
                label="Select Activity"
                value={selectedAct}
                onChange={handleChange}
                style={{ transform: "scale(0.9)", transformOrigin: "left" }}
              >
                <MenuItem value=""> All </MenuItem>
                {activityList.map((act, ind) => (
                  <MenuItem value={act.activity_id}>
                    {act?.detail?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            alignItems="center"
            justifyContent="end"
            display="flex"
            className="tabs-reports_rft"
          >
            {/* <Button onClick={exportExcel}>
              <IosShareSharpIcon fontSize="small" sx={{ mr: 1 }} /> Export List
            </Button> */}
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                inputFormat="DD/MM/YYYY"
                value={selectedDate}
                onChange={(newValue) => {
                  handleDateChange(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    style={{
                      transform: "scale(0.9)",
                      transformOrigin: "right",
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
        </Grid>

        <Grid item xs={12} className={classes.boxes}>
          <TableContainer style={{ maxHeight: "450px" }}>
            <Table
              className="table-dashboard table-borderless report-table"
              stickyHeader
              aria-label="sticky table"
            >
              <TableHead style={{ position: "sticky", zIndex: 9 }}>
                <TableRow>
                  <TableCell className={classes.tableHeaderCell} align="left">
                    Task
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align="center">
                    Start Date
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align="center">
                    ETC Date{" "}
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align="center">
                    Today
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align="center">
                    Today's Goal
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align="center">
                    Total/Completed{" "}
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align="center">
                    Percentage %{" "}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {taskList.map((tsk, index) => (
                  <TableRow className="report-table-hover">
                    <TableCell style={{ "text-align": "left" }}>
                      {tsk?.taskDetail?.name}
                    </TableCell>

                    <TableCell align="center">
                      {tsk?.from_date
                        ? moment(tsk?.from_date).format("DD/MM/YYYY")
                        : "-"}{" "}
                    </TableCell>
                    <TableCell align="center">
                      {" "}
                      {tsk?.projectTask?.etc_date
                        ? moment(tsk?.projectTask?.etc_date).format(
                            "DD/MM/YYYY"
                          )
                        : "-"}{" "}
                    </TableCell>
                    <TableCell align="center">{tsk?.work_done} </TableCell>
                    <TableCell align="center"> {tsk?.assigned_goal} </TableCell>
                    <TableCell className="sizeSmall _total_completed">
                      <span
                        style={{
                          background: "#EDF7EE",
                          position: "relative",
                          overflow: "hidden",
                        }}
                      >
                        <span
                          className="not-completed"
                          style={{
                            width: `${Number(
                              (Number(tsk?.projectTask?.total_completed) *
                                100) /
                                Number(tsk?.projectTask?.total_work_allocated)
                            ).toFixed(0)}%`,
                            position: "absolute",
                            background: "#56c65b",
                            padding: "0",
                          }}
                        ></span>
                        <span>
                          {tsk?.projectTask?.total_work_allocated} /{" "}
                          {tsk?.projectTask?.total_completed}
                        </span>
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <Box style={{ width: "60px", fontWeight: "bold" }}>
                          {Number(
                            (Number(tsk?.projectTask?.total_completed) * 100) /
                              Number(tsk?.projectTask?.total_work_allocated)
                          ).toFixed(0)}
                          %
                        </Box>
                        <CircularProgress
                          variant="determinate"
                          style={{ color: "#56c65b" }}
                          value={
                            (Number(tsk?.projectTask?.total_completed) * 100) /
                            Number(tsk?.projectTask?.total_work_allocated)
                          }
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Backdrop style={{ zIndex: 9, opacity: "35%" }} open={showBackdrop}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
}
