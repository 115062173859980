import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Header from "../../Component/Header/Header";
import PageTitle from "../../Component/PageTitle/PageTitle";
import { Box, Button, Grid, Paper } from "@material-ui/core";
import { TextField, Backdrop, CircularProgress, Snackbar } from "@mui/material";
import EditIcon from "@material-ui/icons/Edit";
import Projectdetailsleft from "../../Component/ProjectDetails/Projectdetailsleft";
import Projectdetailsright from "../../Component/ProjectDetails/Projectdetailsright";
import ApproveTask from "./ApproveTask";
import UpdateTask from "./UpdateTask";
import { DangerBtn, SaccessBtn, PrimaryBtn } from "../../Pages/styles";
import useStyles from "./styles";
import Activetask from "./Activetask";
import Addtask from "./Addtask";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import styled from "styled-components";
import Modal from "../../Component/Modal/Modal";
import { useParams } from "react-router-dom";
import { getProjectById } from "../../redux/api";
import { prj2 } from "../../data/projectTaskData";
import { IconButton } from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import CloseIcon from "@mui/icons-material/Close";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { NavLink } from "react-router-dom";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "../dashboard/Dashboard.scss";

import LinearProgress from "@mui/material/LinearProgress";
import { updateProject as updateProjectApi } from "../../redux/api/index";
import { useSelector, useDispatch } from "react-redux";
import { getphoneState, getphoneCity } from "../../redux/action/phoneAction";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { OutlinedInput } from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import Tooltip from "@mui/material/Tooltip";

const themes = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#1976d2",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: "#d32f2f",
    },
  },
});

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function Projectdetails() {
  const classes = useStyles();
  const params = useParams();
  const dispatch = useDispatch();
  const phoneState = useSelector((state) => state.phone.state);
  const phoneCity = useSelector((state) => state.phone.city);
  const [toggleOpen, setToggleOpen] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [modalData, setModalData] = useState({
    for: "",
    title: "",
    type: "",
    activityId: "",
    taskId: "",
  });

  const [selectedAct, setSelectedAct] = useState(1);
  const [addAct, setAddAct] = useState(0);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [snack, setSnack] = useState({ open: false, message: "" });
  const [showPass, setShowPass] = useState(false);
  const [projectInfo, setProjectInfo] = useState({
    id: "",
    project_id: "",
    project_name: "",
    start_date: null,
    etc_date: null,
    completed_date: null,
    status: "",
    latitude: "",
    longitude: "",
    client_name: "",
    client_email: "",
    client_phone: "",
    address: "",
    state: "",
    state_id: "",
    district: "",
    district_id: "",
    pincode: "",
    supervisor_name: "",
    supervisor_email: "",
    supervisor_phone: "",
    project_creator_name: "",
    project_password: "",
    isDraft: "",
    createdAt: "",
    updatedAt: "",
  });
  let [projrectDetail, setProjrectDetail] = useState({
    add: [
      {
        task: [],
      },
    ],
    activity: [
      {
        task: [],
      },
    ],
    notAddedTask: [],
    addedTask: [],
    approvalPending: [],
  });

  const toggleTabs = (index) => {
    setToggleOpen(index);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [age, setAge] = React.useState("");
  const [value, setValue] = React.useState(null);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const getprojectDetail = async (isUpdating) => {
    // setProjrectDetail(prj2);
    try {
      setShowBackdrop(true);
      setIsLoading(true);
      const { data } = await getProjectById(params.projectid);
      setProjrectDetail(data.data);
      setIsLoading(false);
      if (!isUpdating) {
        // setSelectedAct(data?.data?.activity[0]?.activity_id);
        // setAddAct(data?.data?.add[0]?.activity_id);
        setSelectedAct(0);
        setAddAct(0);
        setToggleOpen(1);
      }
      if (data.data?.state_id) {
        dispatch(getphoneCity(data.data?.state_id));
      }
      setProjectInfo({
        ...projectInfo,
        id: data.data.id,
        project_id: data.data.project_id,
        project_name: data.data.project_name,
        start_date: data.data.start_date,
        etc_date: data.data.etc_date,
        completed_date: data.data.completed_date,
        status: data.data.status,
        latitude: data.data.latitude,
        longitude: data.data.longitude,
        client_name: data.data.client_name,
        client_email: data.data.client_email,
        client_phone: data.data.client_phone,
        address: data.data.address,
        state: data.data?.state?.name,
        state_id: data.data.state_id,
        district: data.data?.district?.name,
        district_id: data.data.district_id,
        pincode: data.data.pincode,
        supervisor_name: data.data.supervisor_name,
        supervisor_email: data.data.supervisor_email,
        supervisor_phone: data.data.supervisor_phone,
        project_creator_name: data.data.project_creator_name,
        project_password: data.data.project_password,
        isDraft: data.data.isDraft,
        createdAt: data.data.createdAt,
        updatedAt: data.data.updatedAt,
      });
      setShowBackdrop(false);
    } catch (error) {
      setIsLoading(false);
      setShowBackdrop(false);
      console.log("project details fetching error", error.message);
    }
  };

  const handleInfoChange = (e) => {
    setProjectInfo({
      ...projectInfo,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpdateProject = async () => {
    try {
      setShowBackdrop(true);
      setIsLoading(true);
      const formData = new FormData();
      formData.append("project_id", projectInfo.id);
      formData.append("project_name", projectInfo.project_name);
      formData.append("start_date", projectInfo.start_date);
      formData.append("etc_date", projectInfo.etc_date);
      projectInfo.completed_date &&
        projectInfo.completed_date != null &&
        formData.append("completed_date", projectInfo.completed_date);
      formData.append("status", projectInfo.status);
      formData.append("latitude", projectInfo.latitude);
      formData.append("longitude", projectInfo.longitude);
      formData.append("client_name", projectInfo.client_name);
      formData.append("client_email", projectInfo.client_email);
      formData.append("client_phone", projectInfo.client_phone);
      formData.append("address", projectInfo.address);
      formData.append("state", projectInfo.state);
      formData.append("state_id", projectInfo.state_id);
      formData.append("district", projectInfo.district);
      formData.append("district_id", projectInfo.district_id);
      formData.append("pincode", projectInfo.pincode);
      formData.append("supervisor_name", projectInfo.supervisor_name);
      formData.append("supervisor_email", projectInfo.supervisor_email);
      formData.append("supervisor_phone", projectInfo.supervisor_phone);
      formData.append("project_creator_name", projectInfo.project_creator_name);
      formData.append("project_password", projectInfo.project_password);
      formData.append("logo", projectInfo.client_logo);

      const { data } = await updateProjectApi(params.projectid, formData);
      setProjectInfo({
        ...projectInfo,
        id: "",
        project_id: data.data.project_id,
        project_name: data.data.project_name,
        start_date: data.data.start_date,
        etc_date: data.data.etc_date,
        completed_date: data.data.completed_date,
        status: data.data.status,
        latitude: data.data.latitide,
        longitude: data.data.longitude,
        client_name: data.data.client_name,
        client_email: data.data.client_email,
        client_phone: data.data.client_phone,
        address: data.data.address,
        state: data.data.state?.name,
        state_id: data.data.state_id,
        district: data.data?.district?.name,
        district_id: data.data.district_id,
        pincode: data.data.pincode,
        supervisor_name: data.data.supervisor_name,
        supervisor_email: data.data.supervisor_email,
        supervisor_phone: data.data.supervisor_phone,
        project_creator_name: data.data.project_creator_name,
        project_password: data.data.project_password,
        isDraft: data.data.isDraft,
        createdAt: data.data.createdAt,
        updatedAt: data.data.updatedAt,
      });
      getprojectDetail({ isUpdating: true });
      setSnack({
        ...snack,
        open: true,
        message: "Update successfully",
      });
      setIsLoading(false);
      handleClose();
      setShowBackdrop(false);
    } catch (error) {
      console.log("update project error", error.message);
      setIsLoading(false);
      setShowBackdrop(false);
    }
  };

  useEffect(() => {
    getprojectDetail();
    dispatch(getphoneState());
  }, []);

  return (
    <>
      <Backdrop
        style={{ zIndex: 9, opacity: "35%" }}
        open={showBackdrop}
        // onClick={()}
      >
        {/* <CircularProgress color="inherit" /> */}
        {/* <LinearProgress /> */}
      </Backdrop>
      <ThemeProvider theme={themes}>
        <div className={classes.root}>
          <CssBaseline />
          <Header />

          <main className={classes.content} style={{ overflow: "auto" }}>
            <div className={classes.toolbar} />
            {/* <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/" onClick={handleClick}>
              Projectdetails
            </Link>

            <Typography color="textPrimary"></Typography>
          </Breadcrumbs> */}
            <PageTitle title="Project Details" />

            <Paper style={{ padding: 0 }}>
              <Box className={classes.listbar}>
                <Box className={classes.listing}>
                  <Box>PROJECT ID</Box>
                  <Box>{projrectDetail.project_id} </Box>
                </Box>

                <Box className={classes.listing}>
                  <Box>PROJECT NAME</Box>
                  <Box> {projrectDetail.project_name} </Box>
                </Box>

                <Box className={classes.listing}>
                  <Box>CLIENT NAME</Box>
                  <Box> {projrectDetail.client_name} </Box>
                </Box>

                <Box className={classes.listing}>
                  <Box>STARTING DATE</Box>
                  <Box>
                    {" "}
                    {projrectDetail?.start_date
                      ? moment(projrectDetail.start_date).format("DD/MM/YYYY")
                      : "-"}{" "}
                  </Box>
                </Box>

                <Box className={classes.listing}>
                  <Box>PROJECT STATUS</Box>
                  <Box>
                    {/* <TextField id="outlined-basic" size='small'  style={{'maxHeight':'40px'}} placeholder='outlined' variant="outlined" /> */}
                    {projrectDetail.status}
                  </Box>
                </Box>

                <Box className={classes.listing}>
                  <Box>
                    <Button
                      variant=""
                      size="small"
                      color="primary"
                      style={{ minWidth: "50px", maxWidth: "50px" }}
                      onClick={handleClickOpen}
                    >
                      <EditIcon />
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Paper>
            <Box sx={{ width: "100%", minHeight: "10px" }}>
              {isLoading && <LinearProgress />}
            </Box>
            <Grid container spacing={0}>
              <Grid item xs={2} md={2}>
                <Projectdetailsleft
                  proj={projrectDetail}
                  selectedAct={selectedAct}
                  setSelectedAct={setSelectedAct}
                  toggleOpen={toggleOpen}
                  curAct={
                    toggleOpen == 4
                      ? projrectDetail?.add?.find(
                          (el) => el.activity_id == selectedAct
                        )
                      : projrectDetail?.activity?.find(
                          (el) => el.activity_id == selectedAct
                        )
                  }
                  setAddAct={setAddAct}
                  addAct={addAct}
                />
              </Grid>
              <Grid item xs={10} md={10}>
                <Listing>
                  <List className={classes.tabsButton} disablePadding>
                    <ListItem disablePadding>
                      <ListItemButton
                        variant="contained"
                        style={{ justifyContent: "center" }}
                        className={
                          toggleOpen === 1 ? "tabs active-tabs" : "tabs"
                        }
                        onClick={() => toggleTabs(1)}
                      >
                        Approve/ Decline Tasks
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton
                        variant="contained"
                        style={{ justifyContent: "center" }}
                        className={
                          toggleOpen === 2 ? "tabs active-tabs" : "tabs"
                        }
                        onClick={() => toggleTabs(2)}
                      >
                        Assign Tasks
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton
                        variant="contained"
                        style={{ justifyContent: "center" }}
                        className={
                          toggleOpen === 3 ? "tabs active-tabs" : "tabs"
                        }
                        onClick={() => toggleTabs(3)}
                      >
                        Update Tasks
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton
                        variant="contained"
                        style={{ justifyContent: "center" }}
                        className={
                          toggleOpen === 4 ? "tabs active-tabs" : "tabs"
                        }
                        onClick={() => toggleTabs(4)}
                      >
                        Add Task
                      </ListItemButton>
                    </ListItem>
                  </List>
                </Listing>

                {toggleOpen === 1 && (
                  <ApproveTask
                    projrectDetail={projrectDetail}
                    getprojectDetail={getprojectDetail}
                    setProjrectDetail={setProjrectDetail}
                    selectedAct={selectedAct}
                    setSelectedAct={setSelectedAct}
                    curAct={projrectDetail?.activity?.find(
                      (el) => el.activity_id == selectedAct
                    )}
                  >
                    <Button
                      style={{
                        marginRight: "20px",
                        padding: "0 20px",
                        height: "40px",
                      }}
                      variant="contained"
                      color="primary"
                    >
                      Approved
                    </Button>
                    <Button
                      style={{ padding: "0 20px", height: "40px" }}
                      color="secondary"
                      variant="contained"
                    >
                      Decline
                    </Button>
                  </ApproveTask>
                )}
                {toggleOpen === 2 && (
                  <Activetask
                    projrectDetail={projrectDetail}
                    getprojectDetail={getprojectDetail}
                    setProjrectDetail={setProjrectDetail}
                    selectedAct={selectedAct}
                    setSelectedAct={setSelectedAct}
                    curAct={projrectDetail?.activity?.find(
                      (el) => el.activity_id == selectedAct
                    )}
                  />
                )}
                {toggleOpen === 3 && (
                  <UpdateTask
                    projrectDetail={projrectDetail}
                    getprojectDetail={getprojectDetail}
                    setProjrectDetail={setProjrectDetail}
                    selectedAct={selectedAct}
                    setSelectedAct={setSelectedAct}
                    curAct={projrectDetail?.activity?.find(
                      (el) => el.activity_id == selectedAct
                    )}
                  />
                )}
                {toggleOpen === 4 && (
                  <Addtask
                    projrectDetail={projrectDetail}
                    getprojectDetail={getprojectDetail}
                    setProjrectDetail={setProjrectDetail}
                    selectedAct={selectedAct}
                    setSelectedAct={setSelectedAct}
                    curAct={projrectDetail?.add?.find(
                      (el) => el.activity_id == addAct
                    )}
                    setAddAct={setAddAct}
                    addAct={addAct}
                  />
                )}
              </Grid>
            </Grid>
          </main>
        </div>

        <Dialog
          fullScreen={fullScreen}
          open={open}
          fullWidth={true}
          maxWidth={"md"}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            id="responsive-dialog-title"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {"Project Detail"}
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Grid container spacing={4} style={{ marginTop: 20 }}>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    id="fullWidth"
                    placeholder="SEPL009"
                    size="small"
                    name="project_id"
                    value={projectInfo.project_id}
                    disabled={true}
                    onChange={(e) => {
                      handleInfoChange(e);
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    id="fullWidth"
                    placeholder="ESSL Kaudgaon"
                    size="small"
                    value={projectInfo.project_name}
                    name="project_name"
                    onChange={(e) => {
                      setProjectInfo({
                        ...projectInfo,
                        project_name: e.target.value.replace(
                          /[0-9$&/+,:;=?@#|[\]\\'<>.^*()%!_-]/gi,
                          ""
                        ),
                      });

                      // handleInfoChange(e)
                    }}
                  />
                </Grid>

                <Grid item md={6}>
                  {/* <TextField
                  fullWidth
                  label="Starting Date"
                  id="fullWidth"
                  placeholder="08/06/2022"
                  size="small"
                  value={projectInfo.start_date}
                  name="start_date"
                  onChange={handleInfoChange}
                /> */}

                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      label="Start Date"
                      // minDate={projectInfo.start_date?moment(new Date(projectInfo.start_date)):moment(new Date())}
                      // maxDate={
                      //   projectInfo.etc_date
                      //     ? moment(new Date(projectInfo.etc_date))
                      //     : moment(new Date("2100-01-01"))
                      // }
                      inputFormat="DD/MM/YYYY"
                      name="start_date"
                      value={projectInfo.start_date}
                      readOnly
                      disableOpenPicker={true}
                      // onChange={(e) => {
                      //   setProjectInfo({
                      //     ...projectInfo,
                      //     start_date: moment(e).utc().format(),
                      //   });
                      // }}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          name="start_date"
                          size="small"
                          className={classes.textField}
                          variant="outlined"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item md={6}>
                  {/* <TextField
                  fullWidth
                  label="ETC Date"
                  id="fullWidth"
                  placeholder="01/07/2022"
                  size="small"
                  value={projectInfo.etc_date}
                  name="etc_date"
                  onChange={handleInfoChange}
                /> */}

                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      label="ETC Date"
                      minDate={
                        projectInfo.start_date
                          ? moment(projectInfo.start_date)
                          : moment(new Date())
                      }
                      maxDate={
                        projectInfo.completed_date
                          ? moment(projectInfo.completed_date)
                          : moment(new Date("2100-01-01"))
                      }
                      inputFormat="DD/MM/YYYY"
                      name="etc_date"
                      value={projectInfo.etc_date}
                      onChange={(e) => {
                        setProjectInfo({
                          ...projectInfo,
                          etc_date: moment(e).utc().format(),
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          name="etc_date"
                          size="small"
                          className={classes.textField}
                          variant="outlined"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item md={6}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      label="Completed Date"
                      minDate={
                        projectInfo.etc_date
                          ? moment(projectInfo.etc_date)
                          : moment(new Date())
                      }
                      inputFormat="DD/MM/YYYY"
                      name="completed_date"
                      value={projectInfo.completed_date}
                      onChange={(e) => {
                        setProjectInfo({
                          ...projectInfo,
                          completed_date: moment(e).utc().format(),
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          name="etc_date"
                          size="small"
                          className={classes.textField}
                          variant="outlined"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Project Status
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Project Status"
                      value={projectInfo.status}
                      name="status"
                      size="small"
                      onChange={handleInfoChange}
                    >
                      <MenuItem value="IN_PROGRESS">In Progress</MenuItem>
                      <MenuItem value="COMPLETED">Completed</MenuItem>
                      <MenuItem value="HOLD">On Hold</MenuItem>
                      <MenuItem value="NOT_STARTED">Not Started</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item md={6}>
                  <TextField
                    fullWidth
                    label="Latitude"
                    id="fullWidth"
                    size="small"
                    placeholder="28.53550° N"
                    value={projectInfo.latitude}
                    name="latitude"
                    onChange={(e) => {
                      setProjectInfo({
                        ...projectInfo,
                        latitude: e.target.value.replace(
                          /[a-zA-Z$&/+,[\]\\:;=?@#|'<>^*()%!_-]/gi,
                          ""
                        ),
                      });

                      // handleInfoChange(e);
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    label="Longitude"
                    id="fullWidth"
                    placeholder="28.53550° N"
                    size="small"
                    value={projectInfo.longitude}
                    name="longitude"
                    onChange={(e) => {
                      setProjectInfo({
                        ...projectInfo,
                        longitude: e.target.value.replace(
                          /[a-zA-Z$&/+,[\]\\:;=?@#|'<>^*()%!_-]/gi,
                          ""
                        ),
                      });
                      // handleInfoChange(e);
                    }}
                  />
                </Grid>

                <Grid item md={6}>
                  <TextField
                    fullWidth
                    label="Client Name"
                    id="fullWidth"
                    placeholder="Bhaskar Jyoti India Pvt Ltd"
                    size="small"
                    value={projectInfo.client_name}
                    name="client_name"
                    onChange={(e) => {
                      setProjectInfo({
                        ...projectInfo,
                        client_name: e.target.value.replace(
                          /[0-9.$&/+,[\]\\:;=?@#|'<>^*()%!_-]/gi,
                          ""
                        ),
                      });

                      // handleInfoChange(e)
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    label="Client Email"
                    id="fullWidth"
                    placeholder="baskar@gmail .com"
                    size="small"
                    value={projectInfo.client_email}
                    name="client_email"
                    onChange={handleInfoChange}
                  />
                </Grid>

                <Grid item md={6}>
                  <TextField
                    fullWidth
                    label="Client Number"
                    id="fullWidth"
                    placeholder="8888899999"
                    size="small"
                    value={projectInfo.client_phone}
                    inputProps={{ maxLength: 10 }}
                    name="client_phone"
                    onChange={(e) => {
                      setProjectInfo({
                        ...projectInfo,
                        client_phone: e.target.value.replace(
                          /[a-zA-Z$&/+,:;=?@#|'<>.^*()%!_-]/gi,
                          ""
                        ),
                      });
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    label="Address"
                    id="fullWidth"
                    placeholder="Shivpuri"
                    size="small"
                    value={projectInfo.address}
                    name="address"
                    onChange={handleInfoChange}
                  />
                </Grid>

                <Grid item md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">State</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={projectInfo.state_id}
                      label="Project Status"
                      name="state_id"
                      size="small"
                      onChange={(e) => {
                        handleInfoChange(e);
                        dispatch(getphoneCity(e.target.value));
                      }}
                    >
                      {phoneState.map((st, ind) => (
                        <MenuItem key={st.id} value={st.id}>
                          {st.name}{" "}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      District
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={projectInfo.district_id}
                      label="Project Status"
                      name="district_id"
                      size="small"
                      onChange={handleInfoChange}
                    >
                      {phoneCity.map((cit, ind) => (
                        <MenuItem key={`district${cit.id}`} value={cit.id}>
                          {cit.name}{" "}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item md={6}>
                  <TextField
                    fullWidth
                    label="Pin Code"
                    id="fullWidth"
                    placeholder="200022"
                    size="small"
                    inputProps={{ maxLength: 6 }}
                    value={projectInfo.pincode}
                    name="pincode"
                    onChange={(e) => {
                      setProjectInfo({
                        ...projectInfo,
                        pincode: e.target.value.replace(
                          /[a-zA-Z$&/+,:[\]\\;=?@#|'<>.^*()%!_-]/gi,
                          ""
                        ),
                      });
                    }}
                  />
                </Grid>

                <Grid item md={6}>
                  <TextField
                    fullWidth
                    label="Supervisor Name"
                    id="fullWidth"
                    placeholder="Kusum"
                    size="small"
                    value={projectInfo.supervisor_name}
                    name="supervisor_name"
                    onChange={(e) => {
                      setProjectInfo({
                        ...projectInfo,
                        supervisor_name: e.target.value.replace(
                          /[0-9.$&/+,[\]\\:;=?@#|'<>^*()%!_-]/gi,
                          ""
                        ),
                      });
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    label="Supervisor Email"
                    id="fullWidth"
                    placeholder="Kusum@gmail.com"
                    size="small"
                    value={projectInfo.supervisor_email}
                    name="supervisor_email"
                    onChange={handleInfoChange}
                  />
                </Grid>

                <Grid item md={6}>
                  <TextField
                    fullWidth
                    label="Supervisor Contact"
                    id="fullWidth"
                    placeholder="8888899999"
                    size="small"
                    inputProps={{ maxLength: 10 }}
                    value={projectInfo.supervisor_phone}
                    name="supervisor_phone"
                    onChange={(e) => {
                      setProjectInfo({
                        ...projectInfo,
                        supervisor_phone: e.target.value.replace(
                          /[a-zA-Z$&/+,:;=?@#|'<>.^*()%!_-]/gi,
                          ""
                        ),
                      });
                    }}
                  />
                </Grid>
                <Grid item md={12}>
                  <Tooltip title="Would be 600x600px, 320x320px">
                    <label htmlFor="contained-button-file">
                      <Input
                        accept="image/*"
                        id="contained-button-file"
                        name="client_logo"
                        // value={formik.values.client_logo}
                        multiple
                        fullWidth
                        type="file"
                        onChange={(e) => {
                          setProjectInfo({
                            ...projectInfo,
                            client_logo: e.target.files[0],
                          });
                        }}
                      />

                      <Button
                        variant="contained"
                        component="span"
                        color="primary"
                        fullWidth
                      >
                        {projectInfo?.client_logo?.name
                          ? projectInfo.client_logo.name
                          : "Client Logo"}{" "}
                        <PhotoCamera sx={{ ml: 1 }} />
                        {}
                      </Button>
                    </label>
                  </Tooltip>
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    label="Creator Name"
                    id="fullWidth"
                    placeholder="Praveen"
                    size="small"
                    value={projectInfo.project_creator_name}
                    name="project_creator_name"
                    onChange={(e) => {
                      setProjectInfo({
                        ...projectInfo,
                        project_creator_name: e.target.value.replace(
                          /[0-9.$&/+,[\]\\:;=?@#|'<>^*()%!_-]/gi,
                          ""
                        ),
                      });
                    }}
                  />
                </Grid>

                <Grid item md={6}>
                  <OutlinedInput
                    fullWidth
                    label="Project Password"
                    id="fullWidth"
                    placeholder="Project Password"
                    type={showPass ? "text" : "password"}
                    size="small"
                    value={projectInfo.project_password}
                    name="project_password"
                    onChange={handleInfoChange}
                    style={{ background: "#fff", cursor: "pointer" }}
                    endAdornment={
                      <Box
                        style={{ lineHeight: "normal" }}
                        onClick={() => {
                          setShowPass(!showPass);
                        }}
                      >
                        {showPass ? <VisibilityOff /> : <Visibility />}
                      </Box>
                    }
                  />
                </Grid>
                <Grid item md={6}></Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ "justify-content": "center" }}>
            <DangerBtn
              autoFocus
              onClick={handleClose}
              sx={{ minWidth: "100px", padding: "10px 20px !important" }}
            >
              Cancel
            </DangerBtn>
            <PrimaryBtn
              onClick={handleUpdateProject}
              autoFocus
              sx={{ minWidth: "100px", padding: "10px 20px !important" }}
            >
              Update
            </PrimaryBtn>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
      <Snackbar
        open={snack.open}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        autoHideDuration={3000}
        onClose={() => {
          setSnack({
            ...snack,
            open: false,
            message: "",
          });
        }}
        message={snack.message}
      />
    </>
  );
}

const Listing = styled.div`
  background: white;
  .tabs {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    font-weight: bold;
    border-bottom: 4px solid transparent;
    font-size: 0.95rem;
    &:hover {
      color: #3c44b1;
      border-color: #3c44b1;
      // box-shadow: 0 0.313rem 0.8rem rgb(60 68 177 / 50%), 0 0.126rem 0.225rem rgb(60 68 177 / 30%);
    }

    & > span.txt {
      background: #e8fbfd;
      color: #049aac;
      text-align: center;
      height: 26px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      border-radius: 30px;
      font-weight: bold;
    }
  }
  .tabs.active-tabs {
    // box-shadow: 0 0.313rem 0.8rem rgb(60 68 177 / 50%), 0 0.126rem 0.225rem rgb(60 68 177 / 30%);
    color: #3c44b1;
    border-color: #3c44b1;
    background: rgba(0, 0, 0, 0.02);
  }
  .btn:not(:disabled):hover {
    transform: translateY(-2px);
  }
`;
const Input = styled("input")({
  display: "none",
});
