import React, { useMemo, useEffect, useState, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import { makeStyles } from "@material-ui/core/styles";

import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import ActivitiesNavbar from "./ActivitiesNavbar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import styled from "styled-components";
import Badge from "@mui/material/Badge";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_ACTIVITY,
  GET_TASK,
  UPDATE_NEW_TASK,
} from "../../../redux/constants";
import activityList from "../../../data/activityData.json";
import taskList from "../../../data/taskData.json";
import { getTask } from "../../../redux/action/masterAction";
import moment from "moment";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeOutlinedIcon from "@material-ui/icons/NavigateBeforeOutlined";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1F487C",
    },
  },
});
export default function Activities({
  selectedTask,
  setSelectedTask,
  checkedTask,
  setCheckedTask,
  setToggleOpen,
}) {
  const dispatch = useDispatch();
  const [activityId, setActivityId] = useState(0);
  const [allDateSelect, setAllDateSelect] = useState({
    scheduled_date: null,
    etc_date: null,
  });
  const task = useSelector((state) => state.master.task);
  const [divHeight, setDivHeight] = useState(null);
  const ref = useRef(null);

  // const COLUMNS = [
  //   {
  //     Header: (props) => (
  //       <Checkbox
  //         // defaultChecked
  //         checked={
  //           selectedTask
  //             .filter(
  //               (t) =>
  //                 t.activity_id == activityId
  //             ).length ===
  //           checkedTask.filter((ck) =>ck.activity_id===activityId).length
  //         }
  //         color="primary"
  //         inputProps={{ "aria-label": "secondary checkbox" }}
  //         onClick={(e) =>{
  //            handleCheckAll(e.target.checked);
  //         }}
  //       />
  //     ),
  //     accessor: "id",
  //     Cell: (props) => (
  //       <Checkbox
  //         checked={
  //           checkedTask.find(
  //             (el) =>
  //               el.id == props?.row?.original?.id &&
  //               el.activity_id == props?.row.original?.activity_id
  //           )
  //             ? true
  //             : false
  //         }
  //         color="primary"
  //         inputProps={{ "aria-label": "secondary checkbox" }}
  //         onClick={() => {
  //           handleAddtask(props.row.original);
  //         }}
  //       />
  //     ),
  //   },
  //   {
  //     Header: "Task Name",
  //     accessor: "name",
  //   },
  //   {
  //     Header: "Units",
  //     accessor: "uom",
  //   },

  //   {
  //     Header: "Goal/Total Work",
  //     accessor: "goal",
  //     Cell: (props) => (
  //       <>
  //         <Box width={140}>
  //           <TextField
  //             variant="outlined"
  //             size="small"
  //             fullWidth
  //             value={ props?.row?.original?.goal}
  //             onChange={(e) => {
  //               props.row.original.goal=e.target.value;
  //               setSelectedTask([...selectedTask]);
  //             }}
  //           />
  //         </Box>
  //       </>
  //     ),
  //   },
  //   {
  //     Header: "SCHEDULE DATE",
  //     accessor: "scheduled_date",
  //     Cell: (props) => (
  //       <>
  //         <Box style={{ width: "160px" }}>
  //           <LocalizationProvider dateAdapter={AdapterMoment}>
  //             <DatePicker
  //               label="SCHEDULE Date"
  //               minDate={moment(new Date())}
  //               inputFormat="DD-MM-YYYY"
  //               // mask='DD-MM-YYYY'
  //               value={props?.row?.original?.scheduled_date}
  //               onChange={(e) => {
  //                 props.row.original.scheduled_date = moment(e).utc().format();

  //                 setSelectedTask([...selectedTask]);
  //               }}
  //               renderInput={(params) => (
  //                 <TextField variant="outlined" size="small" {...params} />
  //               )}
  //             />
  //           </LocalizationProvider>
  //         </Box>
  //       </>
  //     ),
  //   },
  //   {
  //     Header: "ETC Date",
  //     accessor: "etc_date",
  //     Cell: (props) => (
  //       <>
  //         <Box style={{ width: "160px" }}>
  //           <LocalizationProvider dateAdapter={AdapterMoment}>
  //             <DatePicker
  //               label="ETC Date"
  //               minDate={moment(new Date())}
  //               inputFormat="DD-MM-YYYY"
  //               // mask='DD-MM-YYYY'
  //               value={props?.row?.original?.etc_date}
  //               onChange={(e) => {
  //                 props.row.original.etc_date=moment(e).utc().format();
  //                 setSelectedTask([...selectedTask]);
  //               }}
  //               renderInput={(params) => (
  //                 <TextField variant="outlined" size="small" {...params} />
  //               )}
  //             />
  //           </LocalizationProvider>
  //         </Box>
  //       </>
  //     ),
  //   },
  // ];

  // const columns = useMemo(
  //   () => COLUMNS,
  //   [checkedTask,selectedTask]
  // );

  // const tableInstance = useTable(
  //   {
  //     columns: columns,
  //     data: selectedTask,
  //   },
  //   useFilters,
  //   useGlobalFilter,
  //   useSortBy,
  //   usePagination
  // );

  // const {
  //   getTableProps,
  //   getTableBodyProps,
  //   headerGroups,
  //   rows,
  //   page,
  //   pageCount,
  //   pageOptions,
  //   setPageSize,
  //   nextPage,
  //   previousPage,
  //   canPreviousPage,
  //   canNextPage,
  //   prepareRow,
  //   state,
  //   setGlobalFilter,
  //   setFilter,
  //   setAllFilters,
  //   preFilteredRows,
  //   toggleSortBy,
  // } = tableInstance;

  // const { globalFilter, filters, pageIndex, pageSize } = state;

  const handleAddtask = (val) => {
    const isAdded = checkedTask.find((el) => el.id == val.id);
    if (isAdded) {
      const filtCk = checkedTask.filter((el) => el.id != val.id);
      setCheckedTask(filtCk);
    } else {
      setCheckedTask([...checkedTask, val]);
    }
  };
  const handleCheckAll = (val) => {
    if (val) {
      if (activityId == 0) {
        const alllTask = selectedTask.filter((el) => el);
        const withoutCurAct = checkedTask.filter((el) => el);
        setCheckedTask(alllTask);
        setSelectedTask([...selectedTask]);
      } else {
        const alllTask = selectedTask.filter(
          (el) => el.activity_id == activityId
        );
        const withoutCurAct = checkedTask.filter(
          (el) => el.activity_id != activityId
        );
        setCheckedTask([...withoutCurAct, ...alllTask]);
      }
    } else {
      if (activityId == 0) {
        setCheckedTask([]);
      } else {
        const filteredCheckedtask = checkedTask.filter(
          (el) => el.activity_id != activityId
        );
        setCheckedTask([...filteredCheckedtask]);
      }
    }
  };

  useEffect(() => {
    setDivHeight(ref.current.clientHeight);
    console.log("height: ", ref.current.clientHeight);
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Grid container>
          <Grid item xs={3}>
            <ActivitiesNavbar
              activityId={activityId}
              setActivityId={setActivityId}
            />
          </Grid>
          <Grid
            item
            xs={9}
            ref={ref}
            style={{ position: "relative", height: divHeight }}
          >
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  {/* {headerGroups.map((headerGroup) => (
                    <TableRow {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <TableCell>{column.render("Header")}</TableCell>
                      ))}
                    </TableRow>
                  ))} */}

                  <TableRow>
                    <TableCell>
                      <Checkbox
                        // defaultChecked
                        checked={
                          activityId == 0
                            ? selectedTask.length == checkedTask.length
                            : selectedTask.filter(
                                (t) => t.activity_id == activityId
                              ).length ===
                              checkedTask.filter(
                                (ck) => ck.activity_id === activityId
                              ).length
                        }
                        color="primary"
                        size="small"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                        onClick={(e) => {
                          handleCheckAll(e.target.checked);
                        }}
                      />
                    </TableCell>
                    <TableCell style={{ color: "#1F487C" }}>Sr. No</TableCell>
                    <TableCell style={{ color: "#1F487C" }}>
                      {" "}
                      Task Name{" "}
                    </TableCell>
                    <TableCell style={{ color: "#1F487C" }}> Units </TableCell>
                    <TableCell
                      style={{ color: "#1F487C", textAlign: "center" }}
                    >
                      {" "}
                      Total Work{" "}
                    </TableCell>
                    <TableCell style={{ color: "#1F487C" }}>
                      <Box
                        style={{
                          width: "160px",
                          height: "40px",
                          transform: "scale(0.9)",
                        }}
                      >
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker
                            inputFormat="DD/MM/YYYY"
                            // mask='DD-MM-YYYY'
                            label="Schedule Date"
                            value={allDateSelect.scheduled_date}
                            placeholder="Schedule Date"
                            onChange={(e) => {
                              setAllDateSelect({
                                ...allDateSelect,
                                scheduled_date: moment(e).utc().format(),
                              });

                              if (activityId == 0) {
                                selectedTask.map((el) => {
                                  el.scheduled_date = moment(e).utc().format();
                                  return el;
                                });
                              } else {
                                selectedTask
                                  .filter((el) => el.activity_id == activityId)
                                  .map((el) => {
                                    el.scheduled_date = moment(e)
                                      .utc()
                                      .format();
                                    return el;
                                  });
                              }

                              setSelectedTask([...selectedTask]);
                            }}
                            renderInput={(params) => (
                              <TextField
                                variant="outlined"
                                size="small"
                                {...params}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>
                    </TableCell>
                    <TableCell style={{ color: "#1F487C" }}>
                      <Box style={{ width: "160px", transform: "scale(0.9)" }}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker
                            inputFormat="DD/MM/YYYY"
                            // mask='DD-MM-YYYY'
                            label="ETC Date "
                            value={allDateSelect.etc_date}
                            placeholder="ETC Date"
                            onChange={(e) => {
                              setAllDateSelect({
                                ...allDateSelect,
                                etc_date: moment(e).utc().format(),
                              });

                              if (activityId == 0) {
                                selectedTask.map((el) => {
                                  el.etc_date = moment(e).utc().format();
                                  return el;
                                });
                              } else {
                                selectedTask
                                  .filter((el) => el.activity_id == activityId)
                                  .map((el) => {
                                    el.etc_date = moment(e).utc().format();
                                    return el;
                                  });
                              }

                              setSelectedTask([...selectedTask]);
                            }}
                            renderInput={(params) => (
                              <TextField
                                variant="outlined"
                                size="small"
                                {...params}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedTask
                    .filter((el) => {
                      if (activityId == 0) {
                        return el;
                      } else {
                        return el.activity_id == activityId;
                      }
                    })
                    .map((data, ind) => (
                      <>
                        <TableRow>
                          <TableCell>
                            <Checkbox
                              size="small"
                              checked={
                                checkedTask.find(
                                  (el) =>
                                    el.id == data.id &&
                                    el.activity_id == data?.activity_id
                                )
                                  ? true
                                  : false
                              }
                              color="primary"
                              inputProps={{
                                "aria-label": "secondary checkbox",
                              }}
                              onClick={() => {
                                handleAddtask(data);
                              }}
                            />
                          </TableCell>
                          <TableCell>{ind + 1}</TableCell>

                          <TableCell>{data.name}</TableCell>
                          <TableCell>{data.uom}</TableCell>
                          <TableCell>
                            <Box
                              style={{
                                width: "200px",
                                transform: "scale(0.9)",
                              }}
                            >
                              <TextField
                                variant="outlined"
                                size="small"
                                fullWidth
                                value={data?.goal}
                                inputProps={{ maxLength: 9 }}
                                onChange={(e) => {
                                  data.goal = e.target.value.replace(
                                    /[a-zA-[\]\\Z$&/+,:;=?@#|'<>.^*()%!_-]/gi,
                                    ""
                                  );
                                  setSelectedTask([...selectedTask]);
                                }}
                              />
                              {data.error_goal && (
                                <p className="errorText">{data.error_goal}</p>
                              )}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box
                              style={{
                                width: "160px",
                                transform: "scale(0.9)",
                              }}
                            >
                              <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                  // minDate={moment(new Date())}
                                  // maxDate={
                                  //   data.etc_date
                                  //     ? moment(new Date(data.etc_date))
                                  //     : moment(new Date("2100-01-01"))
                                  // }
                                  inputFormat="DD/MM/YYYY"
                                  // mask='DD-MM-YYYY'
                                  value={data?.scheduled_date}
                                  onChange={(e) => {
                                    data.scheduled_date = moment(e)
                                      .utc()
                                      .format();

                                    setSelectedTask([...selectedTask]);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      variant="outlined"
                                      size="small"
                                      {...params}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                              {data.error_scheduled_date && (
                                <p className="errorText">
                                  {data.error_scheduled_date}
                                </p>
                              )}
                            </Box>
                          </TableCell>

                          <TableCell>
                            <Box
                              style={{
                                width: "160px",
                                transform: "scale(0.9)",
                              }}
                            >
                              <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                  // minDate={
                                  //   data.scheduled_date
                                  //     ? moment(data.scheduled_date)
                                  //     : moment(new Date())
                                  // }
                                  inputFormat="DD/MM/YYYY"
                                  // mask='DD-MM-YYYY'
                                  value={data?.etc_date}
                                  onChange={(e) => {
                                    data.etc_date = moment(e).utc().format();
                                    setSelectedTask([...selectedTask]);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      variant="outlined"
                                      size="small"
                                      {...params}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                              {data.error_etc_date && (
                                <p className="errorText">
                                  {data.error_etc_date}
                                </p>
                              )}
                            </Box>
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                  {/* {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <TableRow {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return <TableCell>{cell.render("Cell")}</TableCell>;
                        })}
                      </TableRow>
                    );
                  })} */}
                </TableBody>
              </Table>
            </TableContainer>

            <Grid item xs={12}>
              <Box
                p={3}
                textAlign="center"
                style={{ position: "relative", bottom: "0", width: "100%" }}
              >
                <Button
                  color="primary"
                  size="small"
                  onClick={() => {
                    setToggleOpen(1);
                  }}
                >
                  {" "}
                  <NavigateBeforeOutlinedIcon /> Back{" "}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ marginLeft: "20px" }}
                  onClick={() => {
                    setToggleOpen(3);
                  }}
                >
                  {" "}
                  Next <NavigateNextIcon />
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
}

const Inputdiv = styled(Badge)`
  background-color: #e5f9ed !important;
  color: #1bc943 !important;
  display: inline-block;
  padding: 0 0.7em;
  font-size: 70%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.2rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;
const Listing = styled.div`
  background: white;
  .tabs {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 15px;
    font-weight: bold;
    text-align: center;
    &:hover {
      background: #3c44b1;
      color: #fff;
      text-align: center;
      box-shadow: 0 0.313rem 0.8rem rgb(60 68 177 / 50%),
        0 0.126rem 0.225rem rgb(60 68 177 / 30%);
    }

    & > span.txt {
      background: #e8fbfd;
      color: #049aac;
      text-align: center;
      height: 26px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      border-radius: 30px;
      font-weight: bold;
    }
  }
  li {
    text-align: center;
    justify-content: center;
  }
  .tabs.active-tabs {
    box-shadow: 0 0.313rem 0.8rem rgb(60 68 177 / 50%),
      0 0.126rem 0.225rem rgb(60 68 177 / 30%);
    background: #3c44b1;
    color: #fff;
  }
  .btn:not(:disabled):hover {
    transform: translateY(-2px);
  }
`;
