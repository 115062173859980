import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Header from "../../Component/Header/Header";
import PageTitle from "../../Component/PageTitle/PageTitle";
import {
  Box,
  Button,
  Grid,
  Paper,
  Skeleton,
  CircularProgress,
} from "@mui/material";
import Divider from "@mui/material/Divider";

import TablePagination from "@mui/material/TablePagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import {
  DangerBtn,
  SaccessBtn,
  PrimaryBtn,
  NeutralBtn,
} from "../../Pages/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import Footer from "../Footer/Footer";
import Subtitle from "../../Component/PageTitle/Subtitle";
import { withStyles } from "@material-ui/core/styles";
import { TextField, Snackbar } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "./Dashboard.scss";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
  allAssignedList,
  projectProgress,
  approveBulk,
  rejectBulk,
  accRejSingle,
  getProjectList,
} from "../../redux/api/index";
import { projectListAction } from "../../redux/action/projectAction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const data = [
  { name: "Group A", value: 300 },
  { name: "Group B", value: 400 },
  { name: "Group C", value: 500 },
];
const Land = ["#647BFF", "#3CD4A0", "#FDB834"];

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 4,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 1,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

const longText = `
Aliquam eget finibus ante, non facilisis lectus. Sed vitae dignissim est, vel aliquam tellus.
Praesent non nunc mollis, fermentum neque at, semper arcu.
Nullam eget est sed sem iaculis gravida eget vitae justo.
`;

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#1F487C",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: "#9c27b0",
    },
  },
});
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    overflowX: "auto",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
}));
const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
});

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function Dashboard() {
  const classes = useStyles();
  const [dateVal, setdateVal] = useState(new Date());
  const [allTasks, setAllTasks] = useState([]);
  const [projProg, setProjProg] = useState([]);
  let [checkedTask, setCheckedTask] = useState([]);
  const [page, setPage] = React.useState(0);
  const [snack, setSnack] = useState({ open: false, message: "" });
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const inProgressproj = useSelector((state) => state.project.inprogress);
  const completedProj = useSelector((state) => state.project.completed);
  const holdProj = useSelector((state) => state.project.hold);
  const [allProjCount, setAllProjCount] = useState(0);
  const [loadingTask, setLoadingTask] = useState(false);
  const [projCount, setProjCount] = useState([
    {
      name: "In Progress",
      value: 0,
    },
    {
      name: "Completed",
      value: 0,
    },
    {
      name: "Hold",
      value: 0,
    },
  ]);
  const allProject = useSelector((state) => state.project.allProject);

  const [data, setData] = useState([
    {
      name: "In Progress",
      value: projCount.inprogressCount,
    },
    {
      name: "Completed",
      value: projCount.completedCount,
    },
    { name: "Hold", value: projCount.holdCount },
  ]);

  const dispatch = useDispatch();
  const fetchTasks = async (dt) => {
    try {
      setLoadingTask(true);
      const curDate = moment().format("MM-DD-YYYY");

      const { data } = await allAssignedList(dt ? dt : curDate);
      setAllTasks(data.data);
      setLoadingTask(false);
    } catch (error) {
      console.log("all task fetch issue", error.message);
      setLoadingTask(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const fetchProjectList = async () => {
    try {
      const { data } = await getProjectList();
      setAllProjCount(data.data.length);
      const inProgressProj = data.data.filter(
        (el) => el.status == "IN_PROGRESS"
      ).length;
      const completedProj = data.data.filter(
        (el) => el.status == "COMPLETED"
      ).length;
      const holdProj = data.data.filter((el) => el.status == "HOLD").length;
      setProjCount([
        {
          name: "In Progress",
          value: inProgressProj,
        },
        {
          name: "Completed",
          value: completedProj,
        },
        { name: "Hold", value: holdProj },
      ]);
    } catch (error) {
      console.log("project list error");
    }
  };

  const getchProhectProgress = async () => {
    try {
      const { data } = await projectProgress();
      setProjProg(data.data);
    } catch (error) {
      console.log("project progress fetch error");
    }
  };

  const handleAddtask = (val) => {
    const isAdded = checkedTask.find((el) => el.id == val.id);
    if (isAdded) {
      const filtCk = checkedTask.filter((el) => el.id != val.id);
      setCheckedTask(filtCk);
    } else {
      setCheckedTask([...checkedTask, val]);
    }
  };
  const handleCheckAll = (val) => {
    if (val) {
      setCheckedTask(allTasks);
    } else {
      setCheckedTask([]);
    }
  };
  const handleApproveRej = async (asTask, action) => {
    try {
      const { data } = await accRejSingle(asTask.project_id, asTask, action);
      fetchTasks(dateVal);
      setSnack({
        ...snack,
        open: true,
        message: "Task Approved successfully",
      });
    } catch (error) {
      setSnack({
        ...snack,
        open: true,
        message:
          error?.response?.data?.message ||
          error?.message ||
          "Something went wrong, please try again later",
      });
      console.log("task approve reject error", error.message);
    }
  };

  const handleRejectBulk = async (asTask) => {
    let outData = checkedTask
      .filter((el) => el.work_status === "IN_PROGRESS" && el.isSubmitted == 1)
      .map((el) => {
        let val = {};
        val.id = el.id;
        val.project_id = el.project_id;
        val.project_task_id = el.project_task_id;
        val.task_id = el.task_id;
        val.activity_id = el.activity_id;
        return val;
      });
    if (outData.length === 0) {
      // setSnack({
      //   ...snack,
      //   open:true,
      //   message:"Please check task to be rejected"
      // })
      return;
    }

    try {
      await rejectBulk(outData);
      fetchTasks(dateVal);
      setSnack({
        ...snack,
        open: true,
        message: "Task Approved successfully",
      });
      setCheckedTask([]);
    } catch (error) {
      setSnack({
        ...snack,
        open: true,
        message:
          error?.response?.data?.message ||
          error?.message ||
          "Something went wrong, please try again later",
      });
      console.log("task update error");
    }
  };
  const handleApproveBulk = async (asTask) => {
    let outData = checkedTask
      .filter((el) => el.work_status === "IN_PROGRESS" && el.isSubmitted == 1)
      .map((el) => {
        let val = {};
        val.id = el.id;
        val.project_id = el.project_id;
        val.project_task_id = el.project_task_id;
        val.task_id = el.task_id;
        val.activity_id = el.activity_id;
        return val;
      });
    if (outData.length === 0) {
      // setSnack({
      //   ...snack,
      //   open:true,
      //   message:"Please check task to be approved"
      // })
      return;
    }

    try {
      await approveBulk(outData);
      fetchTasks(dateVal);
      setCheckedTask([]);
      setSnack({
        ...snack,
        open: true,
        message: "Task Approved successfully",
      });
    } catch (error) {
      setSnack({
        ...snack,
        open: true,
        message: "Something went wrong, pleasr try again later",
      });
      console.log("task update error");
    }
  };

  useEffect(() => {
    fetchTasks(dateVal);
    getchProhectProgress();
    dispatch(projectListAction());
    fetchProjectList();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <Header setDashboardSnack={setSnack} dashboardSnack={snack} />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {/* <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/" onClick={handleClick}>
              Dashboard
            </Link>
            <Typography color="textPrimary"></Typography>
          </Breadcrumbs> */}
          <PageTitle title="Dashboard" />
          <Grid spacing={3} container>
            <Grid item xs={12} lg={5} md={5}>
              <Box
                component={Paper}
                sx={{ display: "block", mb: 3 }}
                elevation={4}
              >
                <Subtitle
                  leftTitle="Total Project"
                  rftValue={`${
                    Number(projCount[0].value) +
                    Number(projCount[1].value) +
                    Number(projCount[2].value)
                  }`}
                />
                <Grid spacing={0} container>
                  <Grid item xs={7}>
                    <List className="total_project">
                      <ListItem>
                        <Typography
                          style={{
                            color: "#6E6B7B",
                            display: "flex",
                            alignItems: "center",
                            paddingRight: "6px",
                          }}
                        >
                          <span
                            style={{
                              width: "10px",
                              height: "10px",
                              background: Land[1],
                              display: "block",
                              marginRight: "5px",
                              borderRadius: "60px",
                            }}
                          ></span>
                          Completed Projects{" "}
                          <span style={{ paddingLeft: "10px" }}>
                            {projCount[1].value}
                          </span>
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography
                          style={{
                            color: "#6E6B7B",
                            display: "flex",
                            alignItems: "center",
                            paddingRight: "6px",
                          }}
                        >
                          <span
                            style={{
                              width: "10px",
                              height: "10px",
                              background: Land[0],
                              display: "block",
                              marginRight: "5px",
                              borderRadius: "60px",
                            }}
                          ></span>{" "}
                          In Progress Projects{" "}
                          <span style={{ paddingLeft: "10px" }}>
                            {projCount[0].value}
                          </span>{" "}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography
                          style={{
                            color: "#6E6B7B",
                            display: "flex",
                            alignItems: "center",
                            paddingRight: "6px",
                          }}
                        >
                          <span
                            style={{
                              width: "10px",
                              height: "10px",
                              background: Land[2],
                              display: "block",
                              marginRight: "5px",
                              borderRadius: "60px",
                            }}
                          ></span>{" "}
                          Hold Projects{" "}
                          <span style={{ paddingLeft: "10px" }}>
                            {projCount[2].value}
                          </span>
                        </Typography>
                      </ListItem>
                    </List>
                  </Grid>

                  <Grid item xs={5} style={{ minHeight: "200px" }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <PieChart width={400} height={200}>
                        <Pie
                          data={projCount}
                          cx={90}
                          cy={100}
                          innerRadius={55}
                          outerRadius={70}
                          fill="#8884d8"
                          paddingAngle={0}
                          dataKey="value"
                        >
                          {data.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={Land[index % Land.length]}
                            />
                          ))}
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} lg={7} md={7}>
              <Box component={Paper} elevation={4}>
                <Subtitle leftTitle="Project Progress" />
                <List className="project_progress">
                  {projProg.length > 0 ? (
                    projProg.map((pr, index) => (
                      <ListItem>
                        <ListItemText sx={{ mr: 2 }}>
                          {" "}
                          {pr?.projDetail?.project_id}{" "}
                        </ListItemText>
                        <Box sx={{ width: "95%", display: "inline-block" }}>
                          <LinearProgress
                            variant="determinate"
                            color="primary"
                            value={
                              (Number(pr.completed_total) * 100) /
                              Number(pr.total_work)
                            }
                          />
                        </Box>
                        <Box
                          sx={{
                            width: "7%",
                            marginLeft: "15px",
                            display: "inline-block",
                            "text-align": "right",
                          }}
                        >
                          {Number(
                            (Number(pr.completed_total) * 100) /
                              Number(pr.total_work)
                          ).toFixed(2)}
                          %
                        </Box>
                      </ListItem>
                    ))
                  ) : (
                    <>
                      <Skeleton animation="wave" height={27} />
                      <Skeleton animation="wave" height={27} />
                      <Skeleton animation="wave" height={27} />
                      <Skeleton animation="wave" height={27} />
                      <Skeleton animation="wave" height={27} />
                      <Skeleton animation="wave" height={27} />
                      <Skeleton animation="wave" height={27} />
                    </>
                  )}
                </List>
              </Box>
            </Grid>
          </Grid>

          <Grid spacing={1} container>
            <Grid item xs={12}>
              <Box component={Paper} elevation={4}>
                <Subtitle
                  leftTitle="Daily Task Updates"
                  dateInput={
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        placeholder="Task Date"
                        inputFormat="DD/MM/YYYY"
                        value={dateVal}
                        onChange={(newValue) => {
                          setdateVal(moment(newValue).utc().format());
                          if (newValue !== "Invalid Date") {
                            fetchTasks(moment(newValue).format("YYYY-MM-DD"));
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            style={{
                              transform: "scale(0.9)",
                              transformOrigin: "left",
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  }
                  rftValue={
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={allTasks.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      labelRowsPerPage="Task per Page"
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      sx={{ minHeight: 0 }}
                    />
                  }
                  className="dash_box_daily_task"
                />

                <TableContainer
                  component={Paper}
                  style={{ maxHeight: "350px", overflow: "auto" }}
                >
                  <Table
                    aria-label="simple table"
                    size="small"
                    className="table-dashboard table-scrolling"
                    style={{ minWidth: "1180px", maxWidth: "1400px" }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell className="sizeSmall _font _fontColor">
                          <Checkbox
                            size="small"
                            checked={
                              allTasks?.length > 0 &&
                              checkedTask &&
                              allTasks.length === checkedTask?.length
                            }
                            onClick={(e) => {
                              handleCheckAll(e.target.checked);
                            }}
                          />
                        </TableCell>
                        <TableCell className="sizeSmall _fontColor">
                          S. No
                        </TableCell>
                        <TableCell className="sizeSmall _fontColor">
                          Project ID
                        </TableCell>
                        <TableCell className="sizeSmall _fontColor">
                          Project Name
                        </TableCell>
                        <TableCell className="sizeSmall _fontColor">
                          Activity Name
                        </TableCell>
                        <TableCell className="sizeSmall _fontColor">
                          Task Name
                        </TableCell>
                        <TableCell className="sizeSmall _fontColor">
                          UOM
                        </TableCell>
                        <TableCell className="sizeSmall _fontColor">
                          ETC Date
                        </TableCell>
                        {/* <TableCell className="sizeSmall _fontColor">
                          Completed Date
                        </TableCell> */}
                        <TableCell className="sizeSmall _fontColor">
                          Total/Completed
                        </TableCell>
                        <TableCell className="sizeSmall _fontColor">
                          Today’s Goal
                        </TableCell>
                        <TableCell className="sizeSmall _fontColor">
                          Today’s Work
                        </TableCell>
                        <TableCell className="sizeSmall _fontColor">
                          Remark
                        </TableCell>
                        <TableCell className="sizeSmall">
                          {checkedTask.length > 0 && (
                            <Button
                              variant="contained"
                              color="primary"
                              style={{ fontSize: "10px" }}
                              onClick={() => handleApproveBulk()}
                            >
                              Approve
                            </Button>
                          )}
                        </TableCell>
                        <TableCell className="sizeSmall">
                          {checkedTask.length > 0 && (
                            <Button
                              variant="contained"
                              color="error"
                              style={{ fontSize: "10px" }}
                              onClick={() => handleRejectBulk()}
                            >
                              Decline
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {loadingTask ? (
                      <>
                        <TableRow>
                          <TableCell colSpan={12}>
                            <Skeleton variant="rectangular" height={40} />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={12}>
                            <Skeleton variant="rectangular" height={40} />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={12}>
                            <Skeleton variant="rectangular" height={40} />
                          </TableCell>
                        </TableRow>
                      </>
                    ) : (
                      <TableBody>
                        {allTasks.length > 0 ? (
                          (rowsPerPage > 0
                            ? allTasks.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                            : allTasks
                          ).map((data, index) => (
                            <TableRow className="table_row">
                              <TableCell className="sizeSmall">
                                <Checkbox
                                  size="small"
                                  checked={
                                    checkedTask.find((el) => el.id == data.id)
                                      ? true
                                      : false
                                  }
                                  onClick={() => {
                                    handleAddtask(data);
                                  }}
                                />
                              </TableCell>
                              <TableCell
                                className="sizeSmall"
                                style={{ width: "50px" }}
                              >
                                {index + 1}
                              </TableCell>
                              <TableCell className="sizeSmall">
                                {data?.project?.project_id}
                              </TableCell>
                              <TableCell className="sizeSmall">
                                {data?.project?.project_name}
                              </TableCell>
                              <TableCell className="sizeSmall">
                                {data?.activity?.name}
                                Solar Execution
                              </TableCell>
                              <TableCell className="sizeSmall">
                                {data?.task?.name}
                              </TableCell>
                              <TableCell className="sizeSmall">
                                {data?.task?.uom}
                              </TableCell>
                              <TableCell className="sizeSmall">
                                {data?.projectTask?.etc_date
                                  ? moment(data.projectTask.etc_date).format(
                                      "DD/MM/YYYY"
                                    )
                                  : "-"}
                              </TableCell>
                              {/* <TableCell className="sizeSmall">
                            {data?.completed_date
                              ? moment(data.completed_date).format("DD/MM/YYYY")
                              : "-"}
                          </TableCell> */}
                              <TableCell className="sizeSmall _total_completed _dash">
                                <span
                                  style={{
                                    background: "#EDF7EE",
                                    position: "relative",
                                    overflow: "hidden",
                                  }}
                                >
                                  <span
                                    style={{
                                      position: "absolute",
                                      background: "#56c65b",
                                      height: "100%",
                                      padding: "0",

                                      width: `${
                                        (Number(
                                          data?.projectTask?.total_completed
                                        ).toFixed(0) *
                                          100) /
                                        Number(
                                          data?.projectTask.total_work_allocated
                                        ).toFixed(0)
                                      }%`,
                                    }}
                                  ></span>
                                  <span>
                                    {data?.projectTask.total_work_allocated}/
                                    <span>
                                      {data?.projectTask?.total_completed}
                                    </span>
                                  </span>
                                </span>
                              </TableCell>
                              <TableCell className="sizeSmall">
                                {data?.assigned_goal}
                              </TableCell>
                              <TableCell className="sizeSmall">
                                {data?.work_done}
                              </TableCell>
                              {data?.supervisor_remark ? (
                                <CustomWidthTooltip
                                  title={data?.supervisor_remark}
                                >
                                  <TableCell className="sizeSmall">
                                    View
                                  </TableCell>
                                </CustomWidthTooltip>
                              ) : (
                                <TableCell className="sizeSmall">-</TableCell>
                              )}
                              <TableCell className="sizeSmall">
                                {data?.work_status === "IN_PROGRESS" &&
                                  data?.isSubmitted == true && (
                                    <>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        style={{
                                          fontSize: "10px",
                                          maxWidth: "130px",
                                          minWidth: "100px",
                                        }}
                                        onClick={() =>
                                          handleApproveRej(data, "APPROVED")
                                        }
                                      >
                                        Approve
                                      </Button>
                                    </>
                                  )}
                              </TableCell>
                              <TableCell className="sizeSmall">
                                {data?.work_status === "APPROVED" && (
                                  <Button
                                    variant="contained"
                                    color="success"
                                    style={{
                                      fontSize: "10px",
                                      maxWidth: "130px",
                                      minWidth: "100px",
                                    }}
                                  >
                                    Approved
                                  </Button>
                                )}
                                {data?.work_status === "NO_WORK" && (
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    style={{
                                      fontSize: "10px",
                                      maxWidth: "130px",
                                      minWidth: "100px",
                                    }}
                                  >
                                    No Work
                                  </Button>
                                )}

                                {data?.work_status === "IN_PROGRESS" &&
                                  data?.isSubmitted == true && (
                                    <>
                                      <Button
                                        variant="contained"
                                        color="error"
                                        style={{
                                          fontSize: "10px",
                                          maxWidth: "130px",
                                          minWidth: "100px",
                                        }}
                                        onClick={() =>
                                          handleApproveRej(data, "REJECTED")
                                        }
                                      >
                                        Decline
                                      </Button>
                                    </>
                                  )}

                                {data?.work_status === "REJECTED" && (
                                  <>
                                    <Button
                                      variant="contained"
                                      color="error"
                                      style={{
                                        fontSize: "10px",
                                        maxWidth: "130px",
                                        minWidth: "100px",
                                      }}
                                    >
                                      Declined
                                    </Button>
                                  </>
                                )}

                                {data?.work_status === "IN_PROGRESS" &&
                                  data?.isSubmitted == false && (
                                    <>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        style={{
                                          fontSize: "10px",
                                          maxWidth: "130px",
                                          minWidth: "100px",
                                        }}
                                        onClick={() => console.log(data)}
                                      >
                                        Pending
                                      </Button>
                                    </>
                                  )}
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <>
                            <TableRow>
                              <TableCell
                                colSpan={12}
                                style={{
                                  textAlign: "center",
                                  fontWeight: "500",
                                  height: "100px",
                                  fontSize: "18px",
                                }}
                              >
                                No Data Available
                              </TableCell>
                            </TableRow>
                          </>
                        )}
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
          </Grid>

          <Footer />
        </main>
      </div>
      <Snackbar
        open={snack.open}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        onClose={() => {
          setSnack({
            ...snack,
            open: false,
            message: "",
          });
        }}
        message={snack.message}
      />
    </ThemeProvider>
  );
}
