import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Slide from "@mui/material/Slide";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Box, Grid, Snackbar } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import {
  updateActivity,
  addActivity,
  addtask,
  updateTask,
} from "../../redux/api";
import { useDispatch, useSelector } from "react-redux";
import {
  ADD_ACTIVITY,
  ADD_TASK,
  UPDATE_ACTIVITY,
  UPDATE_TASK,
  FILTER_TASK,
} from "../../redux/constants";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
});

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  Dialogtitle: {
    backgroundColor: "#1976d2",
    color: "#fff",
    "& > Button": {
      color: "#fff",
    },
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function Modal({
  open,
  setOpen,
  data,
  setSelectedAct,
  selectedAct,
  setSnack,
  snack,
}) {
  const dispatch = useDispatch();
  const activityList = useSelector((state) => state.master.activity);
  const handleClose = () => {
    setOpen(false);
    setTaskDetail({
      name: "",
      uom: "",
      activityId: "",
    });
  };
  const classes = useStyles();
  const [activity, setActivity] = useState("");

  const [activityName, setActivityName] = useState("");
  const [taskDetail, setTaskDetail] = useState({
    name: "",
    uom: "",
    activityId: "",
  });

  const [errorObj, setErrorObj] = useState({
    activityName: "",
    taskName: "",
    uom: "",
  });

  const handleTaskchange = (e) => {
    setTaskDetail({
      ...taskDetail,
      [e.target.name]: e.target.value.replace(
        /[0-9$&/+,:;=?@#|[\]\\'<>.^*()%!_-]/gi,
        ""
      ),
    });
  };

  const handleAddActivity = async () => {
    setActivityName(activityName.trim());

    if (!activityName.trim()) {
      setErrorObj({
        ...errorObj,
        activityName: "Activity name is required field",
      });
      return;
    }
    try {
      const { data } = await addActivity(activityName.trim());
      setSnack({
        ...snack,
        open: true,
        message: "Activity successfully added",
      });
      dispatch({ type: ADD_ACTIVITY, payload: data.data });
      setActivityName("");
      handleClose();
      setSelectedAct(data.data.id);
    } catch (error) {
      console.log("add activity error", error.message);
    }
  };

  const handleUpdateActivity = async () => {
    setActivityName(activityName.trim());

    if (!activityName.trim()) {
      setErrorObj({
        ...errorObj,
        activityName: "Activity name is required field",
      });
      return;
    }

    try {
      const updAct = await updateActivity(selectedAct, activityName.trim());
      setActivityName("");
      setSnack({
        ...snack,
        open: true,
        message: "Activity successfully updated",
      });
      dispatch({ type: UPDATE_ACTIVITY, payload: updAct.data.data });
      setSelectedAct(updAct.data.activityId);
      handleClose();
    } catch (error) {
      console.log("add activity error", error.message);
    }
  };

  const handleAddTask = async (actId) => {
    setTaskDetail({
      ...taskDetail,
      name: taskDetail.name.trim(),
      uom: taskDetail.uom.trim(),
    });

    if (!taskDetail.name.trim() || !taskDetail.uom.trim()) {
      if (!taskDetail.name.trim()) {
        setErrorObj({
          ...errorObj,
          taskName: "Task name is required field",
        });
      }
      if (!taskDetail.uom.trim()) {
        setErrorObj({
          ...errorObj,
          uom: "UOM is required field",
        });
      }
      return;
    }
    try {
      const { data } = await addtask(
        activity,
        taskDetail.name.trim(),
        taskDetail.uom.trim()
      );
      setSnack({
        ...snack,
        open: true,
        message: "Task successfully added",
      });
      dispatch({ type: ADD_TASK, payload: data.data });
      setSelectedAct(data.data.activityId);
      handleClose();
    } catch (error) {
      console.log(" update task error", error.message);
    }
  };
  const handleUpdateTask = async () => {
    setTaskDetail({
      ...taskDetail,
      name: taskDetail.name.trim(),
      uom: taskDetail.uom.trim(),
    });
    if (!taskDetail.name.trim() || !taskDetail.uom.trim()) {
      if (!taskDetail.name.trim()) {
        setErrorObj({
          ...errorObj,
          taskName: "Task name is required field",
        });
      }
      if (!taskDetail.uom.trim()) {
        setErrorObj({
          ...errorObj,
          uom: "UOM is required field",
        });
      }
      return;
    }

    try {
      const updTask = await updateTask(
        activity,
        data.task.id,
        taskDetail.name.trim(),
        taskDetail.uom.trim()
      );
      setSnack({
        ...snack,
        open: true,
        message: "Task successfully updated",
      });
      dispatch({ type: UPDATE_TASK, payload: updTask.data.data });
      setSelectedAct(activity);
      handleClose();
    } catch (error) {
      console.log(" update task error", error.message);
    }
  };

  const handleLoad = () => {
    setErrorObj({
      ...errorObj,
      activityName: "",
      taskName: "",
      uom: "",
    });

    if (data.for == "activity" && data.type == "edit") {
      setActivityName(data.activity.name);
    }
    if (data.for == "activity" && data.type == "add") {
      setActivityName("");
    }
    if (data.for == "task" && data.type == "add") {
      setActivity(data.activityId);
    }
    if (data.for == "task" && data.type == "edit") {
      setActivity(data.activityId);
      setTaskDetail({
        name: data.task.name,
        uom: data.task.uom,
        activityId: "",
      });
    }
  };

  useEffect(() => {
    handleLoad();
  }, [data]);

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle
          className={classes.Dialogtitle}
          id="customized-dialog-title"
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
        >
          {data.title}
        </DialogTitle>
        <DialogContent dividers>
          {data.for == "activity" && data.type == "add" && (
            <>
              <Box>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                    {/* <form className={classes.form} noValidate> */}
                    <TextField
                      variant="outlined"
                      margin="normal"
                      size="small"
                      required
                      fullWidth
                      id="active"
                      label="Active Name"
                      name="text"
                      autoComplete="text"
                      autoFocus
                      value={activityName}
                      onChange={(e) => {
                        setActivityName(
                          e.target.value.replace(
                            /[0-9$&/+,:;=?@#|[\]\\'<>.^*()%!_-]/gi,
                            ""
                          )
                        );
                      }}
                    />
                    {errorObj.activityName && (
                      <p className="errorText"> {errorObj.activityName}</p>
                    )}
                    <Button
                      type="submit"
                      size="small"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={handleAddActivity}
                    >
                      Create
                    </Button>
                    {/* </form> */}
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
          {data.for == "activity" && data.type == "edit" && (
            <>
              <Box>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  style={{ textAlign: "center" }}
                >
                  <Grid item xs={12} md={12}>
                    {/* <form className={classes.form} noValidate> */}
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="active"
                      label="Active Name"
                      name="text"
                      size="small"
                      autoComplete="text"
                      autoFocus
                      value={activityName}
                      onChange={(e) => {
                        setActivityName(
                          e.target.value.replace(
                            /[0-9$&/+,:;=?@#|[\]\\'<>.^*()%!_-]/gi,
                            ""
                          )
                        );
                        //   if(e.target.value.replace(/[0-9$&/+,:;=?@#|[\]\\'<>.^*()%!_-]/gi, "")){
                        //     setErrorObj({
                        //       ...errorObj,
                        //       activityName:""
                        //     })
                        // }
                      }}
                    />
                    {errorObj.activityName && (
                      <p className="errorText">{errorObj.activityName}</p>
                    )}
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={handleUpdateActivity}
                    >
                      Update
                    </Button>
                    {/* </form> */}
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
          {data.for == "task" && data.type == "edit" && (
            <>
              <Box>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12} md={12}>
                    {/* <form className={classes.form} noValidate> */}
                    <FormControl
                      variant="filled"
                      className={classes.formControl}
                      style={{ width: "100%" }}
                    >
                      <InputLabel id="demo-simple-select-filled-label">
                        Activity
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={activity}
                        onChange={(id) => setActivity(id)}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {activityList.map((act, index) => (
                          <MenuItem
                            key={`edit-activity-${act.id}`}
                            value={act.id}
                          >
                            {act.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="active"
                      label="Task Name"
                      name="name"
                      autoComplete="text"
                      size="small"
                      // autoFocus
                      value={taskDetail.name}
                      onChange={handleTaskchange}
                    />
                    {errorObj.taskName && (
                      <p className="errorText">{errorObj.taskName}</p>
                    )}
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="active"
                      label="Enter unit"
                      name="uom"
                      autoComplete="text"
                      size="small"
                      // autoFocus
                      value={taskDetail.uom}
                      onChange={handleTaskchange}
                    />
                    {errorObj.uom && (
                      <p className="errorText">{errorObj.uom}</p>
                    )}

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={() => handleUpdateTask()}
                    >
                      Update
                    </Button>
                    {/* </form> */}
                  </Grid>
                </Grid>
              </Box>
            </>
          )}

          {data.for == "task" && data.type == "add" && (
            <>
              <Box>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12} md={12}>
                    {/* <form className={classes.form} noValidate> */}
                    <FormControl
                      variant="filled"
                      className={classes.formControl}
                      style={{ width: "100%" }}
                    >
                      <InputLabel id="demo-simple-select-filled-label">
                        Activity
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={activity}
                        onChange={(e) => setActivity(e.target.value)}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>

                        {activityList.map((act, index) => (
                          <MenuItem
                            key={`add-activity-${act.id}`}
                            value={act.id}
                          >
                            {act.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="active"
                      label="Task Name"
                      name="name"
                      size="small"
                      autoComplete="text"
                      // autoFocus
                      value={taskDetail.name}
                      onChange={handleTaskchange}
                    />
                    {errorObj.taskName && (
                      <p className="errorText">{errorObj.taskName}</p>
                    )}
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="active"
                      label="Enter unit"
                      name="uom"
                      size="small"
                      autoComplete="text"
                      value={taskDetail.uom}
                      onChange={handleTaskchange}
                      // autoFocus
                    />
                    {errorObj.uom && (
                      <p className="errorText">{errorObj.uom}</p>
                    )}

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={() => handleAddTask()}
                    >
                      Create
                    </Button>
                    {/* </form> */}
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Create
          </Button>
        </DialogActions> */}
      </Dialog>
    </>
  );
}
