import * as React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@mui/material/Link';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Header from '../../Component/Header/Header';
import PageTitle from '../../Component/PageTitle/PageTitle';
import { Button,Grid,Paper,Box } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {DangerBtn,SaccessBtn,PrimaryBtn,NeutralBtn,ListingBar} from '../../Pages/styles'
import {useStyles,btnStyles} from '../TableStyle'
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';

import Subtitle from '../../Component/PageTitle/Subtitle';


import { PieChart, Pie, Sector, Cell, ResponsiveContainer} from "recharts";
import Footer from '../Footer/Footer';


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
  }));

  const data = [
            { name: "Group A", value: 200 },
            { name: "Group B", value: 300 },
  ];
  const Land = ['#0088FE', '#FFBB28', '#FF8042'];
  const SHS = ['#FFBB28', '#0088FE', '#FF8042'];
  const Solar = ['#FFBB28',  '#FF8042','#0088FE'];


const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#ffffff',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    }
  }
  });

const number = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15, 16,17,18,19,20]
function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

export default function ProjectSummary() {
    const classes = useStyles();
    const [value, setValue] = React.useState();

  const handleChange = (newValue) => {
    setValue(newValue);
  }


  return (
    <ThemeProvider  theme={theme}>
      
    <div className={classes.root}>
      <CssBaseline />
      <Header/>
      <main className={classes.content}>
     
        <div className={classes.toolbar} />
        <Breadcrumbs aria-label="breadcrumb">
      <Link color="inherit" href="/" onClick={handleClick}>
        Dashboard
      </Link>
      <Typography color="textPrimary">Project Summary</Typography>
    </Breadcrumbs>
    <Box display="flex" 
        alignItems="center"
        justifyContent="space-between" style={{'width':'100%'}}>
       <PageTitle title="Project Summary"/>

       <Box sx={{'display':'flex','alignItems':'center',width:'50%'}}>
        <Box classes={classes.root} sx={{'display':'flex','alignItems':'center','justify-content':'space-between',width:'100%'}}>
        <Typography display="block" style={{'mr':2}}> SELECT RANGE </Typography>
        <LocalizationProvider dateAdapter={AdapterMoment}  >
      <DatePicker
        minDate={moment(new Date())}
        name="start_date"
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        renderInput={(params) => <TextField name='start_date'  size='small' className={classes.textField}   {...params} />}
      />

<DatePicker
minDate={moment(new Date())}
name="start_date"
value={value}
onChange={(newValue) => {
setValue(newValue);
}}
renderInput={(params) => <TextField name='start_date'  size='small' className={classes.textField}  {...params} />}
/>
      
    </LocalizationProvider>
    <Button variant="contained" disableElevation>Go</Button>
        </Box>
        </Box>
    </Box>
    
            <Grid container rowSpacing={1} spacing={2}>
            <Grid item xs={12} className={classes.boxes}> 
              <Box component={Paper}>
                  <ListingBar>
                      <ListItem>
                          <Typography variant="subtitle1" gutterBottom component="div">PROJECT ID</Typography>
                          <Typography variant="subtitle1" gutterBottom component="div">SEPL009</Typography>
                      </ListItem>
                      <ListItem>
                          <Typography variant="subtitle1" gutterBottom component="div">PROJECT NAME</Typography>
                          <Typography variant="subtitle1" gutterBottom component="div">ESSL Kaudgaon</Typography>
                      </ListItem>
                      <ListItem>
                          <Typography variant="subtitle1" gutterBottom component="div">CLIENT NAME</Typography>
                          <Typography variant="subtitle1" gutterBottom component="div">Bhaskar Jyoti India Pvt Ltd</Typography>
                      </ListItem>
                      <ListItem>
                          <Typography variant="subtitle1" gutterBottom component="div">START DATE</Typography>
                          <Typography variant="subtitle1" gutterBottom component="div">01/06/2022</Typography>
                      </ListItem>
                      <ListItem>
                          <Typography variant="subtitle1" gutterBottom component="div">PROJECT STATUS</Typography>
                          <Typography variant="subtitle1" gutterBottom component="div">In Progress</Typography>
                      </ListItem>
                      <ListItem>
                          <Typography variant="subtitle1" gutterBottom component="div" style={{width:'150px'}}>
                          <BorderLinearProgress variant="determinate" value={50} />
                          </Typography>
                          
                      </ListItem>
                      
                  </ListingBar>
              </Box>
            </Grid>
             <Grid item xs={12} md={3} className={classes.boxes}> 
        <Box component={Paper} height={300} sx={{'display':'block','mb':3}} elevation={4}>
                <Subtitle
                 leftTitle="Land Development"
                 rftValue={`${data[0].value}`}
                />
            <ResponsiveContainer width="100%" height="100%">
                        <PieChart>
                <Pie
                    data={data}
                    cx={160}
                    cy={100}
                    innerRadius={60}
                    outerRadius={80}
                    fill="#8884d8"
                    paddingAngle={0}
                    dataKey="value"
                >
                    {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={Land[index % Land.length]} />
                    ))}
                </Pie>
                </PieChart>  
            </ResponsiveContainer>
         </Box>
         <Box component={Paper}  height={300} sx={{'display':'block','mb':3}} elevation={4}>

         <Subtitle
                 leftTitle="Off-Grid SHS"
                 rftValue={`${data[1].value}`}
                /> 
            <ResponsiveContainer width="100%" height="100%">
                        <PieChart>
                <Pie height={400}
                    data={data}
                    cx={160}
                    cy={100}
                    innerRadius={60}
                    outerRadius={80}
                    fill="#8884d8"
                    paddingAngle={0}
                    dataKey="value"
                >
                    {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={SHS[index % SHS.length]} />
                    ))}
                </Pie>
                </PieChart>  
            </ResponsiveContainer>
         </Box>

         <Box component={Paper}  height={300} sx={{'display':'block','mb':3}} elevation={4}>

         <Subtitle
                 leftTitle="Solar Execution"
                 rftValue={`${data[0].value}`}
                /> 
         
            <ResponsiveContainer width="100%" height="100%">
                        <PieChart>
                <Pie height={400}
                    data={data}
                    cx={160}
                    cy={100}
                    innerRadius={60}
                    outerRadius={80}
                    fill="#ff0000"
                    paddingAngle={0}
                    dataKey="value"
                >
                    {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={Solar[index % Solar.length]} />
                    ))}
                </Pie>
                </PieChart>  
            </ResponsiveContainer>
         </Box>

            
             </Grid> 
              <Grid item xs={12} md={9} className={classes.boxes}>

              <Table  className={classes.tableCellClasses} sx={{'background':'white'}}>
                    <TableHead>

                    <TableRow>
                        <TableCell className={classes.tableHeaderCell}  sx={{fontSize:'12px',paddingTop:1}}>Date</TableCell>
                        <TableCell className={classes.tableHeaderCell}  sx={{fontSize:'12px', padding:1}}>Activity Name</TableCell>
                        <TableCell className={classes.tableHeaderCell}  sx={{fontSize:'12px',padding:1}}>Task Name</TableCell>
                        <TableCell className={classes.tableHeaderCell}  sx={{fontSize:'12px',padding:1}}>UOM</TableCell>
                        <TableCell className={classes.tableHeaderCell}  sx={{fontSize:'12px',padding:1}}>Total Work</TableCell>
                        <TableCell className={classes.tableHeaderCell}  sx={{fontSize:'12px',padding:1}}>Completed work</TableCell>
                        <TableCell className={classes.tableHeaderCell}  sx={{fontSize:'12px',padding:1}}>Today’s Goal</TableCell>
                        <TableCell className={classes.tableHeaderCell}  sx={{fontSize:'12px',padding:1}}>Work Done</TableCell>
                        <TableCell className={classes.tableHeaderCell}  sx={{fontSize:'12px',padding:1}}>ETC Date</TableCell>
                        <TableCell className={classes.tableHeaderCell}  sx={{fontSize:'12px',padding:1}}>Task Progress </TableCell>
                      
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    <TableRow >
                         <TableCell rowSpan={5}>01/06/2022 </TableCell>
                                   
                                    <TableCell colSpan={0}>Land Development</TableCell>
                                    <TableCell>Pits Filling</TableCell>
                                    <TableCell>CuFt</TableCell>
                                    <TableCell>500</TableCell>
                                    <TableCell>500</TableCell>
                                    <TableCell>100</TableCell>
                                    <TableCell>100</TableCell>
                                    <TableCell>12/06/2022</TableCell>
                                    <TableCell><BorderLinearProgress variant="determinate" value={50} /></TableCell>
                        </TableRow>
                        {
                            [1,2,3,4].map(data=>{
                                return (
                            <TableRow>                                   
                           
                                   
                           <TableCell colSpan={0}>Land Development</TableCell>
                                    <TableCell>Pits Filling</TableCell>
                                    <TableCell>CuFt</TableCell>
                                    <TableCell>500</TableCell>
                                    <TableCell>500</TableCell>
                                    <TableCell>100</TableCell>
                                    <TableCell>100</TableCell>
                                    <TableCell>12/06/2022</TableCell>
                                    <TableCell>
                                    <BorderLinearProgress variant="determinate" value={50} />

                                    </TableCell>
                       
                            </TableRow>
                                )
                            })
                        }


<TableRow >
                         <TableCell rowSpan={10}>01/06/2022 </TableCell>
                                   
                                    <TableCell colSpan={0}>Activity Name</TableCell>
                                    <TableCell>Task Name</TableCell>
                                    <TableCell>UOM</TableCell>
                                    <TableCell>Total Work</TableCell>
                                    <TableCell>Completed work</TableCell>
                                    <TableCell>Today’s Goal</TableCell>
                                    <TableCell>Work Done</TableCell>
                                    <TableCell>ETC Date</TableCell>
                                    <TableCell>
                                        <BorderLinearProgress variant="determinate" value={80} />
                                    </TableCell>
                        </TableRow>


                         {
                            [1,2,3,4,5,6,7,8,9].map(data=>{
                                return (
                            <TableRow>                                   
                           
                                   
                                   <TableCell>Activity Name</TableCell>
                                   <TableCell>Task Name</TableCell>
                                   <TableCell>UOM</TableCell>
                                   <TableCell>Total Work</TableCell>
                                   <TableCell>Completed work</TableCell>
                                   <TableCell>Today’s Goal</TableCell>
                                   <TableCell>Work Done</TableCell>
                                   <TableCell>ETC Date</TableCell>
                                   <TableCell><BorderLinearProgress variant="determinate" value={90} /></TableCell>
                       
                            </TableRow>
                                )
                            })
                        }
                        
                        
                        {
                            [1,2,3,4,5,6,7,8,9].map(data=>{
                                return (
                            <TableRow>                                   
                           
                           <TableCell>Date</TableCell>
                                   <TableCell>Activity Name</TableCell>
                                   <TableCell>Task Name</TableCell>
                                   <TableCell>UOM</TableCell>
                                   <TableCell>Total Work</TableCell>
                                   <TableCell>Completed work</TableCell>
                                   <TableCell>Today’s Goal</TableCell>
                                   <TableCell>Work Done</TableCell>
                                   <TableCell>ETC Date</TableCell>
                                   <TableCell><BorderLinearProgress variant="determinate" value={40} /></TableCell>
                       
                            </TableRow>
                                )
                            })
                        }
                        
                       
                         
                                                   
                                
                    </TableBody>
                </Table>
              </Grid>
             </Grid>
             <Footer/> 
      </main>
    </div>
    </ThemeProvider>
  );
}
