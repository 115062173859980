import React from "react";
import Box from "@mui/material/Box";
import { Avatar, Typography, Button } from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import styled from "styled-components";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import Image from "../assets/images/illustration_404.svg";

export default function Notfound() {
  return (
    <div style={{ background: "#F4F6F8" }}>
      <Grid
        container
        spacing={4}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "105vh", background: "#F4F6F8" }}
      >
        <Grid item xs={8} md={8}>
          <Box
            style={{
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              minWidth: "40rem",
            }}
          >
            <Box elevation={0} sx={{ p: 5 }}>
              <AvtarImg>
                <img src={Image} />
              </AvtarImg>
              <Heading>404: The page you are looking for isn’t here</Heading>
              <Typography variant="p">
                You either tried some shady route or you came here by mistake.
                Whichever it is, try using the navigation.
              </Typography>
            </Box>
            <Button variant="contained" size="large" sx={{ mr: 2 }}>
              <ArrowLeftIcon /> Go to Dashboard
            </Button>
            <Button variant="outlined" size="large">
              Login
            </Button>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

const AvtarImg = styled.div`
  width: 300px;
  margin: 0 auto;
  img {
    width: 100%;
  }
`;
const Heading = styled.h1`
  font-size: 2rem;
  margin-bottom: 3px;
  margin-top: 12px;
  font-weight: bold;
`;
