import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { TextField, Grid } from "@mui/material";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import Avatar from "@material-ui/core/Avatar";
import logo from "../../assets/images/protrac+blue.svg";
import Slinko from "../../assets/images/slnko+white.svg";
import apmLogo from "../../assets/images/apm.png";
import alobhaLogoFlag from "../../assets/images/flag-alobha-logo-12.png";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { NavLink } from "react-router-dom";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import GroupIcon from "@material-ui/icons/Group";
import LayersIcon from "@material-ui/icons/Layers";
import "../../Pages/dashboard/Dashboard.scss";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { logOut, changePass } from "../../redux/api";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Snackbar } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import LockIcon from "@mui/icons-material/Lock";
import LogoutIcon from "@mui/icons-material/Logout";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputLabel from "@mui/material/InputLabel";
import CloseIcon from "@mui/icons-material/Close";
const drawerWidth = 220;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    // marginTop:'15px',
    // borderRadius:'8px',
    width: `calc(100% - ${theme.spacing(14) + 1}px)`,
    backgroundImage: "white",
    color: "#151c30",
    [theme.breakpoints.down("sm")]: {
      width: `calc(100% - ${theme.spacing(12) + 1}px)`,
    },
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    background: "#193051",
    padding: "0 10px",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
      overflowL: "#ed00ff",
    }),
  },
  drawerClose: {
    background: "#193051",
    width: theme.spacing(14) + 1,
    paddingLeft: theme.spacing(2),
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    width: theme.spacing(12) + 1,
    overflow: "hidden",
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(14),
      padding: "0 12px",
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    [theme.breakpoints.up("sm")]: {
      justifyContent: "center",
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  profilesTxt: {
    display: "flex",
    textAlign: "right",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  imgLogo: {
    width: "90px",
    overflow: "inherit",
    margin: "0 auto",
    fill: "#1F487C",
  },
  imgAccount: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    width: "auto",
  },
  imgAccountLogo: {
    width: `calc(100% - 200px)`,
    display: "flex",
    alignItems: "center",
  },
  slogo: {
    width: "100px",
    margin: "13px auto",
  },
  arrow: {
    color: "white",
  },
  maxWidth: {
    minWidth: "80px",
    marginRight: "20px",
  },
  minWidth: {
    minWidth: "63px",
  },
}));

const sidebarTheme = createTheme({
  overrides: {
    MuiListItem: {
      root: {
        color: "#ffffff80",
        fontSize: "0.7rem",
        fontWeight: "normal",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.1) !important",
          borderRadius: "60px",
          color: "#65cfea",
        },
        "&.Mui-selected": {
          borderRadius: "60px",
          boxShadow:
            "0 .18rem .1rem rgba(25 46 79,0.8),0 .126rem 1rem rgba(122,123,151,.1)",
          color: "#65cfea",
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.1) !important",
            borderRadius: "60px",
            "& div > svg": {
              color: "#65cfea",
            },
          },
          "& div > svg": {
            color: "#65cfea",
          },
        },
      },
      text: {
        color: "#ffffff80",
      },
    },
    MuiListItemIcon: {
      root: {
        color: "#ffffff80",
        justifyContent: "center",
        "&:hover": {
          color: "#65cfea",
        },
      },
    },
  },
});

export default function Header({
  setDashboardSnack,
  dashboardSnack,
  addProjSnack,
  setAddProjSnack,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [snack, setSnack] = useState({ open: false, message: "" });
  const [openlogin, setOpenlogin] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState();
  const [anchorChangePass, setAnchorChangePass] = React.useState(null);
  const [showPass, setShowPass] = useState(false);
  const [passwordVal, setPasswordVal] = useState({
    currentPass: "",
    newPass: "",
  });
  const preventDefault = (event) => event.preventDefault();
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorChangePass(event.currentTarget);
  };
  const openChangePass = Boolean(anchorChangePass);
  const id = open ? "simple-popover" : undefined;

  const handleCloseChangePass = () => {
    setAnchorChangePass(null);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/");
    } catch (error) {
      console.log("logout error");
    }
  };
  const handleChangePass = async () => {
    try {
      if (!passwordVal.currentPass) {
        throw new Error("Please enter current password");
      }
      if (!passwordVal.newPass) {
        throw new Error("Please enter new password");
      }
      await changePass(passwordVal.currentPass, passwordVal.newPass);
      // setSnack({
      //   ...snack,
      //   open: true,
      //   message: "Password changed successfully"
      // });
      setDashboardSnack({
        ...dashboardSnack,
        open: true,
        message: "Password changed successfully",
      });
      setOpenlogin(false);
    } catch (error) {
      setSnack({
        ...snack,
        open: true,
        message:
          error?.response?.data?.message ||
          error?.message ||
          "Something went wrong, please try again later",
      });
      console.log(error.message);
    }
  };

  useEffect(() => {
    console.log(addProjSnack);
    if (addProjSnack?.open) {
      setSnack({
        ...snack,
        open: true,
        message: "Project added successfully",
      });
    }
  }, []);
  const handleloginOpen = () => {
    handleCloseChangePass();
    setOpenlogin(true);
  };

  const handleloginClose = () => {
    setOpenlogin(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <AppBar
        position="fixed"
        color="info"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}></Typography>

          <div className={classes.profilesTxt}>
            <Box component="div" className={classes.imgAccountLogo}>
              <Avatar
                alt="logo"
                src={alobhaLogoFlag}
                width="97"
                className={classes.imgLogo}
                style={{width:'114px'}}
              />
            </Box>
            {/* <Popover
              id={id}
              open={openChangePass}
              anchorEl={anchorChangePass}
              onClose={handleCloseChangePass}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Box component="div">
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignIten="center"
                >
                  <Grid item>
                    <Button
                      color="inherit"
                      variant="contained"
                      size="small"
                      onClick={handleloginOpen}
                    >
                      Change Password
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Popover> */}
            <Paper>
              <Menu
                id={id}
                open={openChangePass}
                anchorEl={anchorChangePass}
                onClose={handleCloseChangePass}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <MenuItem onClick={handleloginOpen} dense>
                  <LockIcon sx={{ mr: 1, fontSize: 16 }} /> Change Password
                </MenuItem>
                <Divider />
                <MenuItem dense onClick={() => handleLogout()}>
                  {" "}
                  <LogoutIcon sx={{ mr: 1, fontSize: 16 }} /> Logout
                </MenuItem>
              </Menu>
            </Paper>

            <Box component="div" className={classes.imgAccount}>
              <Typography className="account_name">Project Admin</Typography>
              <Button color="inherit" size="small">
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={handleClick}
                >
                  <AccountCircle fontSize="large" />
                </IconButton>
              </Button>
            </Box>
          </div>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Drawer
        variant="permanent"
        open={true}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <a href="/dashboard">
            <img src={apmLogo} alt className={classes.slogo} />
          </a>
          <IconButton
            onClick={handleDrawerClose}
            style={{ color: "#fff", position: "absolute", right: 0 }}
            className={{ [classes.hide]: !open }}
          >
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <ThemeProvider theme={sidebarTheme}>
          <List className="activelink">
            <NavLink to="/dashboard">
              <ListItem
                disableGutters
                button
                className={classes.link}
                selected={selectedIndex === 0}
                // onClick={(event) => handleListItemClick(event, 0)}
              >
                <ListItemIcon
                  disableGutters
                  className={{
                    [classes.minWidth]: open,
                    [classes.maxWidth]: !open,
                  }}
                >
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItem>
            </NavLink>
            <NavLink to="/general-master">
              <ListItem
                disableGutters
                button
                selected={selectedIndex === 1}
                // onClick={(event) => handleListItemClick(event, 1)}
              >
                <ListItemIcon
                  className={{
                    [classes.minWidth]: open,
                    [classes.maxWidth]: !open,
                  }}
                >
                  <AcUnitIcon />
                </ListItemIcon>
                <ListItemText primary="General Master" />
              </ListItem>
            </NavLink>

            <NavLink to="/project-management">
              <ListItem
                disableGutters
                button
                selected={selectedIndex === 2}
                // onClick={(event) => handleListItemClick(event, 2)}
              >
                <ListItemIcon
                  className={{
                    [classes.minWidth]: open,
                    [classes.maxWidth]: !open,
                  }}
                >
                  <LayersIcon />
                </ListItemIcon>
                <ListItemText primary="Project Management" />
              </ListItem>
            </NavLink>

            <NavLink to="/reportview">
              <ListItem
                disableGutters
                button
                selected={selectedIndex === 3}
                // onClick={(event) => handleListItemClick(event, 3)}
              >
                <ListItemIcon
                  className={{
                    [classes.minWidth]: open,
                    [classes.maxWidth]: !open,
                  }}
                >
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText primary="Reports" />
              </ListItem>
            </NavLink>
          </List>

          <Dialog
            open={openlogin}
            onClose={handleloginClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ justifyContent: "space-between", display: "flex" }}
            >
              {"Change Password"}
              <IconButton onClick={handleloginClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box component="div" style={{ paddingBottom: "30px" }}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Current Password"
                      type="password"
                      id="password"
                      size="small"
                      onChange={(e) => {
                        setPasswordVal({
                          ...passwordVal,
                          currentPass: e.target.value,
                        });
                      }}
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="New Password"
                      type="password"
                      id="password"
                      size="small"
                      onChange={(e) => {
                        setPasswordVal({
                          ...passwordVal,
                          newPass: e.target.value,
                        });
                      }}
                    />

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      style={{ marginTop: "15px" }}
                      onClick={() => {
                        handleChangePass();
                      }}
                    >
                      Submit
                    </Button>
                  </Box>
                </Box>
                <Snackbar
                  open={snack.open}
                  autoHideDuration={3000}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  onClose={() => {
                    setSnack({
                      ...snack,
                      open: false,
                      message: "",
                    });
                  }}
                  message={snack.message}
                />
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </ThemeProvider>
      </Drawer>
    </div>
  );
}
