import React, { useState, useEffect } from "react";
import { Divider, Toolbar, Tooltip } from "@mui/material";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ListItemButton from "@mui/material/ListItemButton";
import styled from "styled-components";
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  leftlist: {
    height: "",
  },
}));

export default function Projectdetailsleft({
  proj,
  setSelectedAct,
  selectedAct,
  toggleOpen,
  setAddAct,
  addAct,
}) {
  const classes = useStyles();

  useEffect(() => {}, []);

  return (
    <>
      <LeftSideBar elevation={4} className={classes.leftlist}>
        <Typography
          variant="h6"
          p={2}
          style={{ color: "#3c44b1" }}
          component="h6"
        >
          Activity Name
        </Typography>
        <List disablePadding className="listing">
          <Divider />
          {toggleOpen == 4 && (
            <ListItem disablePadding className={addAct == 0 ? "active" : ""}>
              <ListItemButton
                onClick={() => {
                  setAddAct(0);
                }}
              >
                All
              </ListItemButton>
            </ListItem>
          )}
          {toggleOpen !== 4 && (
            <ListItem
              disablePadding
              className={selectedAct == 0 ? "active" : ""}
            >
              <ListItemButton
                onClick={() => {
                  setSelectedAct(0);
                }}
              >
                All
              </ListItemButton>
            </ListItem>
          )}
          {toggleOpen == 4
            ? proj?.add?.map((act) => (
                <>
                  {act?.detail && (
                    <>
                      <ListItem
                        disablePadding
                        className={act?.activity_id == addAct ? "active" : ""}
                      >
                        <Tooltip title={act?.detail?.name}>
                          <ListItemButton
                            onClick={() => {
                              setAddAct(act?.activity_id);
                            }}
                          >
                            {act?.detail?.name}
                          </ListItemButton>
                        </Tooltip>
                      </ListItem>
                      <Divider />
                    </>
                  )}
                </>
              ))
            : proj?.activity?.map((act) => (
                <>
                  <ListItem
                    disablePadding
                    className={selectedAct == act?.activity_id ? "active" : ""}
                  >
                    <Tooltip title={act?.detail?.name}>
                      <ListItemButton
                        onClick={() => setSelectedAct(act?.activity_id)}
                      >
                        {act?.detail?.name}
                      </ListItemButton>
                    </Tooltip>
                  </ListItem>
                  <Divider />
                </>
              ))}

          {/* {
          proj?.activity?.map(act=>(
            <>
            <ListItem disablePadding className={selectedAct==act?.activity_id?"active":""} >
              <ListItemButton  onClick={()=>setSelectedAct(act?.activity_id)} >
                {
                  act?.detail?.name
                }
              
              </ListItemButton>
               </ListItem>
            <Divider/>
            </>
            
          ))
        } */}
          {/* <ListItem disablePadding>
            <ListItemButton>
              Off-Grid SHS
              </ListItemButton>
               </ListItem>
            <Divider/>
            <ListItem disablePadding className='active'> 
            <ListItemButton>
            Solar Execution 
            </ListItemButton>
            </ListItem>
            <Divider/> */}
        </List>
      </LeftSideBar>
    </>
  );
}
const LeftSideBar = styled(Paper)`
  box-shadow: 0 0.46875rem 2.1875rem rgba(60, 68, 177, 0.03),
    0 0.9375rem 1.40625rem rgba(60, 68, 177, 0.03),
    0 0.25rem 0.53125rem rgba(60, 68, 177, 0.05),
    0 0.125rem 0.1875rem rgba(60, 68, 177, 0.03) !important;
  .listing {
    background: #fff;
    border-right: 1px solid #ddd;
    li {
      &.active {
        background-color: #a2a6dadb;
        box-shadow: 0 0.18rem 0.3rem rgba(166, 166, 185, 0.5),
          0 0.326rem 3rem rgba(122, 123, 151, 0.3) !important;
      }
      padding: 0;
      div {
        padding: 10.9px;
        font-weight: 500;
        color: #3c44b1;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: block;
        font-size: 0.83rem;
      }
    }
  }
`;
