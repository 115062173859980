import defaultTheme from "./default";

// import { createMuiTheme } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const overrides = {
  typography: {
    h1: {
      fontSize: "3rem",
    },
    h2: {
      fontSize: "2rem",
    },
    h3: {
      fontSize: "1.64rem",
    },
    h4: {
      fontSize: "1.5rem",
    },
    h5: {
      fontSize: "1.285rem",
    },
    h6: {
      fontSize: "0.842rem",
    },
  },
};

const Themes = {
  default: createTheme({ ...defaultTheme, ...overrides }),
};

export default Themes;
