import styled from "styled-components";
import Button from "@mui/material/Button";
import List from "@mui/material/List";

export const DangerBtn = styled(Button)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0.25rem 0.55rem rgb(248 50 69 / 35%);
  color: #fff;
  background-color: #d32f2f;
  border-color: #d32f2f;
  &:hover {
    color: #fff;
    background-color: #d32f2f;
    border-color: #d32f2f;
  }
`;

export const SaccessBtn = styled(Button)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #1bc943;
  border-color: #1bc943;
  box-shadow: 0 0.25rem 0.55rem rgb(27 201 67 / 35%);
  &:hover {
    color: #fff;
    background-color: #1bc943;
    border-color: #1bc943;
  }
`;

export const PrimaryBtn = styled(Button)`
  padding: 0;
  min-width: 42px;
  height: 40px;
  line-height: 36px;
  text-align: center;
  border-radius: 0.2rem !important;
  margin: 0 0.28571rem;
  transition: all 0.2s ease-in-out;
  position: relative;
  font-weight: 700;
  // color: #3b3e66;
  background-color: #fff;
  border: 1px solid #e6e7f1;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0.313rem 0.8rem rgb(60 68 177 / 50%),
    0 0.126rem 0.225rem rgb(60 68 177 / 30%);
  background: #3c44b1;
  color: #fff;
  border-color: #3c44b1;
  cursor: pointer;
  &:hover {
    color: #fff;
    background: #3c44b1;
    border-color: #3c44b1;
  }
`;
export const NeutralBtn = styled(Button)`
display: inline-flex;
align-items: center;
justify-content: center;
align-items: center;
justify-content: center;
background: rgba(122,123,151,.15);
box-shadow: 0 0.25rem 0.55rem rgb(203 206 233 / 69%);
color: #3b3e66;
cursor: pointer
    &:hover{
        color: #fff;
        background: #3c44b1;
        border-color: #3c44b1;
    }
`;

export const DarkBtn = styled(Button)`
display: inline-flex;
align-items: center;
justify-content: center;
align-items: center;
justify-content: center;
color: #fff;
background-color: #070919;
border-color: #070919;
box-shadow: 0 0.25rem 0.55rem rgb(7 9 25 / 35%);
cursor: pointer
    &:hover{
        color: #fff;
        background-color: #070919;
        border-color: #070919;
    }
`;

export const ListingBar = styled(List)`
  display: flex;
  justify-content: space-between;
  li {
    width: auto;
    display: flex;
    justify-content: space-between;

    div {
      font-size: 14px;
      text-transform: capitalize;
    }
    div:first-child {
      color: #3c44b1;
      font-weight: 700;
      margin-right: 1.5rem;
    }
    div:last-child {
      margin-right: 0rem;
    }
  }
`;
