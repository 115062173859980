import * as api from "../api/index";
import { GET_PROJECT_LIST } from "../constants";

export const projectListAction = () => async (dispatch) => {
  try {
    const { data } = await api.getProjectList();
    dispatch({ type: GET_PROJECT_LIST, payload: data.data });
  } catch (error) {
    console.log("error in fetching project list", error.message);
  }
};
