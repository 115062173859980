import React, { useState, useEffect, useMemo } from "react";
import { Box, Grid, Paper } from "@material-ui/core";
import Button from "@mui/material/Button";
import { Divider, Toolbar, Tooltip } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useStyles, btnStyles } from "../TableStyle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@mui/material/Checkbox";
import { TextField, Snackbar } from "@mui/material";
import {
  DangerBtn,
  SaccessBtn,
  PrimaryBtn,
  NeutralBtn,
} from "../../Pages/styles";
import ButtonGroup from "@mui/material/ButtonGroup";
import "../../Pages/dashboard/Dashboard.scss";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import Pagination from "./Pagination";
import { useParams } from "react-router-dom";
import { approveBulk, rejectBulk, accRejSingle } from "../../redux/api/index";
import { useSelector } from "react-redux";

const ApproveTask = ({
  projrectDetail,
  setProjrectDetail,
  selectedAct,
  setSelectedAct,
  curAct,
  getprojectDetail,
}) => {
  const classes = useStyles();
  const [checkedTask, setcheckedTask] = useState([]);
  const [snack, setSnack] = useState({ open: false, message: "" });
  const param = useParams();
  const approveT = useSelector((state) => state.project.currentProject);
  const handleCheck = (val) => {
    const isAdded = checkedTask.find((el) => el.id == val.id);
    if (isAdded) {
      const filtCk = checkedTask.filter((el) => el.id != val.id);
      setcheckedTask(filtCk);
    } else {
      setcheckedTask([...checkedTask, val]);
    }
  };

  const handleCheckAll = (val) => {
    if (val) {
      if (selectedAct == 0) {
        const alllTask = projrectDetail?.approvalPending?.filter((el) => true);
        setcheckedTask(alllTask);
      } else {
        const alllTask = curAct?.approvalPending?.filter(
          (el) => el.activity_id == selectedAct
        );
        const withoutCurAct = checkedTask.filter(
          (el) => el.activity_id != selectedAct
        );
        setcheckedTask([...withoutCurAct, ...alllTask]);
      }
    } else {
      if (selectedAct == 0) {
        setcheckedTask([]);
      } else {
        const filteredCheckedtask = checkedTask.filter(
          (el) => el.activity_id != selectedAct
        );
        setcheckedTask([...filteredCheckedtask]);
      }
    }
  };
  const handleApproveBulk = async () => {
    let outData = checkedTask
      .filter((el) => el.work_status === "IN_PROGRESS")
      .map((el) => {
        let val = {};
        val.id = el.id;
        val.project_id = el.project_id;
        val.project_task_id = el.project_task_id;
        val.task_id = el.task_id;
        val.work_done=el.work_done;
        val.activity_id = el.activity_id;
        return val;
      });
    if (outData.length === 0) {
      setSnack({
        ...snack,
        open: true,
        message: "Please checkbox task for approval",
      });
      return;
    }
    try {
      await approveBulk(outData);
      getprojectDetail({ isUpdating: true });
      setSnack({
        ...snack,
        open: true,
        message: "Task approved successfully",
      });
      setcheckedTask([]);
    } catch (error) {
      console.log("task update error");
      setSnack({
        ...snack,
        open: true,
        message:error?.response?.data?.message ||
          error?.message ||
          "Something went wrong, please try again later",
      });
    }
  };

  const handleRejectBulk = async () => {
    let outData = checkedTask
      .filter((el) => el.work_status === "IN_PROGRESS")
      .map((el) => {
        let val = {};
        val.id = el.id;
        val.project_id = el.project_id;
        val.project_task_id = el.project_task_id;
        val.task_id = el.task_id;
        val.activity_id = el.activity_id;
        val.work_done=el.work_done;
        return val;
      });
    if (outData.length === 0) {
      setSnack({
        ...snack,
        open: true,
        message: "Please check the task for rejection",
      });
      return;
    }

    try {
      await rejectBulk(outData);
      setSnack({
        ...snack,
        open: true,
        message: "Task successfully rejected",
      });
      getprojectDetail({ isUpdating: true });
      setcheckedTask([]);
    } catch (error) {
      setSnack({
        ...snack,
        open: true,
        message:
          error?.response?.data?.message ||
          error?.message ||
          "Something went wrong, please try again later",
      });
      console.log("task update error");
    }
  };

  const handleAccRej = async (val, action) => {
    try {
      await accRejSingle(param.projectid, val, action);
      getprojectDetail({ isUpdating: true });
      setSnack({
        ...snack,
        open: true,
        message: "Task successfully updated",
      });
    } catch (error) {
      setSnack({
        ...snack,
        open: true,
        message:
          error?.response?.data?.message ||
          error?.message ||
          "Something went wrong, please try again later",
      });
      console.log("acc rej single error");
    }
  };

  const COLUMNS = [
    {
      Header: (props) => (
        <Checkbox
          size="small"
          checked={
            selectedAct != 0
              ? projrectDetail?.approvalPending.length > 0 &&
                projrectDetail?.approvalPending?.filter(
                  (ts) => ts.activity_id == selectedAct
                ).length ==
                  checkedTask.filter((ts) => ts.activity_id == selectedAct)
                    .length
              : projrectDetail?.approvalPending.length > 0 &&
                projrectDetail?.approvalPending?.length == checkedTask.length
          }
          color="primary"
          inputProps={{ "aria-label": "secondary checkbox" }}
          onClick={(e) => {
            handleCheckAll(e.target.checked);
          }}
        />
      ),
      accessor: "id",
      Cell: (props) => (
        <Checkbox
          size="small"
          color="primary"
          inputProps={{ "aria-label": "secondary checkbox" }}
          checked={
            checkedTask.find(
              (el) =>
                el.id == props?.row?.original?.id &&
                el.activity_id == props?.row.original?.activity_id
            )
              ? true
              : false
          }
          onChange={() => {
            handleCheck(props?.row?.original);
          }}
        />
      ),
    },
    {
      Header: "S No.",
      Cell: (props) => <p>{props.row.index + 1}</p>,
      accessor: "activity_id",
    },
    {
      Header: "Task Name",
      accessor: "taskDetail.name",
      Cell: (props) => <>{props.value}</>,
    },
    {
      Header: "UOM",
      accessor: "taskDetail.uom",
    },
    {
      Header: "Start Date",
      accessor: "projectTask.task_assign_from_date",
      Cell: (props) => (
        <>{props.value ? moment(props.value).format("DD/MM/YYYY") : "-"}</>
      ),
    },
    {
      Header: "Scheduled Date",
      accessor: "projectTask.scheduled_date",
      Cell: (props) => (
        <>{props.value ? moment(props.value).format("DD/MM/YYYY") : "-"}</>
      ),
    },
    {
      Header: "Completed Date",
      accessor: "projectTask.completed_date",
      Cell: (props) => (
        <>{props.value ? moment(props.value).format("DD/MM/YYYY") : "-"}</>
      ),
    },
    {
      Header: "Total / Completed",
      accessor: "projectTask.total_work_allocated",
      Cell: (props) => (
        <>
          {props?.value} /{props?.row?.original?.projectTask?.total_completed}
        </>
      ),
    },
    {
      Header: "Today Work",
      accessor: "work_done",
    },
    {
      Header: "Remark",
      accessor: "supervisor_remark",
    },
    {
      Header: "Status",
      accessor: "isApproved",
      Cell: (props) => (
        <>
          {props.row.original.work_status === "PENDING" ||
            (props.row.original.work_status === "IN_PROGRESS" && (
              <Box style={{ display: "flex" }}>
                <Button
                  size="small"
                  style={{
                    fontSize: "10px",
                    maxWidth: "100px",
                    minWidth: "80px",
                  }}
                  variant="contained"
                  onClick={() => handleAccRej(props.row.original, "APPROVED")}
                >
                  Approve
                </Button>
                <Button
                  size="small"
                  style={{
                    fontSize: "10px",
                    marginLeft: "5px",
                    maxWidth: "100px",
                    minWidth: "80px",
                  }}
                  variant="contained"
                  color="error"
                  onClick={() => handleAccRej(props.row.original, "REJECTED")}
                >
                  Decline
                </Button>
              </Box>
            ))}
          {props.row.original.work_status === "APPROVED" && (
            <>
              <Button
                variant="contained"
                size="small"
                style={{
                  fontSize: "10px",
                  maxWidth: "100px",
                  minWidth: "80px",
                }}
              >
                Approved
              </Button>
            </>
          )}
          {props.row.original.work_status === "NO_WORK" && (
            <>
              <Button
                variant="contained"
                size="small"
                style={{
                  fontSize: "10px",
                  maxWidth: "100px",
                  minWidth: "80px",
                }}
              >
                No Work
              </Button>
            </>
          )}
          {props.row.original.work_status === "REJECTED" && (
            <>
              <Button
                variant="contained"
                color="error"
                style={{
                  fontSize: "10px",
                  maxWidth: "100px",
                  minWidth: "80px",
                }}
              >
                REJECTED
              </Button>
            </>
          )}
        </>
      ),
    },
  ];

  const columns = useMemo(() => COLUMNS, [curAct, checkedTask]);
  const data = useMemo(
    () =>
      projrectDetail?.approvalPending.filter((el) => {
        if (selectedAct != 0) {
          return el.activity_id == selectedAct;
        } else {
          return true;
        }
      }),
    [selectedAct, curAct, checkedTask, projrectDetail]
  );
  const tableInstance = useTable(
    {
      columns: columns,
      data: data,
      // data: projrectDetail.activity.find((el) => el.activity_id == selectedAct)
      //   ?.submittedTask
      //   ? projrectDetail.activity.find((el) => el.activity_id == selectedAct)
      //       ?.submittedTask
      //   : approveT.activity[0].submittedTask,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    pageCount,
    pageOptions,
    setPageSize,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    prepareRow,
    state,
    setGlobalFilter,
    setFilter,
    setAllFilters,
    preFilteredRows,
    toggleSortBy,
  } = tableInstance;

  const { globalFilter, filters, pageIndex, pageSize } = state;

  useEffect(() => {
    getprojectDetail({ isUpdating: true });
  }, []);

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          {...getTableProps()}
          size="small"
          className="table_approve_right"
          style={{ minWidth: "980px", maxWidth: "1400px" }}
        >
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow
                {...headerGroup.getHeaderGroupProps()}
                className="aprrove-task"
              >
                {headerGroup.headers.map((column) => (
                  <TableCell>{column.render("Header")}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()} className="aprrove-task">
                  {row.cells.map((cell) => {
                    return <TableCell>{cell.render("Cell")}</TableCell>;
                  })}
                </TableRow>
              );
            })}

            <TableRow>
              {rows.length > 0 ? (
                <>
                  <TableCell
                    colSpan={14}
                    style={{ padding: "20px", textAlign: "center" }}
                  >
                    <Button
                      variant="contained"
                      style={{ minWidth: "150px", marginRight: "10px" }}
                      onClick={() => handleApproveBulk()}
                    >
                      Approve
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      style={{ minWidth: "150px" }}
                      onClick={() => handleRejectBulk()}
                    >
                      Reject
                    </Button>
                  </TableCell>
                </>
              ) : (
                <TableCell
                  colSpan={14}
                  style={{ padding: "20px", textAlign: "center" }}
                  align="center"
                >
                  No Data Available
                </TableCell>
              )}
            </TableRow>
          </TableBody>
        </Table>
        {rows.length > 0 && (
          <Pagination
            page={page}
            nextPage={nextPage}
            previousPage={previousPage}
            pageCount={pageCount}
            setPageSize={setPageSize}
            pageSize={pageSize}
            pageOptions={pageOptions}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            rows={rows}
            pageIndex={pageIndex}
          />
        )}
      </TableContainer>
      <Snackbar
        open={snack.open}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        onClose={() => {
          setSnack({
            ...snack,
            open: false,
            message: "",
          });
        }}
        message={snack.message}
      />
    </>
  );
};

export default ApproveTask;
