import React, { useState, useEffect, useMemo } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import UserHeader from "../../Component/Header/UserHeader";
import PageTitle from "../../Component/PageTitle/PageTitle";
import { Button, Grid, Paper, Box, Divider } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  DangerBtn,
  SaccessBtn,
  PrimaryBtn,
  NeutralBtn,
} from "../../Pages/styles";
import { useStyles, btnStyles } from "../TableStyle";
import Link from "@mui/material/Link";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Snackbar, Backdrop } from "@mui/material";
import Select from "@mui/material/Select";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import Footer from "../Footer/Footer";
import {
  assignedtasklist,
  submitTask,
  projAct,
  submitNoWork,
} from "../../redux/api/index";
import { useParams, NavLink } from "react-router-dom";
import "../../Pages/dashboard/Dashboard.scss";

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#ffffff",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
  },
});

export default function Supervisor() {
  let [taskList, setTaskList] = useState({
    assigned: [],
  });
  const [checkedTask, setcheckedTask] = useState([]);
  const [projectActivity, setProjectActivity] = useState([]);
  const [snack, setSnack] = useState({ open: false, message: "" });
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [selectedAct, setSelectedAct] = useState(0);
  const [supName, setSupName] = useState({
    value: "",
    error: "",
  });
  const [value, setValue] = React.useState(null);

  const param = useParams();

  const classes = useStyles();

  const COLUMNS = [
    {
      Header: (props) => (
        <Checkbox
          color="primary"
          inputProps={{ "aria-label": "secondary checkbox" }}
          checked={taskList.assigned.length === checkedTask.length}
          onClick={(e) => {
            handleCheckAll(e.target.checked);
          }}
        />
      ),
      accessor: "id",
      Cell: (props) => (
        <Checkbox
          color="primary"
          inputProps={{ "aria-label": "secondary checkbox" }}
          checked={
            checkedTask.find((el) => el.id == props?.row?.original.id)
              ? true
              : false
          }
          onChange={() => {
            handleCheck(props.row.original);
          }}
        />
      ),
    },
    {
      Header: "Activity Name",
      accessor: "activity.name",
    },
    {
      Header: "Task Name",
      accessor: "taskDetail.name",
    },
    {
      Header: "UOM",
      accessor: "taskDetail.uom",
    },
    {
      Header: "Start Date",
      accessor: "projectTask.from_date",
    },
    {
      Header: "ETC Date",
      accessor: "projectTask.etc_date",
    },
    // {
    //   Header: "Completed Date",
    //   accessor: "completed_date",
    //   Cell: (props) => (
    //     <>
    //       <LocalizationProvider dateAdapter={AdapterMoment}>
    //         <DatePicker
    //           label="Completed Date"
    //           // minDate={moment(new Date())}
    //           inputFormat="DD-MM-YYYY"
    //           name="completed_date"
    //           value={props?.row?.original?.completed_date}
    //           onChange={(e) => {
    //             props.row.original.completed_date = moment(e).utc().format();
    //             setTaskList({ ...taskList });
    //           }}
    //           renderInput={(params) => (
    //             <TextField
    //               name="start_date"
    //               size="small"
    //               className={classes.textField}
    //               variant="outlined"
    //               {...params}
    //             />
    //           )}
    //         />
    //       </LocalizationProvider>
    //     </>
    //   ),
    // },
    {
      Header: "Total work",
      accessor: "projectTask.total_work_allocated",
    },
    {
      Header: "Work Done",
      accessor: "work_done",
      Cell: (props) => (
        <>
          <TextField
            label="Work Done"
            size="small"
            value={props?.row?.original?.work_done}
            onChange={(e) => {
              props.row.original.work_done = e.target.value;
              setTaskList({ ...taskList });
            }}
          />
        </>
      ),
    },
    {
      Header: "Remark",
      accessor: "supervisor_remark",
      Cell: (props) => (
        <>
          <TextField
            label="Remark"
            size="small"
            value={props?.row?.original?.supervisor_remark}
            onChange={(e) => {
              props.row.original.supervisor_remark = e.target.value;
              // console.log(taskList);
              setTaskList({ ...taskList });
            }}
          />
        </>
      ),
    },
    {
      Header: "Work Status",
      accessor: "work_status",
      Cell: (props) => (
        <>
          {/* <Select
            size="small"
            fullWidth
            value={props?.row?.original?.work_status}
            label="12/06/2022"
            onChange={(e) => {
              props.row.original.work_status = e.target.value;
              setTaskList({ ...taskList });
            }}
          >
            <MenuItem value="">Select status</MenuItem>
            <MenuItem value="IN_PROGRESS">In Progress</MenuItem>
            <MenuItem value="PENDING">In Pending</MenuItem>
            <MenuItem value="APPROVED">Completed</MenuItem>
          </Select> */}
          <Button color="primary" variant="contained">
            Submit
          </Button>
        </>
      ),
    },
  ];

  const columns = useMemo(() => COLUMNS, [taskList, checkedTask]);
  const tableInstance = useTable(
    {
      columns: columns,
      data: taskList?.assigned ? taskList.assigned : [],
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    pageCount,
    pageOptions,
    setPageSize,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    prepareRow,
    state,
    setGlobalFilter,
    setFilter,
    setAllFilters,
    preFilteredRows,
    toggleSortBy,
  } = tableInstance;

  const { globalFilter, filters, pageIndex, pageSize } = state;

  const fetchAssignedTask = async (actId, date) => {
    try {
      if (date) {
        if (
          new Date(moment().format("YYYY-MM-DD")).getTime() <
          new Date(date).getTime()
        ) {
          const { data } = await assignedtasklist(
            param.projectid,
            actId ? actId : null,
            null
          );
          setTaskList(data.data);
        } else {
          const { data } = await assignedtasklist(
            param.projectid,
            actId ? actId : null,
            date ? date : null
          );
          setTaskList(data.data);
        }
      } else {
        const { data } = await assignedtasklist(
          param.projectid,
          actId ? actId : null,
          date ? date : null
        );
        setTaskList(data.data);
      }
    } catch (error) {
      console.log("assigned task fetch error");
    }
  };
  const projectAct = async () => {
    try {
      const { data } = await projAct(param.projectid);
      setProjectActivity(data.data);
    } catch (error) {
      console.log("assigned task fetch error");
    }
  };

  const handleCheck = (val) => {
    const isAdded = checkedTask.find((el) => el.id == val.id);
    if (isAdded) {
      const filtCk = checkedTask.filter((el) => el.id != val.id);
      setcheckedTask(filtCk);
    } else {
      setcheckedTask([...checkedTask, val]);
    }
  };

  const handleCheckAll = (val) => {
    if (val) {
      setcheckedTask(taskList.assigned);
    } else {
      setcheckedTask([]);
    }
  };

  let errArr = [];
  const handleSubmit = async () => {
    errArr = [];
    setTaskList({ ...taskList });

    const outDate = checkedTask.map((el) => {
      let val = {};
      val.id = el.id;
      val.project_id = el.project_id;
      val.project_task_id = el.project_task_id;
      val.task_id = el.task_id;
      val.activity_id = el.activity_id;
      val.completed_date = el.completed_date;
      val.supervisor_remark = el.supervisor_remark;
      val.work_status = el.work_status;
      val.work_done = el.work_done;
      el.error_supervisor_remark = "";
      val.supervisor_name = supName.value;
      if (!el.work_done || Number(el.work_done) <= 0) {
        el.error_work_done = "Please enter value";
        errArr.push(el.error_work_done);
      } else {
        el.error_work_done = "";
      }
      if (!supName.value) {
        setSupName({
          ...supName,
          error: "Please enter supervisor name",
        });
        errArr.push("Please enter supervisor name");
      } else {
        setSupName({
          ...supName,
          error: "",
        });
      }

      return val;
    });
    setTaskList({ ...taskList });

    if (errArr.length > 0) {
      setSnack({
        ...snack,
        open: true,
        message: "Please fix the error, to continue",
      });
      return;
    }

    if (checkedTask.length === 0) {
      setSnack({
        ...snack,
        open: true,
        message: "Please check task you wish to submit",
      });
      return;
    }

    try {
      await submitTask(param.projectid, outDate);
      setSupName({
        ...supName,
        value: "",
        error: "",
      });
      setSnack({
        ...snack,
        open: true,
        message: "Task submitted succussfully",
      });
      fetchAssignedTask();
    } catch (error) {
      setSnack({
        ...snack,
        open: true,
        message:
          error?.response?.data?.message ||
          error?.message ||
          "Something went wrong, please try again later",
      });
    }
  };

  errArr = [];
  const handleNoWorkSubmit = async () => {
    setTaskList({ ...taskList });

    const outDate = checkedTask.map((el) => {
      let val = {};
      val.id = el.id;
      val.project_id = el.project_id;
      val.project_task_id = el.project_task_id;
      val.task_id = el.task_id;
      val.activity_id = el.activity_id;
      val.completed_date = el.completed_date;
      val.supervisor_remark = el.supervisor_remark;
      val.work_status = el.work_status;
      val.work_done = el.work_done;
      el.error_work_done = "";
      val.supervisor_name = supName.value;
      if (!el.supervisor_remark) {
        el.error_supervisor_remark = "Please enter value";
        errArr.push(el.error_supervisor_remark);
      } else {
        el.error_supervisor_remark = "";
      }
      if (!supName.value) {
        setSupName({
          ...supName,
          error: "Please enter supervisor name",
        });
        errArr.push("Please enter supervisor name");
      } else {
        setSupName({
          ...supName,
          error: "",
        });
      }

      return val;
    });
    setTaskList({ ...taskList });

    if (errArr.length > 0) {
      setSnack({
        ...snack,
        open: true,
        message: "Please fix the error, to continue",
      });
      return;
    }

    if (checkedTask.length === 0) {
      setSnack({
        ...snack,
        open: true,
        message: "Please check task you wish to submit",
      });
      return;
    }

    try {
      await submitNoWork(param.projectid, outDate);
      setSupName({
        ...supName,
        value: "",
        error: "",
      });
      setSnack({
        ...snack,
        open: true,
        message: "No work submitted succussfully",
      });
      fetchAssignedTask();
    } catch (error) {
      setSnack({
        ...snack,
        open: true,
        message:
          error?.response?.data?.message ||
          error?.message ||
          "Something went wrong, please try again later",
      });
    }
  };

  useEffect(() => {
    fetchAssignedTask();
    projectAct();
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <CssBaseline />
          <UserHeader
            setProjectActivity={setProjectActivity}
            projectActivity={projectActivity}
            selectedAct={selectedAct}
            setSelectedAct={setSelectedAct}
            fetchAssignedTask={fetchAssignedTask}
            date={value}
            setDate={setValue}
          />
          <main className={classes.content} style={{ overflow: "hidden" }}>
            <div className={classes.toolbar} />
            {/* <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" href="/">
                User
              </Link>
              <Typography color="textPrimary">Listing</Typography>
            </Breadcrumbs> */}

            <PageTitle title="User" />
            <Grid container style={{ marginBottom: "20px" }}>
              <Grid item xs={12}>
                <Box elevation={4}>
                  <Typography
                    variant="h5"
                    sx={{ display: "flex", p: 0, mb: 1 }}
                    component={Box}
                  >
                    {taskList[0]?.project?.project_name}{" "}
                    <span className="mr-2">
                      {" "}
                      {taskList[0]?.project?.project_id}{" "}
                    </span>
                  </Typography>
                  <Divider></Divider>
                </Box>

                <TableContainer component={Paper} elevation={10}>
                  <Table
                    size="small"
                    fullWidth
                    style={{ tableLayout: "fixed" }}
                    className="supervisor-dashboard"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{ width: "50px", textAlign: "center" }}
                        >
                          <Checkbox
                            size="small"
                            className="sizeSmall"
                            checked={
                              taskList.assigned.length > 0 &&
                              taskList.assigned.length === checkedTask.length
                            }
                            onClick={(e) => {
                              handleCheckAll(e.target.checked);
                            }}
                          />
                        </TableCell>
                        <TableCell sx={{ pl: 1, pr: 1 }}>
                          Activity Name
                        </TableCell>
                        <TableCell sx={{ pl: 1, pr: 1 }}>Task Name</TableCell>
                        <TableCell sx={{ pl: 1, pr: 1 }}>UOM</TableCell>
                        <TableCell sx={{ pl: 1, pr: 1 }}>Start Date</TableCell>
                        <TableCell sx={{ pl: 1, pr: 1 }}>ETC Date</TableCell>
                        {/* <TableCell sx={{ pl: 1, pr: 1 }} align="center">
                        Completed Date
                      </TableCell> */}
                        <TableCell sx={{ pl: 1, pr: 1 }}>Total Work</TableCell>
                        <TableCell sx={{ pl: 1, pr: 1 }}>Work Done</TableCell>
                        <TableCell sx={{ pl: 1, pr: 1 }}>Remark</TableCell>
                        <TableCell sx={{ pl: 1, pr: 1 }}>Work Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {taskList?.assigned.map((data) => {
                        return (
                          <TableRow>
                            <TableCell
                              sx={{ pl: 1, pr: 1 }}
                              align="left"
                              className="sizeSmall"
                            >
                              <Checkbox
                                size="small"
                                checked={
                                  checkedTask.find((el) => el.id == data.id)
                                    ? true
                                    : false
                                }
                                onChange={() => {
                                  handleCheck(data);
                                }}
                              />
                            </TableCell>
                            <TableCell sx={{ pl: 1, pr: 1 }} align="center">
                              {data?.activity?.name}
                            </TableCell>
                            <TableCell sx={{ pl: 1, pr: 1 }} align="center">
                              {data?.taskDetail?.name}
                            </TableCell>
                            <TableCell sx={{ pl: 1, pr: 1 }} align="center">
                              {data?.taskDetail?.uom}{" "}
                            </TableCell>
                            <TableCell sx={{ pl: 1, pr: 1 }} align="center">
                              {data?.from_date
                                ? moment(data?.from_date).format("DD/MM/YYYY")
                                : "-"}
                            </TableCell>
                            <TableCell sx={{ pl: 1, pr: 1 }} align="center">
                              {data?.projectTask?.etc_date
                                ? moment(data?.projectTask?.etc_date).format(
                                    "DD/MM/YYYY"
                                  )
                                : "-"}
                            </TableCell>
                            {/* <TableCell sx={{ pl: 1, pr: 1 }} align="center">
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DatePicker
                                label="Start Date"
                                // minDate={moment(new Date())}
                                name="start_date"
                                value={data?.completed_date}
                                onChange={(e) => {
                                  data.completed_date = moment(e)
                                    .utc()
                                    .format();
                                  setTaskList({ ...taskList });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    name="start_date"
                                    size="small"
                                    className={classes.textField}
                                    variant="outlined"
                                    {...params}
                                    style={{
                                      transform: "scale(0.9)",
                                      width: "150px",
                                    }}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </TableCell> */}
                            <TableCell sx={{ pl: 1, pr: 1 }} align="center">
                              {data?.assigned_goal}
                            </TableCell>
                            <TableCell sx={{ pl: 1, pr: 1 }} align="center">
                              <TextField
                                name="work_done"
                                size="small"
                                className={classes.textField}
                                value={data?.work_done}
                                variant="outlined"
                                onChange={(e) => {
                                  let val = e.target.value.replace(
                                    /[a-zA-[\]\\Z$&/+,:;=?@#|'<>.^*()%!_-]/gi,
                                    ""
                                  );
                                  // data.work_done = val;
                                  // setTaskList({ ...taskList });
                                  if (
                                    Number(val) <=
                                    Number(
                                      data.projectTask.total_work_allocated
                                    ) -
                                      Number(data.projectTask.total_completed)
                                  ) {
                                    data.work_done = val;
                                    setTaskList({ ...taskList });
                                  }
                                  if (
                                    Number(val) >
                                    Number(
                                      data.projectTask.total_work_allocated
                                    ) -
                                      Number(data.projectTask.total_completed)
                                  ) {
                                    data.work_done =
                                      Number(
                                        data.projectTask.total_work_allocated
                                      ) -
                                      Number(data.projectTask.total_completed);
                                    setTaskList({ ...taskList });
                                  }
                                }}
                              />
                              {data.error_work_done && (
                                <p className="errorText">
                                  {" "}
                                  {data?.error_work_done}{" "}
                                </p>
                              )}
                            </TableCell>
                            <TableCell sx={{ pl: 1, pr: 1 }} align="center">
                              <TextField
                                label="Remark"
                                size="small"
                                value={data.supervisor_remark}
                                onChange={(e) => {
                                  data.supervisor_remark = e.target.value;
                                  setTaskList({
                                    ...taskList,
                                    assigned: [...taskList.assigned],
                                  });
                                }}
                              />
                              {data.error_supervisor_remark && (
                                <p className="errorText">
                                  {data.error_supervisor_remark}
                                </p>
                              )}
                            </TableCell>
                            <TableCell sx={{ pl: 1, pr: 1 }} align="center">
                              {/* <Box width={150}>
                              <Select
                                size="small"
                                fullWidth
                                value={data?.work_status}
                                label="12/06/2022"
                                onChange={(e) => {
                                  data.work_status = e.target.value;
                                  setTaskList({ ...taskList });
                                }}
                                style={{ transform: "scale(0.9)" }}
                              >
                                <MenuItem value="">Select status</MenuItem>
                                <MenuItem value="IN_PROGRESS">
                                  In Progress
                                </MenuItem>
                                <MenuItem value="COMPLETED">Completed</MenuItem>
                              </Select>
                            </Box> */}
                              <Button color="primary" variant="contained">
                                Submit
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            <Grid container component={Paper}>
              <Grid item xs={8}>
                <Box
                  sx={{ mt: 2, p: 2, display: "flex", alignItems: "center" }}
                >
                  <Typography style={{ marginRight: "20px" }}>
                    Supervisor Name
                  </Typography>
                  <TextField
                    label="Enter name"
                    size="small"
                    style={{ width: "60%" }}
                    value={supName.value}
                    onChange={(e) => {
                      setSupName({
                        ...supName,
                        value: e.target.value.replace(
                          /[0-9$&/+,:;=?@#|[\]\\'<>.^*()%!_-]/gi,
                          ""
                        ),
                      });
                    }}
                  />
                </Box>
                {supName.error && <p className="errorText">{supName.error}</p>}
              </Grid>
              <Grid item xs={4}>
                <Box
                  sx={{
                    mt: 2,
                    p: 2,
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => handleNoWorkSubmit()}
                  >
                    No Work
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => handleSubmit()}
                  >
                    Update Tasks
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Footer />
          </main>
        </div>
      </ThemeProvider>
      <Backdrop
        style={{ zIndex: 9, opacity: "35%" }}
        open={showBackdrop}
        // onClick={()}
      >
        {/* <CircularProgress color="inherit" /> */}
        {/* <LinearProgress /> */}
      </Backdrop>
      <Snackbar
        open={snack.open}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={3000}
        onClose={() => {
          setSnack({
            ...snack,
            open: false,
            message: "",
          });
        }}
        message={snack.message}
      />
    </>
  );
}
