import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import logo from "../../assets/images/protrac+blue.svg";
import Slinko from "../../assets/images/slnko+blue.svg";

const darkTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#fff",
    },
  },
});

export default function ReportHeader({ projectInfo }) {
  return (
    <ThemeProvider theme={darkTheme}>
      <Box>
        <AppBar position="fixed" color="primary">
          <Toolbar>
            <Typography component="div" sx={{ flexGrow: 1 }}>
              <Avatar
                alt=""
                src={Slinko}
                style={{
                  borderRadius: "0",
                  minWidth: "130px",
                  maxWidth: "160px",
                  padding: "4px",
                  minHeight: "64px",
                }}
              />
            </Typography>
            <Typography component="div" sx={{ flexGrow: 1 }}>
              <Avatar
                alt=""
                src={logo}
                style={{
                  borderRadius: "0",
                  minWidth: "140px",
                  maxWidth: "160px",
                  padding: "5px",
                  minHeight: "60px",
                }}
              />
            </Typography>
            {/* <Button color="inherit">Client Logo</Button> */}
            <Avatar
              alt=""
              src={`${projectInfo?.client_logo}`}
              style={{
                borderRadius: "0",
                minWidth: "140px",
                maxWidth: "160px",
                padding: "5px",
                minHeight: "60px",
              }}
            />
          </Toolbar>
        </AppBar>
      </Box>
    </ThemeProvider>
  );
}
