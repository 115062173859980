import {
  GET_ACTIVITY,
  GET_TASK,
  FILTER_ACTIVITY,
  FILTER_TASK,
  UPDATE_NEW_TASK,
  ADD_ACTIVITY,
  GET_ALL_TASK,
  ADD_TASK,
  UPDATE_ACTIVITY,
  UPDATE_TASK,
} from "../constants";

const initialState = {
  activity: [],
  task: [],
  filterActivity: [],
  filterTask: [],
  newTask: [],
};

export const master = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACTIVITY:
      return {
        ...state,
        activity: action.payload,
        // filterTask:state.task.filter(t=>t.activityId==action.payload[0].id)
      };
    case ADD_ACTIVITY:
      return {
        ...state,
        activity: [...state.activity, action.payload],
      };
    case UPDATE_ACTIVITY:
      const filterAct = state.activity.filter(
        (act) => act.id != action.payload.id
      );
      return {
        ...state,
        activity: [...filterAct, action.payload].sort(
          (first, sec) => first.id - sec.id
        ),
      };
    case ADD_TASK:
      return {
        ...state,
        task: [...state.task, action.payload],
      };
    case UPDATE_TASK:
      let filterTsk = state.task.filter((tsk) => tsk.id != action.payload.id);
      let finalTsk = [...filterTsk, action.payload];
      return {
        ...state,
        task: [...filterTsk, action.payload].sort(
          (first, sec) => first.id - sec.id
        ),
        filterTask: finalTsk.filter(
          (t) => t.activityId == action.payload.activityId
        ),
      };
    case GET_TASK:
      return {
        ...state,
        task: action.payload,
        newTask: action.payload.map((t) => {
          return {
            task_id: t.id,
            activity_id: t.activityId,
            name: t.name,
            uom: t.uom,
            goal: "",
            etc_date: null,
            scheduled_date: null,
          };
        }),
      };
    case GET_ALL_TASK:
      return {
        ...state,
        task: action.payload,
        filterTask: action.payload.filter(
          (t) => t.activityId == state.activity[0].id
        ),
      };
    case FILTER_ACTIVITY:
      return {
        ...state,
        filterActivity: state.activity.filter(
          (act) => act.id == action.payload
        ),
      };
    case FILTER_TASK:
      if (action.payload == 0) {
        return {
          ...state,
          filterTask: state.task,
        };
      } else {
        return {
          ...state,
          filterTask: state.task.filter((t) => t.activityId == action.payload),
        };
      }
    case UPDATE_NEW_TASK:
      return {
        ...state,
        newTask: action.payload,
      };

    default:
      return state;
  }
};
