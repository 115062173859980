import axios from "axios";

// const baseUrl = "http://localhost:3105/api/v1";
const baseUrl = "http://alobhaitsolution.com:3105/api/v1";

const api = axios.create({
  baseURL: baseUrl,
  // withCredentials: true,
});

export const loginApi = (email, password) =>
  api({
    method: "post",
    url: "/admin/login",
    data: {
      email: email,
      password: password,
    },
  });

export const changePass = (currentPass, newPass) =>
  api({
    method: "post",
    url: "/admin/change-password",
    data: {
      currentPassword: currentPass,
      newPassword: newPass,
    },
  });

export const logOut = () =>
  api({
    method: "post",
    url: "/admin/logout",
  });

export const supervisorLogin = (project_id, password) =>
  api({
    method: "post",
    url: "/projects/login",
    data: {
      project_id: project_id,
      password: password,
    },
  });

export const activity = () =>
  api({
    method: "get",
    url: "/activity",
  });
export const activityAddProject = () =>
  api({
    method: "get",
    url: "/activity/add-project",
  });
export const addActivity = (actName) =>
  api({
    method: "post",
    url: "/activity",
    data: {
      name: actName,
    },
  });

export const updateActivity = (actId, actName) =>
  api({
    method: "patch",
    url: `/activity/${actId}`,
    data: {
      name: actName,
    },
  });

export const changeActivityStatus = (actId, status) =>
  api({
    method: "patch",
    url: `activity/${actId}/update-status`,
    data: {
      status: status,
    },
  });

export const deleteActivity = (actId) =>
  api({
    method: "delete",
    url: `/activity/${actId}`,
  });

export const allTask = () =>
  api({
    method: "get",
    url: "/task",
  });

export const allTaskAddProject = () =>
  api({
    method: "get",
    url: "/task/add-project",
  });

export const taskList = (activityId) =>
  api({
    method: "get",
    url: `/activity/${activityId}/task`,
  });

export const addtask = (activityId, taskName, uom) =>
  api({
    method: "post",
    url: `/activity/${activityId}/task`,
    data: {
      name: taskName,
      uom: uom,
    },
  });

export const updateTask = (activityId, taskId, taskName, uom) =>
  api({
    method: "patch",
    url: `/activity/${activityId}/task/${taskId}`,
    data: {
      name: taskName,
      uom: uom,
    },
  });

export const changeTaskStatus = (activityId, taskId, status) =>
  api({
    method: "patch",
    url: `/activity/${activityId}/task/${taskId}/update-status`,
    data: {
      status: status,
    },
  });

export const deleteTask = (activityId, taskId) =>
  api({
    method: "delete",
    url: `/activity/${activityId}/task/${taskId}`,
  });

export const getProjectList = () =>
  api({
    method: "get",
    url: `/projects`,
  });

export const getProjectById = (pid) =>
  api({
    method: "get",
    url: `/projects/${pid}/all-details`,
  });

export const addProject = (projData) =>
  api({
    method: "post",
    url: `/projects`,
    data: projData,
  });

export const updateProject = (projId, updatedInfo) =>
  api({
    method: "patch",
    url: `/projects/${projId}`,
    data: updatedInfo,
  });

export const addProjectTask = (projectId, task) =>
  api({
    method: "post",
    url: `/projects/${projectId}/add-new-task`,
    data: {
      task,
    },
  });

export const updateProjectTask = (projectId, task) =>
  api({
    method: "patch",
    url: `/projects/${projectId}/add-new-task`,
    data: {
      task,
    },
  });

export const assignTask = (projectId, task) =>
  api({
    method: "POST",
    url: `/projects/${projectId}/bulk-assign-task`,
    data: {
      task: task,
    },
  });

export const singleAssignTask = (projectId, task) =>
  api({
    method: "POST",
    url: `/projects/${projectId}/single-assign-task`,
    data: {
      task: task,
    },
  });

export const approveBulk = (task) =>
  api({
    method: "POST",
    url: `/projects/assigned-task/approve-bulk`,
    data: {
      task: task,
    },
  });
export const rejectBulk = (task) =>
  api({
    method: "POST",
    url: `/projects/assigned-task/reject-bulk`,
    data: {
      task: task,
    },
  });

export const projAct = (projectId) =>
  api({
    method: "get",
    url: `/projects/${projectId}/assigned-activity`,
  });

export const accRejSingle = (projectId, task, action) =>
  api({
    method: "POST",
    url: `/projects/${projectId}/assigned-task/action-single`,
    data: {
      task: task,
      action,
    },
  });

export const assignedtasklist = (projectId, activityId, date) =>
  api({
    method: "POST",
    url: `/projects/${projectId}/assigned-task/all-listing`,
    data: {
      date: date,
      activityId: activityId,
    },
  });

export const futureAssignedList = (projectId, actId, date) =>
  api({
    method: "post",
    url: `/projects/assigntask/listing-filter?project_id=${projectId}`,
    data: {
      date,
      activity_id: actId,
    },
  });

export const submitTask = (projectId, task) =>
  api({
    method: "POST",
    url: `/projects/${projectId}/assigned-task/submit-task`,
    data: {
      task: task,
    },
  });

export const submitNoWork = (projectId, task) =>
  api({
    method: "POST",
    url: `/projects/${projectId}/assigned-task/submit-no-work`,
    data: {
      task: task,
    },
  });

export const submitTaskHistory = (projectId) =>
  api({
    method: "get",
    url: `/projects/${projectId}/assigned-task/submit-task-history`,
  });

export const allAssignedList = (date) =>
  api({
    method: "post",
    url: "/projects/assigntask/listing-all",
    data: {
      date,
    },
  });

export const projectProgress = () =>
  api({
    method: "GET",
    url: "/projects/progress-all",
  });

export const getState = () =>
  api({
    method: "get",
    url: "/phone/state?countryId=101",
  });
export const getCity = (stateid) =>
  api({
    method: "get",
    url: `/phone/city?stateId=${stateid}`,
  });

export const reportTaskOverview = (projectId) =>
  api({
    method: "get",
    url: `/report/client/project-report/task-overview?projectId=${projectId}`,
  });
export const reportActivityOverview = (projectId) =>
  api({
    method: "get",
    url: `/report/client/project-report/activity-overview?projectId=${projectId}`,
  });

export const reportProjectOverview = (projectId) =>
  api({
    method: "get",
    url: `/report/client/project-report/project-overview?projectId=${projectId}`,
  });

export const reportProjectSummary = (activity_id, date, projectId) =>
  api({
    method: "post",
    url: `/report/client/project-report/project-summary?project_id=${projectId}`,
    data: {
      activity_id,
      date,
    },
  });

export const reportProjectSummaryAggregated = (activity_id, date, projectId) =>
  api({
    method: "post",
    url: `/report/client/project-report/project-summary-aggregated?project_id=${projectId}`,
    data: {
      activity_id,
      date,
    },
  });

export const reportProjectDetailed = (
  projectId,
  activity_id,
  fromDate,
  toDate
) =>
  api({
    method: "post",
    url: `/report/client/project-report/project-detailed?project_id=${projectId}`,
    data: {
      activity_id,
      fromDate,
      toDate,
    },
  });
