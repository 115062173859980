export const prj = {
    project:{
        "id": 1,
        "project_name": "project sn",
        "project_site_link": null,
        "project_id": "SEPL1",
        "client_name": "client name",
        "client_email": "clientname@gmail.com",
        "client_phone": "9876543219",
        "client_id": null,
        "location": null,
        "start_date": "0000-00-00",
        "etc_date": "0000-00-00",
        "status": "COMLETE",
        "address": "address 1",
        "state_id": 0,
        "district_id": 0,
        "pincode": 123456,
        "supervisor_name": "supervisor name",
        "supervisor_email": "supervisoremail@gmail.com",
        "supervisor_phone": "9875432345",
        "project_creator_name": "project creator name",
        "project_password": null,
        "longitude": 22.99999,
        "latitude": null,
        "projectAccess_Emails": null,
        "isDraft": 1,
        "createdAt": "2022-06-21T11:14:25.000Z",
        "updatedAt": "2022-06-21T11:14:25.000Z"
    },
    activity:[
        {
            id:1,
            name:'land development',
            approval:[
                {
                    id:1,
                    task_id:1,
                    name:"Pits Compaction",
                    uom:"Nos",
                    start_date:"10-02-2021",
                    scheduled_date:"10-02-2021",
                    etc_date:"10-02-2021",
                    completed_date:"10-02-2021",
                    total_completed_work:200,
                    total_goal:500,
                    today_goal:100,
                    remark:"this is remark about task",
                    isApproved:true,
                    isAssigned:false
                }
            ],
            assign:[
                {
                    task_id:1,
                    name:"Pits Compaction",
                    uom:"Nos",
                    start_date:"10-02-2021",
                    scheduled_date:"10-02-2021",
                    etc_date:"10-02-2021",
                    completed_date:"10-02-2021",
                    total_completed_work:200,
                    total_goal:500,
                    today_goal:100,
                    remark:"this is remark about task",
                    isApproved:true,
                    isAssigned:false
                }
            ],
            update:[
                {
                    task_id:1,
                    name:"Pits Compaction 1",
                    uom:"Nos",
                    start_date:"10-02-2021",
                    scheduled_date:"10-02-2021",
                    etc_date:"10-02-2021",
                    completed_date:"10-02-2021",
                    total_completed_work:200,
                    total_goal:500,
                    today_goal:100,
                    remark:"this is remark about task",
                    isApproved:true,
                    isAssigned:false
                }
            ],
            add:[
                {
                    task_id:1,
                    name:"Pits Compaction",
                    uom:"Nos",
                    start_date:"10-02-2021",
                    scheduled_date:"10-02-2021",
                    etc_date:"10-02-2021",
                    completed_date:"10-02-2021",
                    total_completed_work:200,
                    total_goal:500,
                    today_goal:100,
                    remark:"this is remark about task",
                    isApproved:true,
                    isAssigned:false
                }
            ]
        },
        {
            id:2,
            name:'land development 2',
            approval:[
                {
                    task_id:1,
                    name:"Pits Compaction act 2 task 1",
                    uom:"Nos",
                    start_date:"10-02-2021",
                    scheduled_date:"10-02-2021",
                    etc_date:"10-02-2021",
                    completed_date:"10-02-2021",
                    total_completed_work:200,
                    total_goal:500,
                    today_goal:100,
                    remark:"this is remark about task",
                    isApproved:true,
                    isAssigned:false
                }
            ],
            assign:[
                {
                    task_id:1,
                    name:"Pits Compaction act 2 task 1",
                    uom:"Nos",
                    start_date:"10-02-2021",
                    scheduled_date:"10-02-2021",
                    etc_date:"10-02-2021",
                    completed_date:"10-02-2021",
                    total_completed_work:200,
                    total_goal:500,
                    today_goal:100,
                    remark:"this is remark about task",
                    isApproved:true,
                    isAssigned:false
                }
            ],
            update:[
                {
                    task_id:1,
                    name:"Pits Compaction act 2 task 1",
                    uom:"Nos",
                    start_date:"10-02-2021",
                    scheduled_date:"10-02-2021",
                    etc_date:"10-02-2021",
                    completed_date:"10-02-2021",
                    total_completed_work:200,
                    total_goal:500,
                    today_goal:100,
                    remark:"this is remark about task",
                    isApproved:true,
                    isAssigned:false
                }
            ],
            add:[
                {
                    task_id:1,
                    name:"Pits Compaction act 2 task 1",
                    uom:"Nos",
                    start_date:"10-02-2021",
                    scheduled_date:"10-02-2021",
                    etc_date:"10-02-2021",
                    completed_date:"10-02-2021",
                    total_completed_work:200,
                    total_goal:500,
                    today_goal:100,
                    remark:"this is remark about task",
                    isApproved:true,
                    isAssigned:false
                }
            ]
        },
        
    ],

}

export const prj2= {
    "id": 1,
    "project_name": "project sn",
    "project_site_link": null,
    "project_id": "SEPL1",
    "client_name": "client name",
    "client_email": "clientname@gmail.com",
    "client_phone": "9876543219",
    "client_id": null,
    "location": null,
    "start_date": "0000-00-00",
    "etc_date": "0000-00-00",
    "status": "COMLETE",
    "address": "address 1",
    "state_id": 0,
    "district_id": 0,
    "pincode": 123456,
    "supervisor_name": "supervisor name",
    "supervisor_email": "supervisoremail@gmail.com",
    "supervisor_phone": "9875432345",
    "project_creator_name": "project creator name",
    "project_password": null,
    "longitude": 22.99999,
    "latitude": null,
    "projectAccess_Emails": null,
    "isDraft": 1,
    "createdAt": "2022-06-21T11:14:25.000Z",
    "updatedAt": "2022-06-21T11:14:25.000Z",
    add:[
        {
            "activity_id": 3,
            "detail": {
                "id": 1,
                "name": "ADD Land Development",
                "status": 1,
                "createdAt": "2022-06-21T07:03:28.000Z",
                "updatedAt": "2022-06-22T04:51:03.000Z"
            },
            "task": [
                {
                    "id": 1,
                    "name": "Pits compaction - Land Development",
                    "uom": "cuFit",
                    "activityId": 1,
                    "status": 1,
                    "createdAt": "2022-06-21T09:11:57.000Z",
                    "updatedAt": "2022-06-22T04:52:14.000Z",
                    "goal": "",
                    "etc_date": null
                },
                {
                    "id": 2,
                    "name": "Pits compaction-3 - Land Development",
                    "uom": "uom",
                    "activityId": 1,
                    "status": 1,
                    "createdAt": "2022-06-21T09:13:24.000Z",
                    "updatedAt": "2022-06-22T04:52:44.000Z",
                    "goal": "",
                    "etc_date": null
                },
                {
                    "id": 7,
                    "name": "Pits compaction-2 - Land Development",
                    "uom": "meter",
                    "activityId": 1,
                    "status": 1,
                    "createdAt": "2022-06-21T16:07:40.000Z",
                    "updatedAt": "2022-06-22T04:52:29.000Z",
                    "goal": "",
                    "etc_date": null
                }
            ],
        }
    ],
    "activity": [
        {
            "activity_id": 1,
            "detail": {
                "id": 1,
                "name": "Land Development",
                "status": 1,
                "createdAt": "2022-06-21T07:03:28.000Z",
                "updatedAt": "2022-06-22T04:51:03.000Z"
            },
            "add": [
                {
                    "id": 1,
                    "name": "Pits compaction - Land Development",
                    "uom": "cuFit",
                    "activityId": 1,
                    "status": 1,
                    "createdAt": "2022-06-21T09:11:57.000Z",
                    "updatedAt": "2022-06-22T04:52:14.000Z",
                    "goal": "",
                    "etc_date": null
                },
                {
                    "id": 2,
                    "name": "Pits compaction-3 - Land Development",
                    "uom": "uom",
                    "activityId": 1,
                    "status": 1,
                    "createdAt": "2022-06-21T09:13:24.000Z",
                    "updatedAt": "2022-06-22T04:52:44.000Z",
                    "goal": "",
                    "etc_date": null
                },
                {
                    "id": 7,
                    "name": "Pits compaction-2 - Land Development",
                    "uom": "meter",
                    "activityId": 1,
                    "status": 1,
                    "createdAt": "2022-06-21T16:07:40.000Z",
                    "updatedAt": "2022-06-22T04:52:29.000Z",
                    "goal": "",
                    "etc_date": null
                }
            ],
            "unassigned": [
                {
                    "id": 9,
                    "project_id": "1",
                    "activity_id": 1,
                    "projectTask_id": null,
                    "ProjectPrimary_ID": null,
                    "task_id": 1,
                    "start_date": null,
                    "etc_date": "2022-02-12",
                    "completed_date": null,
                    "completed_total": 0,
                    "work_done": 0,
                    "assigned_total": 500,
                    "remark": null,
                    "status": null,
                    "supervisorName": null,
                    "isApproved": 0,
                    "isAssigned": 0,
                    "Total_Work": null,
                    "createdAt": "2022-06-22T17:15:56.000Z",
                    "updatedAt": "2022-06-22T17:15:56.000Z",
                    "taskDetail": {
                        "id": 1,
                        "name": "Pits compaction - Land Development",
                        "uom": "cuFit",
                        "activityId": 1,
                        "status": 1,
                        "createdAt": "2022-06-21T09:11:57.000Z",
                        "updatedAt": "2022-06-22T04:52:14.000Z"
                    }
                },
                {
                    "id": 11,
                    "project_id": "1",
                    "activity_id": 1,
                    "projectTask_id": null,
                    "ProjectPrimary_ID": null,
                    "task_id": 2,
                    "start_date": null,
                    "etc_date": "2022-03-12",
                    "completed_date": null,
                    "completed_total": 0,
                    "work_done": 0,
                    "assigned_total": 800,
                    "remark": null,
                    "status": null,
                    "supervisorName": null,
                    "isApproved": 0,
                    "isAssigned": 0,
                    "Total_Work": null,
                    "createdAt": "2022-06-22T17:45:15.000Z",
                    "updatedAt": "2022-06-22T17:45:15.000Z",
                    "taskDetail": {
                        "id": 2,
                        "name": "Pits compaction-3 - Land Development",
                        "uom": "uom",
                        "activityId": 1,
                        "status": 1,
                        "createdAt": "2022-06-21T09:13:24.000Z",
                        "updatedAt": "2022-06-22T04:52:44.000Z"
                    }
                }
            ],
            "assigned": []
        },
        {
            "activity_id": 2,
            "detail": {
                "id": 2,
                "name": "Solar Execution",
                "status": 1,
                "createdAt": "2022-06-21T07:03:34.000Z",
                "updatedAt": "2022-06-21T16:06:48.000Z"
            },
            "add": [
                {
                    "id": 3,
                    "name": "Pits compaction-1 - Solar Execution",
                    "uom": "uom",
                    "activityId": 2,
                    "status": 1,
                    "createdAt": "2022-06-21T09:13:46.000Z",
                    "updatedAt": "2022-06-22T04:53:21.000Z",
                    "goal": "",
                    "etc_date": null
                },
                {
                    "id": 6,
                    "name": "Pits compaction-2 - Solar Execution",
                    "uom": "Feet",
                    "activityId": 2,
                    "status": 1,
                    "createdAt": "2022-06-21T09:59:20.000Z",
                    "updatedAt": "2022-06-22T04:53:38.000Z",
                    "goal": "",
                    "etc_date": null
                }
            ],
            "unassigned": [
                {
                    "id": 10,
                    "project_id": "1",
                    "activity_id": 2,
                    "projectTask_id": null,
                    "ProjectPrimary_ID": null,
                    "task_id": 3,
                    "start_date": null,
                    "etc_date": "2022-03-12",
                    "completed_date": null,
                    "completed_total": 0,
                    "work_done": 0,
                    "assigned_total": 800,
                    "remark": null,
                    "status": null,
                    "supervisorName": null,
                    "isApproved": 0,
                    "isAssigned": 0,
                    "Total_Work": null,
                    "createdAt": "2022-06-22T17:15:56.000Z",
                    "updatedAt": "2022-06-22T17:15:56.000Z",
                    "taskDetail": {
                        "id": 3,
                        "name": "Pits compaction-1 - Solar Execution",
                        "uom": "uom",
                        "activityId": 2,
                        "status": 1,
                        "createdAt": "2022-06-21T09:13:46.000Z",
                        "updatedAt": "2022-06-22T04:53:21.000Z"
                    }
                }
            ],
            "assigned": []
        }
    ]
}