import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

const Pagination = ({
  page,
  nextPage,
  previousPage,
  pageCount,
  pageOptions,
  canPreviousPage,
  canNextPage,
  rows,
  pageSize,
  setPageSize,
  pageIndex,
}) => {
  const [pageNo, setPageNo] = useState();

  // console.log({
  //     page:page,
  //     pageCount:pageCount,
  //     pageOptions:pageOptions,
  //     canNextPage:canNextPage,
  //     canPreviousPage:canPreviousPage,
  //     rows:rows,
  //     pageSize:pageSize,
  //     pageIndex:pageIndex
  // });

  const currentPageSize = () => {
    setPageNo();
  };

  useEffect(() => {
    setPageSize(10);
  }, []);

  return (
    <Wrapper>
      <div className="detail">
        <span className="currentCount">
          {/* {
    (pageIndex+1)===(Math.floor(rows.length/pageSize)+1 )?
     (   (rows.length%pageSize)+ ( (pageIndex+1) -1 )*pageSize ):pageSize*(pageIndex+1)

} */}
          {/* ----------- */}
          {(pageIndex + 1 - 1) * pageSize + 1} -{" "}
          {rows.length % pageSize > 0
            ? pageIndex + 1 === Math.floor(rows.length / pageSize) + 1
              ? (rows.length % pageSize) + (pageIndex + 1 - 1) * pageSize
              : pageSize * (pageIndex + 1)
            : pageSize * (pageIndex + 1)}
          {/* {((pageIndex+1)-1)*pageSize+1} - {
            pageSize*(pageIndex+1)
            }  */}
        </span>
        Out of
        <span className="totalCount"> {rows.length} </span>
      </div>
      <div className="action">
        <div className="leftArr">
          <ChevronLeftIcon
            onClick={() => previousPage()}
            style={{
              fontSize: "30px",
              color: canPreviousPage
                ? "rgb(255 255 255)"
                : "rgba(255, 255, 255, 0.42)",
            }}
          />
        </div>
        {/* <div className="pageNo">
                1
            </div> */}
        <div className="rightArr">
          <ChevronRightIcon
            onClick={() => nextPage()}
            style={{
              fontSize: "30px",
              color: canNextPage ? "#fff" : "rgba(255, 255, 255, 0.42)",
            }}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default Pagination;

const Wrapper = styled.div`
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(255 255 255);
  padding: 20px;
  .detail {
    color: #3b3e66;
    font-weight: 700;
    font-size: 14px;
    text-transform: capitalize;
    .currentCount {
      padding-right: 8px;
      font-weight: 700;
    }
    .totalCount {
      font-weight: 700;
    }
  }

  .action {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 25px;
    gap: 10px;
  }
  .pageNo {
    font-size: 25px;
    font-weight: 700;
  }
  .rightArr,
  .leftArr {
    padding: 0;
    min-width: 42px;
    height: 40px;
    line-height: 36px;
    text-align: center;
    border-radius: 0.2rem !important;
    margin: 0 0.28571rem;
    transition: all 0.2s ease-in-out;
    position: relative;
    font-weight: 700;
    // color: #3b3e66;
    background-color: #fff;
    border: 1px solid #e6e7f1;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0.313rem 0.8rem rgb(60 68 177 / 50%),
      0 0.126rem 0.225rem rgb(60 68 177 / 30%);
    background: #1976d2;
    color: #fff;
    border-color: #1976d2;
    cursor: pointer;
  }
`;
