import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Header from "../../Component/Header/Header";
import PageTitle from "../../Component/PageTitle/PageTitle";
import { Button, Grid, Paper } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  DangerBtn,
  SaccessBtn,
  PrimaryBtn,
  NeutralBtn,
} from "../../Pages/styles";
import { useStyles, btnStyles } from "../TableStyle";
import { NavLink } from "react-router-dom";
import Link from "@mui/material/Link";
import Footer from "../Footer/Footer";
import Subtitle from "../../Component/PageTitle/Subtitle";
import { Input } from "@material-ui/core";
import "../dashboard/Dashboard.scss";
import ReportsDetails from "./ReportsDetails";
import Reports from "./Reports";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import { Box, Backdrop, CircularProgress } from "@mui/material";
import styled from "styled-components";
import ReportDashboard from "./ReportDashboard";
import ReportHeader from "./ReportHeader";
import ShareIcon from "@mui/icons-material/Share";
import moment from "moment";
import { getProjectById } from "../../redux/api/index";
import { useParams, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#ffffff",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
  },
});

export default function Reportsmain() {
  const classes = useStyles();
  const [toggleOpen, setToggleOpen] = useState(1);
  const [showBackdrop, setShowBackdrop] = useState(false);

  const [projectInfo, setProjectInfo] = useState({});
  const toggleTabs = (index) => {
    setToggleOpen(index);
  };

  const params = useParams();
  const navigate = useNavigate();

  const fetchProjDetail = async () => {
    try {
      setShowBackdrop(true);
      const { data } = await getProjectById(params.projectId);
      setProjectInfo(data.data);
      setShowBackdrop(false);
    } catch (error) {
      setShowBackdrop(false);
      console.log("project detail fetch error");
    }
  };

  useEffect(() => {
    fetchProjDetail();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        {/* <Header /> */}
        <ReportHeader projectInfo={projectInfo} />
        <main className={classes.content} style={{ overflow: "auto" }}>
          <div
            style={{ marginTop: "60px" }}
            // className={classes.toolbar}
          />
          {/* <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/">
              Dashboard
            </Link>
            <Typography color="textPrimary">Reports</Typography>
          </Breadcrumbs> */}
          <Box
            className={classes.root}
            style={{ alignItems: "center", justifyContent: "space-between" }}
          >
            <PageTitle title="Reports" />
            <Box>
              <Button
                size="small"
                variant="outlined"
                style={{ marginRight: "10px" }}
                onClick={() => {
                  navigate("/reportview");
                }}
              >
                <ArrowBackIosIcon style={{ width: "20px" }} size="small" />
              </Button>
              <Button size="small" variant="outlined">
                <ShareIcon />{" "}
              </Button>
            </Box>
          </Box>
          <Paper elevation={8} className="reportTabs" sx={{ p: 2, mb: 2 }}>
            <Grid container>
              <Grid sm={12} md={4} lg={4}>
                <List className="address-details">
                  <ListItem>
                    <Typography>PROJECT ID</Typography>
                    <Typography> {projectInfo?.project_id} </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>PROJECT NAME</Typography>
                    <Typography> {projectInfo?.project_name} </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>STARTING DATE</Typography>
                    <Typography> {projectInfo?.start_date} </Typography>
                  </ListItem>
                </List>
              </Grid>
              <Grid sm={12} md={4} lg={4}>
                <List className="address-details">
                  <ListItem>
                    <Typography>NAME</Typography>
                    <Typography> {projectInfo?.client_name} </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>EMAIL</Typography>
                    <Typography> {projectInfo?.client_email} </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>ETC DATE</Typography>
                    <Typography>
                      {" "}
                      {projectInfo?.etc_date
                        ? moment(projectInfo?.etc_date).format("DD/MM/YYYY")
                        : "-"}{" "}
                    </Typography>
                  </ListItem>
                </List>
              </Grid>
              <Grid sm={12} md={4} lg={4}>
                <List className="address-details">
                  <ListItem style={{ display: "block" }}>
                    <Typography>ADDRESS</Typography>
                    <Box> {projectInfo?.address} </Box>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Paper>
          <Paper elevation={4} className="reportTabs">
            <Grid>
              <Grid item xs={12}>
                <Listing>
                  <List disablePadding className="reportListing">
                    <ListItem disablePadding>
                      <ListItemButton
                        className={
                          toggleOpen === 1 ? "tabs active-tabs" : "tabs"
                        }
                        onClick={() => toggleTabs(1)}
                      >
                        Dashboard{" "}
                      </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding>
                      <ListItemButton
                        className={
                          toggleOpen === 2 ? "tabs active-tabs" : "tabs"
                        }
                        onClick={() => toggleTabs(2)}
                      >
                        Project Summary{" "}
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton
                        className={
                          toggleOpen === 3 ? "tabs active-tabs" : "tabs"
                        }
                        onClick={() => toggleTabs(3)}
                      >
                        {" "}
                        Detailed Report{" "}
                      </ListItemButton>
                    </ListItem>
                  </List>
                </Listing>
                {toggleOpen === 1 && <ReportDashboard />}
                {toggleOpen === 2 && <Reports />}
                {toggleOpen === 3 && <ReportsDetails />}
              </Grid>
            </Grid>
          </Paper>
          <Backdrop
            style={{ zIndex: 9, opacity: "35%" }}
            open={showBackdrop}
            // onClick={()}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Footer />
        </main>
      </div>
    </ThemeProvider>
  );
}

const Listing = styled.div`
  .tabs {
    min-width: 250px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    font-weight: bold;
    background: #fff;
    border-bottom: 4px solid transparent;
    min-width: auto;
    font-size: 0.95rem;
    &:hover {
      color: #3c44b1;
      border-color: #3c44b1;
      // box-shadow: 0 0.313rem 0.8rem rgb(60 68 177 / 50%), 0 0.126rem 0.225rem rgb(60 68 177 / 30%);
    }

    & > span.txt {
      background: #e8fbfd;
      color: #049aac;
      text-align: center;
      height: 26px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      border-radius: 30px;
      font-weight: bold;
    }
  }
  .tabs.active-tabs {
    // box-shadow: 0 0.313rem 0.8rem rgb(60 68 177 / 50%), 0 0.126rem 0.225rem rgb(60 68 177 / 30%);
    color: #3c44b1;
    border-color: #3c44b1;
    background: rgba(0, 0, 0, 0.02);
    min-width: auto;
  }
  .btn:not(:disabled):hover {
    transform: translateY(-2px);
  }
`;
