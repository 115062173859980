import * as api from "../api/index";
import { GET_STATE, GET_CITY } from "../constants";

export const getphoneState = () => async (dispatch) => {
  try {
    const { data } = await api.getState();
    dispatch({ type: GET_STATE, payload: data.data });
  } catch (error) {
    dispatch({ type: "GET_STATE_ERROR" });
  }
};

export const getphoneCity = (stateId) => async (dispatch) => {
  try {
    const { data } = await api.getCity(stateId);
    dispatch({ type: GET_CITY, payload: data.data });
  } catch (error) {
    dispatch({ type: "GET_CITY_ERROR" });
  }
};
