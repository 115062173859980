import React, { useEffect, useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  Box,
  TextField,
  Fade,
  CssBaseline,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import { Snackbar, InputAdornment, OutlinedInput } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";

import "./login.css";
import logo from "../assets/images/flag-alobha-logo-12.png";
import { loginApi } from "../redux/api/index";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const [loginDetail, setLoginDetail] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();
  const [snack, setSnack] = useState({ open: false, message: "" });
  const [showPass, setShowPass] = useState(false);

  const handleInputChange = (e) => {
    setLoginDetail({
      ...loginDetail,
      [e.target.name]: e.target.value,
    });
  };

  const adminLogin = async () => {
    try {
      if (!loginDetail.email || !loginDetail.password) {
        throw new Error("Please use Admin ID or Project ID and password");
      }
      const { data } = await loginApi(loginDetail.email, loginDetail.password);
      navigate("/dashboard");
    } catch (error) {
      setSnack({
        ...snack,
        open: true,
        message: error?.response?.data?.message || error.message,
      });
    }
  };

  useEffect(() => {}, []);

  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className="bg-color"
      >
        <Grid item xs={12} md={3}>
          <div className="login-container" boxShadow={3}>
            <div className="logo">
              <img src={logo} alt="" />
            </div>

            <Card className="box-login" elevation={8}>
              <CardContent
                direction="row"
                alignItems="center"
                justifyContent="center"
                className="cardcontent"
              >
                <Typography variant="h5" conponent="h5">
                  Welcome
                </Typography>
                <form className="formgroup" noValidate autoComplete="off">
                  <FormControl fullWidth className="form-control">
                    <TextField
                      id="outlined-basic"
                      placeholder="Admin ID/ Project ID"
                      variant="outlined"
                      className="form-input"
                      name="email"
                      onChange={handleInputChange}
                      size="small"
                    />
                  </FormControl>

                  {/* <FormControl fullWidth pt={10} className="formcontrol"> */}
                  <OutlinedInput
                    id="outlined-basic"
                    placeholder="Password"
                    type={showPass ? "text" : "password"}
                    className="form-input"
                    name="password"
                    size="small"
                    fullWidth
                    onChange={handleInputChange}
                    style={{ background: "#fff", cursor: "pointer" }}
                    endAdornment={
                      <Box
                        style={{ display: "flex" }}
                        onClick={() => {
                          setShowPass(!showPass);
                        }}
                      >
                        {showPass ? <VisibilityOff /> : <Visibility />}
                      </Box>
                    }
                  />
                  {/* </FormControl> */}
                  <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    onClick={adminLogin}
                    fullWidth
                    className=""
                    elevation={4}
                  >
                    {" "}
                    Login
                  </Button>
                </form>
              </CardContent>
            </Card>
          </div>
        </Grid>
      </Grid>
      <Snackbar
        open={snack.open}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={3000}
        onClose={() => {
          setSnack({
            ...snack,
            open: false,
            message: "",
          });
        }}
        message={snack.message}
      />
    </div>
  );
}
