
import {ADMIN_LOGIN} from '../constants/index'

const INITIAL_STATE = {
    state:[]
  };

  // eslint-disable-next-line import/no-anonymous-default-export
  export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
      case ADMIN_LOGIN:
        return {
            ...state,
            state:action.payload
        }
  
      default:
        return state;
    }
  }